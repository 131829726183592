import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b410950"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-form_holder" }
const _hoisted_2 = { class: "vb-form_label" }
const _hoisted_3 = {
  key: 0,
  class: "vb-offer-total_data"
}
const _hoisted_4 = {
  key: 0,
  class: "vb-row vb-row--jc-sb"
}
const _hoisted_5 = {
  key: 1,
  class: "vb-row vb-row--jc-sb"
}
const _hoisted_6 = { class: "vb-offer-total-data_result vb-row vb-row--jc-sb" }
const _hoisted_7 = { class: "vb-text-uppercase" }
const _hoisted_8 = {
  key: 1,
  class: "vb-h5 vb-room-head_noprice vb-select-price-message"
}
const _hoisted_9 = {
  key: 2,
  class: "vb-h5 vb-room-head_noprice"
}
const _hoisted_10 = {
  key: 3,
  class: "vb-h5 vb-room-head_noprice"
}
const _hoisted_11 = {
  key: 4,
  class: "vb-h6 vb-room-dates"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_currency = _resolveComponent("price-currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('page-search_order-info')), 1),
    ($props.itemType !== 'restaurant')
      ? (_openBlock(), _createElementBlock("dl", _hoisted_3, [
          ($props.allNights)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("dt", null, _toDisplayString(_ctx.$t('word_nights')) + ":", 1),
                _createElementVNode("dd", null, _toDisplayString($props.allNights), 1)
              ]))
            : _createCommentVNode("", true),
          (this.hasAdditionals)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("dt", null, _toDisplayString(_ctx.$t('page-search_w-additionals')) + ":", 1),
                _createElementVNode("dd", null, [
                  _createVNode(_component_price_currency, {
                    "data-price": $options.additionalTotalPrice,
                    "data-currency": $options.itemCurrency
                  }, null, 8, ["data-price", "data-currency"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("dt", _hoisted_7, _toDisplayString(_ctx.$t('word_total')) + ":", 1),
            _createElementVNode("dd", null, [
              _createVNode(_component_price_currency, {
                "data-price": $props.modelValue,
                "data-currency": $options.itemCurrency
              }, null, 8, ["data-price", "data-currency"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ((undefined !== this.selectPeopleValue) && (typeof(this.selectPeopleValue) !== 'number'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('please-select-message')), 1))
      : _createCommentVNode("", true),
    (this.ifAvailableNumber && undefined !== this.priceListCount && !this.priceListCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('no-valid-price')), 1))
      : _createCommentVNode("", true),
    (!this.ifAvailableNumber && !this.productsInCart)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('page-search_not-availability_text')), 1))
      : _createCommentVNode("", true),
    ($props.filterDates.checkIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          ($props.filterDates.checkIn !== $props.filterDates.checkOut)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('quick-booking_calendar_label')) + " " + _toDisplayString($options.checkInDate) + " > " + _toDisplayString($options.checkOutDate), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('word_date')) + ": " + _toDisplayString($options.checkInDate), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}