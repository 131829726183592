<template>
  <div
      class="vb-details-info"
      :class="{'vb-details-info--show': showInfo}"
      :style="{'max-height': showInfo ? detailsHeight : '0px'}"
      ref="detailsEl"
  >
    <slot/>
  </div>
</template>

<script lang="ts">
export default {
  name: "DetailsInfo",
  props: {
    showInfo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    detailsHeight: "0px",
  }),
  watch: {
    showInfo: {
      handler() {
        this.calcElHeight()
      },
      immediate: true
    },
  },
  mounted() {
    if (this.showInfo) {
      this.calcElHeight();
    }
  },
  methods: {
    calcElHeight() {
      const el = this.$refs.detailsEl as HTMLDivElement;
      if (el) {
        this.detailsHeight = el.scrollHeight + 'px'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";

.vb-details-info {
  @include multi-transition(max-height);
  overflow: hidden;
}
</style>
