import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePicker = _resolveComponent("BasePicker")!

  return ($options.settings)
    ? (_openBlock(), _createBlock(_component_BasePicker, {
        key: 0,
        ref: "datePicker",
        disabled: $options.isDisable,
        "starting-date-value": $options.value.checkIn,
        "ending-date-value": $options.value.checkOut,
        "start-date": $options.startBooking,
        "end-date": $options.stopBooking,
        "min-nights": $options.minNights,
        "max-nights": $options.maxNights,
        showYear: true,
        format: _ctx.$_datePicker_dateFormat(),
        i18n: _ctx.$_datePicker_i18nCalendar(),
        "first-day-of-week": _ctx.$_datePicker_firstDayOfWeek(),
        "current-date-style": _ctx.$_datePicker_dateStyle(),
        singleDaySelection: $options.singleDaySelection,
        onCheckInChanged: _cache[0] || (_cache[0] = ($event: any) => ($options.updateInfo($event, true))),
        onCheckOutChanged: _cache[1] || (_cache[1] = ($event: any) => ($options.updateInfo($event, false))),
        class: "hotel-date-picker"
      }, null, 8, ["disabled", "starting-date-value", "ending-date-value", "start-date", "end-date", "min-nights", "max-nights", "format", "i18n", "first-day-of-week", "current-date-style", "singleDaySelection"]))
    : _createCommentVNode("", true)
}