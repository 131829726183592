import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt", "title", "src"]
const _hoisted_2 = { class: "vb-header-main_logo-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: {name: 'home'},
    class: "vb-header-main_logo vb-ellipsis flex items-center"
  }, {
    default: _withCtx(() => [
      ($options.logoImg)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: $options.logoNameAlt,
            title: $options.logoName,
            src: $options.logoImg,
            class: "vb-header-main_logo-img"
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_2, _toDisplayString(` ${$options.logoName}`), 1)
    ], undefined, true),
    _: 1
  }))
}