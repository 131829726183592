import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3a72c66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "test" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_switch = _resolveComponent("base-switch")!
  const _component_base_input_phone = _resolveComponent("base-input-phone")!
  const _component_update_email = _resolveComponent("update-email")!
  const _component_update_mobile = _resolveComponent("update-mobile")!
  const _component_custom_text = _resolveComponent("custom-text")!
  const _component_date_period = _resolveComponent("date-period")!
  const _component_price_currency = _resolveComponent("price-currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" Test Page "),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input, {
        placeholder: "test",
        modelValue: $data.text,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.text) = $event)),
        "enable-read-only": ""
      }, null, 8, ["modelValue"]),
      _createElementVNode("p", null, _toDisplayString($data.text), 1),
      _createVNode(_component_base_switch, {
        modelValue: $data.switchBoolean,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.switchBoolean) = $event)),
        placeholder: "switch",
        "first-value": false,
        "second-value": true,
        "second-text": _ctx.$t('page-account_form_subscribe')
      }, null, 8, ["modelValue", "second-text"]),
      _createElementVNode("p", null, _toDisplayString($data.switchBoolean), 1),
      _createVNode(_component_base_input_phone, {
        modelValue: $data.phone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.phone) = $event)),
        "input-placeholder": _ctx.$t('word_phone'),
        "input-options": {showDialCode: false}
      }, null, 8, ["modelValue", "input-placeholder"]),
      _createElementVNode("p", null, _toDisplayString($data.phone), 1),
      _createVNode(_component_update_email, {
        "model-email": $data.email,
        onConfirmEmail: $options.confirmEmail
      }, null, 8, ["model-email", "onConfirmEmail"]),
      _createElementVNode("p", null, _toDisplayString($data.email), 1),
      _createVNode(_component_update_mobile, {
        "show-dial-code": false,
        "model-mobile": $data.mobile,
        onConfirmMobile: $options.confirmMobile
      }, null, 8, ["model-mobile", "onConfirmMobile"]),
      _createVNode(_component_custom_text, { type: "mypage_profile_info" }),
      _createVNode(_component_date_period, {
        "data-to": "2023-06-21T20:00:00",
        "data-from": "2023-06-19T10:00:00"
      }),
      _createVNode(_component_price_currency, {
        "data-currency": "NOK",
        "data-price": "1050"
      })
    ])
  ]))
}