<template>
<div class="w-full text-black bg-lavender">
  <div
    v-for="week in weekList"
    :key="week.weekNumber"
    class="h-12 flex justify-center items-center tablet:h-16 mobile:h-16"
  >
    <div :class="{'vb-invisible': !week.showWeek}">
      {{ week.weekNumber }}
    </div>
  </div>
</div>
</template>

<script>
function getWeek(dayDate, dowOffset = 0, showWeek) {
  // ISO 8601
  const date = new Date(dayDate);
  const newYear = new Date(date.getFullYear(), 0, 1);
  let day = newYear.getDay() - dowOffset; //the day of week the year begins on
  day = day >= 0 ? day : day + 7;
  const daynum =
    Math.floor(
      (date.getTime() -
        newYear.getTime() -
        (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
        86400000
    ) + 1;
  let weeknum;
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      const nYear = new Date(date.getFullYear() + 1, 0, 1);
      const nday = nYear.getDay() - dowOffset;
      const nDay = nday >= 0 ? nday : nday + 7;
      /*if the next year starts before the middle of
          the week, it is week #1 of that year*/
      weeknum = nDay < 4 ? 1 : 53;
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  return {
    weekNumber: weeknum,
    showWeek,
  };
}
export default {
  name: "HotelDatePickerWeeks",
  props: {
    dayList: { type: Array, default: () => [] },
  },
  computed: {
    weekList() {
      return this.dayList
        .filter((day, index) => index === 0 || !(index % 7))
        .map((day) => {
          const dayIndex = this.dayList.findIndex(
            (d) => d.date.toString() === day.date.toString()
          );
          const week = this.dayList.slice(dayIndex, dayIndex + 7);
          return {
            ...day,
            showWeek: week.some((day) => day.belongsToThisMonth === true),
          };
        })
        .map((day) =>
          getWeek(day.date, +this.$t("date-picker_first-day"), day.showWeek)
        );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../scss/base";
</style>
