import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3745fd78"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vb-price-currency"
}
const _hoisted_2 = {
  key: 0,
  class: "vb-price-currency_currency vb-small-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_base = _resolveComponent("price-base")!

  return ($props.dataPrice !== undefined)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_price_base, {
          "data-price": $props.dataPrice,
          "show-decimals": $props.showDecimals
        }, null, 8, ["data-price", "show-decimals"]),
        ($props.dataCurrency)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.dataCurrency), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}