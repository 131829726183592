import { createApp } from "vue";
import App from "@/App.vue";
import './index.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import VueCookies from "vue-cookies";
import Visbook from "visbook-api";
import { ApiUrl } from "./config";
import VueGtag from 'vue-gtag';
import { getDefaultLanguageCountry } from "./core/helpers";
import ToRouterLink from "@/components/different/to-router-link.vue";
import BaseInput from "@/components/forms/base-input.vue";
import BaseSelect from "@/components/forms/base-select.vue";
import VueMultiselect from '@vueform/multiselect';
import mitt from "mitt";
import filters from "@/core/filters";
import vClickOutside from 'click-outside-vue3';

if (!sessionStorage.getItem("defaultLanguage")) {
  getDefaultLanguageCountry();
}

export const app = createApp(App as any)
    .use(VueGtag, {
      config: {id: "GA_MEASUREMENT_ID"}
    })
    .use(store)
    .use(i18n)
    .use(router)
    .use(VueCookies)
    .use(ElementPlus)
    .component("to-router-link", ToRouterLink)
    .component("base-select", BaseSelect)
    .component("vue-multiselect", VueMultiselect)
    .component("base-input", BaseInput)
    .directive("click-outside", vClickOutside.directive);

/*Filters*/
app.config.globalProperties.$filters = filters

export const visbook = new Visbook(
  ApiUrl,
  app.config.globalProperties.$entity,
  i18n.global.locale.value
);

const url = router.resolve(window.location.pathname); //route has not yet been resoved automatically, get result manually
if (url.params.entity) {
  app.config.globalProperties.$entity = parseInt(
    Array.isArray(url.params.entity) ? url.params.entity[0] : url.params.entity
  );
  const visbook = new Visbook(
    ApiUrl,
    app.config.globalProperties.$entity,
    i18n.global.locale.value
  );
  app.config.globalProperties.$visbook = visbook;
  // store.state.$visbook = visbook;
} else {
  //absence of web entity makes all API unavailable

  app.config.globalProperties.$entity = null;
  (app.config.globalProperties.$visbook as any) = null;
}
app.config.globalProperties.$emitter = mitt<any>();

app.mount("#app");
