import {
  i_globalView,
  i_siteLanguage,
  loginWay_type,
  splashScreenStopper_contant,
} from "@/store/store";
import { i_country } from "visbook-api";
import router from "@/router";
import i18n from "@/i18n";
import { app } from "@/main";
import { de } from "element-plus/es/locale";

const namespaced = true;

const state: i_globalView = {
  requestLoader: 0,
  splashScreen:
    splashScreenStopper_contant.LANGUAGE +
    splashScreenStopper_contant.COMPANYINFO,
  isDisableScroll: false,
  siteLanguage: "",
  countries: [],
  notifications: [],
  modalComponent: "",
  loginWay: loginWay_type.EMPTY,
  profileActiveTabName: 'account',
};

const getters = {
  GET_requestLoader(store: i_globalView) {
    return !!store.requestLoader;
  },
  GET_profileActiveTabName(store: i_globalView) {
    return store.profileActiveTabName;
  },
};

const mutations = {
  dec_splashScreen(state: i_globalView, value: number) {
    state.splashScreen = Math.max(0, state.splashScreen - value);
  },
  dec_requestLoader(state: i_globalView) {
    state.requestLoader = state.requestLoader ? --state.requestLoader : -1;
  },
  inc_requestLoader(state: i_globalView) {
    state.requestLoader = state.requestLoader ? ++state.requestLoader : 1;
  },
  set_isDisableScroll(state: i_globalView, value: boolean) {
    state.isDisableScroll = value;
  },
  set_siteLanguage(state: i_globalView, value: string) {
    state.siteLanguage = value;
  },
  set_countries(state: i_globalView, value: i_country[]) {
    state.countries = value;
  },
  add_notification(state: i_globalView, value: any) {
    state.notifications.push(value);
  },
  remove_notification(state: i_globalView, id: number) {
    state.notifications = state.notifications.filter((el: any) => el.id !== id);
  },
  set_modal(state: i_globalView, value: string) {
    state.modalComponent = value;
  },
  set_loginWay(state: i_globalView, value: loginWay_type) {
    state.loginWay = value;
  },
  set_profileActiveTabName(state: i_globalView, value: string) {
    state.profileActiveTabName = value;
  },
};

const actions = {
  DEC_splashScreen({ commit }: any, val: number) {
    commit("dec_splashScreen", val);
  },
  DEC_requestLoader({ commit }: any) {
    commit("dec_requestLoader");
  },
  INC_requestLoader({ commit }: any) {
    commit("inc_requestLoader");
  },
  SET_isDisableScroll({ commit }: any, val: boolean) {
    commit("set_isDisableScroll", val);
  },
  SHOW_notification({ state, commit }: any, val: any) {
    if (val && Object.prototype.hasOwnProperty.call(val, "message")) {
      if (!Array.isArray(val.message)) {
        val.message = [val.message];
      }
      const id = Array.from(val.message.join(":")).reduce(
        (s: any, c: any) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
        0
      );
      if (!state.notifications.some((el: any) => el.id === id)) {
        commit("add_notification", {
          id,
          type: val.type,
          message: val.message,
        });
        if (val.type == "success") {
          setTimeout(() => {
            commit("remove_notification", id);
          }, 5000);
        }
      }
    }
  },
  HIDE_notification({ commit }: any, id: number) {
    commit("remove_notification", id);
  },
  async SET_siteLanguage({ commit }: any, { lang, to, skipReplace }: i_siteLanguage) {
    commit("set_siteLanguage", lang);
    i18n.global.locale.value = lang;
    if (!skipReplace) {
      await router.replace({ path: to.path, query: { ...to.query, lang } });
    }
    app.config.globalProperties.$cookies.set("lang", lang);
    if (app.config.globalProperties.$visbook) {
      app.config.globalProperties.$visbook.SET_language(getHeaderLang(lang));
    }
    document.documentElement.lang = lang;
  },
  SET_countries({ commit /*, state*/ }: any, val: object[]) {
    // if (state.siteLanguage !== "en") {
    //   const translations: any =
    //     i18n.global.messages[`countries_${state.siteLanguage}`];
    //   if (translations) {
    //     val.forEach((e: any) => {
    //       const name = translations.find((t: any) => t.id === e.id);
    //       if (name) {
    //         e.name = name.name;
    //       }
    //     });
    //     val.sort((a: any, b: any) => a.name.localeCompare(b.name));
    //   }
    // }
    commit("set_countries", val);
  },
  OPEN_modal({ commit }: any, componentName: string) {
    commit("set_isDisableScroll", true);
    commit("set_modal", componentName);
  },
  CLOSE_modal({ commit }: any) {
    commit("set_isDisableScroll", false);
    commit("set_modal", "");
  },
  SET_loginWay({ commit }: any, val: loginWay_type) {
    if (val) {
      localStorage.setItem("loginWay", val);
    } else {
      localStorage.removeItem("loginWay");
    }
    commit("set_loginWay", val);
  },
};

function getHeaderLang(lang: string): string {
  let result;
  switch (lang) {
    case "en":
      result = "en,en-US";
      break;
    case "de":
      result = "de,de-DE";
      break;
    case "no":
      result = "no,nb-NO";
      break;
    case "se":
      result = "sv,sv-SE";
      break;
    case "dk":
      result = "da,da-DK";
      break;
    default:
      result = lang;
  }
  return result;
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
