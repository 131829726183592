import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_template_error = _resolveComponent("template-error")!

  return (_openBlock(), _createBlock(_component_template_error, {
    "error-code": "404",
    "page-title": _ctx.$t('page-entity_title'),
    "page-text": _ctx.$t('page-entity_text')
  }, null, 8, ["page-title", "page-text"]))
}