import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19b073fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-user-info-root vb-aside-inner" }
const _hoisted_2 = { class: "vb-aside-inner" }
const _hoisted_3 = {
  class: "vb-user-info_form",
  ref: "accountForm"
}
const _hoisted_4 = { class: "vb-form_label" }
const _hoisted_5 = {
  key: 1,
  class: "vb-form_row"
}
const _hoisted_6 = { class: "vb-form_holder" }
const _hoisted_7 = { class: "vb-form_holder" }
const _hoisted_8 = { class: "vb-form_holder vb-w-100" }
const _hoisted_9 = { class: "vb-form_holder vb-w-100" }
const _hoisted_10 = { class: "vb-form_holder vb-w-100" }
const _hoisted_11 = { class: "vb-form_holder vb-w-100" }
const _hoisted_12 = { class: "vb-form_holder" }
const _hoisted_13 = { class: "vb-form_holder" }
const _hoisted_14 = {
  key: 0,
  id: "cities"
}
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "vb-form_holder vb-w-100" }
const _hoisted_17 = { class: "vb-form_holder vb-w-100" }
const _hoisted_18 = { class: "vb-form_holder vb-w-100" }
const _hoisted_19 = { class: "vb-form_holder vb-w-100" }
const _hoisted_20 = { class: "vb-form_holder vb-w-100" }
const _hoisted_21 = { class: "vb-form_holder vb-w-100" }
const _hoisted_22 = {
  key: 0,
  class: "vb-required-span"
}
const _hoisted_23 = { class: "vb-page_actions vb-form_row" }
const _hoisted_24 = { class: "vb-form_holder vb-w-100" }
const _hoisted_25 = {
  key: 0,
  class: "vb-required-span"
}
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "vb-form_holder vb-w-100" }
const _hoisted_28 = {
  key: 2,
  class: "vb-checkout-creditcart-logos"
}
const _hoisted_29 = { key: 0 }
const _hoisted_30 = ["colspan"]
const _hoisted_31 = ["href", "title"]
const _hoisted_32 = ["src"]
const _hoisted_33 = { class: "vb-custom-text-placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_summary_total = _resolveComponent("order-summary-total")!
  const _component_account_confirm = _resolveComponent("account-confirm")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_input_phone = _resolveComponent("base-input-phone")!
  const _component_base_select = _resolveComponent("base-select")!
  const _component_gift_cards = _resolveComponent("gift-cards")!
  const _component_base_checkbox = _resolveComponent("base-checkbox")!
  const _component_order_summary_partial = _resolveComponent("order-summary-partial")!
  const _component_the_testmode = _resolveComponent("the-testmode")!
  const _component_custom_text = _resolveComponent("custom-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_order_summary_total),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('page-account_title')), 1),
        (!$options.profile)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('page-account_title-confirm')), 1),
              _createVNode(_component_account_confirm, {
                class: "vb-w-100",
                "model-email": _ctx.model.email,
                "model-mobile": _ctx.model.mobile,
                onConfirmEmail: $options.confirmEmail,
                onConfirmMobile: $options.confirmMobile
              }, null, 8, ["model-email", "model-mobile", "onConfirmEmail", "onConfirmMobile"])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_base_input, {
                  type: "text",
                  autocomplete: "given-name",
                  "model-value": _ctx.model.firstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.firstName) = $event)),
                  required: true,
                  disabled: !$options.profile,
                  placeholder: _ctx.$t('page-account_form_fname')
                }, null, 8, ["model-value", "disabled", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_base_input, {
                  type: "text",
                  autocomplete: "family-name",
                  "model-value": _ctx.model.lastName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.lastName) = $event)),
                  placeholder: _ctx.$t('page-account_form_lname'),
                  required: true,
                  disabled: !$options.profile
                }, null, 8, ["model-value", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_base_input, {
                  type: "email",
                  autocomplete: "email",
                  "model-value": _ctx.model.email,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.email) = $event)),
                  required: true,
                  placeholder: _ctx.$t('word_email'),
                  disabled: $options.loginWay === 'email'
                }, null, 8, ["model-value", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_base_input_phone, {
                  class: "vb-input-section_input",
                  type: "tel",
                  autocomplete: "tel",
                  "model-value": _ctx.model.mobile,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.mobile) = $event)),
                  required: true,
                  placeholder: _ctx.$t('word_mobile'),
                  "input-options": {showDialCode: true},
                  disabled: $options.loginWay === 'phone'
                }, null, 8, ["model-value", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_base_input, {
                  type: "text",
                  autocomplete: "organization",
                  "model-value": _ctx.model.company,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.company) = $event)),
                  placeholder: _ctx.$t('word_company'),
                  disabled: !$options.profile
                }, null, 8, ["model-value", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_base_input, {
                  type: "text",
                  autocomplete: "address-line1",
                  "model-value": _ctx.model.address,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.address) = $event)),
                  placeholder: _ctx.$t('word_address'),
                  disabled: !$options.profile
                }, null, 8, ["model-value", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_base_input, {
                  type: "text",
                  pattern: "^[a-zA-Z0-9- ]+$",
                  autocomplete: "postal-code",
                  "model-value": _ctx.model.zipCode,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.zipCode) = $event)),
                  required: true,
                  placeholder: _ctx.$t('word_zip-code'),
                  disabled: !$options.profile
                }, null, 8, ["model-value", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_base_input, {
                  type: "text",
                  autocomplete: "address-level2",
                  "model-value": _ctx.model.city,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.city) = $event)),
                  required: true,
                  placeholder: _ctx.$t('word_city'),
                  disabled: !$options.profile,
                  list: "cities"
                }, null, 8, ["model-value", "placeholder", "disabled"]),
                (_ctx.allCities.length)
                  ? (_openBlock(), _createElementBlock("datalist", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allCities, (city) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: city.city,
                          value: city.city
                        }, null, 8, _hoisted_15))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_base_select, {
                  "model-value": _ctx.countryName,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.countryName) = $event)),
                  searchable: true,
                  options: $options.countries,
                  required: true,
                  placeholder: _ctx.$t('word_country'),
                  disabled: !$options.profile
                }, null, 8, ["model-value", "options", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                (_ctx.paymentTypes.length > 1)
                  ? (_openBlock(), _createBlock(_component_base_select, {
                      key: 0,
                      value: _ctx.selectedPayTypesValue,
                      options: _ctx.paymentTypes,
                      label: 'label',
                      trackBy: 'value',
                      allowEmpty: false,
                      showLabels: false,
                      placeholder: _ctx.$t('page-orders_pay-type'),
                      required: true,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ($options.updatePayTypes($event)))
                    }, null, 8, ["value", "options", "placeholder"]))
                  : _createCommentVNode("", true)
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_18, [
                _createVNode(_component_gift_cards, {
                  "model-value": $options.giftcards,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($options.giftcards) = $event)),
                  "can-add": _ctx.toPayNow > 0
                }, null, 8, ["model-value", "can-add"])
              ], 512), [
                [_vShow, $options.showGiftcards]
              ]),
              _createElementVNode("div", _hoisted_19, [
                (_ctx.paymentTypes.length == 1)
                  ? _withDirectives((_openBlock(), _createBlock(_component_base_checkbox, {
                      key: 0,
                      disabled: "disabled",
                      checked: "checked"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.selectedPayTypes ? _ctx.selectedPayTypes.label : ''), 1)
                      ], undefined, true),
                      _: 1
                    }, 512)), [
                      [_vShow, false]
                    ])
                  : _createCommentVNode("", true),
                _createVNode(_component_order_summary_partial, {
                  "payment-type": $options.paymentType,
                  invoiceable: $options.isPaymentInvoiceable,
                  "show-giftcards": $options.showGiftcards,
                  giftcards: $options.giftcards,
                  onToPayNowChanged: _cache[11] || (_cache[11] = ($event: any) => (_ctx.toPayNow = $event))
                }, null, 8, ["payment-type", "invoiceable", "show-giftcards", "giftcards"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_base_checkbox, {
                  "model-value": _ctx.model.followupAccepted,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.followupAccepted) = $event)),
                  disabled: !$options.profile
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('page-account_form_subscribe')), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["model-value", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_base_checkbox, {
                  required: true,
                  disabled: !$options.profile,
                  "model-value": _ctx.acceptedTerms,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.acceptedTerms) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('page-account_btn_agree')), 1),
                    _createElementVNode("button", {
                      class: "vb-btn vb-btn--link",
                      type: "button",
                      onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => ($options.openTerms && $options.openTerms(...args)))
                    }, _toDisplayString(_ctx.$t('page-account_btn_terms')), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["disabled", "model-value"]),
                (_ctx.showTermsRequired && !_ctx.acceptedTerms)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t('have_to_accept_terms')), 1))
                  : _createCommentVNode("", true)
              ])
            ])),
        _createVNode(_component_the_testmode, { class: "vb-testmode_inline" }),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            ($options.showTypePaymentError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.$t('type_payment_error')), 1))
              : _createCommentVNode("", true),
            (!$options.disabledBtnPay)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "vb-btn vb-bg-primary vb-btn-account_update vb-w-100",
                  type: "button",
                  onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => ($options.toPay && $options.toPay(...args)))
                }, _toDisplayString(_ctx.$t($options.buttonPayText)), 1))
              : _createCommentVNode("", true),
            ($options.disabledBtnPay)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "vb-btn vb-bg-primary vb-btn-account_update vb-w-100 vb-btn-disabled",
                  disabled: !$options.profile,
                  onClick: _cache[16] || (_cache[16] = ($event: any) => ($options.setTermsRequired(true, $event)))
                }, _toDisplayString(_ctx.$t($options.buttonPayText)), 9, _hoisted_26))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("button", {
              class: "vb-btn vb-btn--outline vb-btn-account_update vb-w-100",
              type: "button",
              onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => ($options.toSearch && $options.toSearch(...args)))
            }, _toDisplayString(_ctx.$t('cart_btn_search')), 1)
          ])
        ]),
        ($options.showCreditcartLogos)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("table", null, [
                ($options.isExternalPreviewNetsPaymentOptionSet)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_29, [
                      _createElementVNode("td", {
                        class: "vb-checkout-creditcart-logos-preview",
                        colspan: _ctx.creditCartLogos.length
                      }, "*** Preview only ***", 8, _hoisted_30)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.creditCartLogos, (logo, index) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: logo.src,
                      class: _normalizeClass({ 'vb-checkout-creditcart-logos_first-img': 0 === index })
                    }, [
                      _createElementVNode("a", {
                        href: logo.url,
                        title: logo.title,
                        target: "_blank"
                      }, [
                        _createElementVNode("img", {
                          src: logo.src
                        }, null, 8, _hoisted_32)
                      ], 8, _hoisted_31)
                    ], 2))
                  }), 128))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 512),
      _createElementVNode("div", _hoisted_33, [
        _createVNode(_component_custom_text, { type: "C" })
      ])
    ])
  ]))
}