<template>
    <div class="u-input-phone">
        <p
            v-if="props.label"
            :class="props.error ? 'text-red-600' : ''"
            class="mb-1 text-sm font-medium text-gray-600"
        >
            {{ props.label }}
        </p>
        <div class="flex">
            <ElDropdown
                trigger="click"
                @command="handleCommand"
                popper-class="[&>_span]:hidden"
                placement="bottom-start"
            >
                <ElButton
                    class="!h-[56px] !border-none !shadow-[0_0_0_1px_#d1d5db] phone-dropdown"
                >
                    <div class="flex items-center">
                        <span :class="`fi fi-${activeCountry?.iso2} fis rounded-full`"></span>
                        <span
                            class="relative top-px ml-2 block text-base font-normal text-black"
                        >
                            {{ `+${activeCountry?.dialCode}` }}
                        </span>
                        <UElIcon class="ml-4" :size="13" value="ArrowDown" />
                    </div>
                </ElButton>
                <template #dropdown>
                    <ElDropdownMenu class="!p-0 h-[224px] overflow-auto">
                        <ElDropdownItem
                            v-for="country in countries"
                            :key="country.id"
                            class="!p-0 hover:!bg-black/10"
                            :class="{
                                '!bg-black/10':
                                    country.id === activeCountry?.id,
                            }"
                            :command="country"
                        >
                            <template #default>
                                <div class="flex p-4">
                                  <span :class="`fi fi-${country?.iso2} fis rounded-full`"></span>
                                    <span
                                        class="relative top-px ml-4 block text-base font-normal text-black"
                                    >
                                        {{ `+${country.dialCode}` }}
                                    </span>
                                    <span
                                        class="relative top-px ml-4 block text-base font-normal text-black"
                                    >
                                        {{ country.name }}
                                    </span>
                                </div>
                            </template>
                        </ElDropdownItem>
                    </ElDropdownMenu>
                </template>
            </ElDropdown>
            <UInput
                class="ml-2 grow !appearance-none [&_input]:!text-base [&_input]:!text-black phone-input"
                type="phone"
                maxlength="30"
                :modelValue="lazyPhone"
                @update:modelValue="handlePhoneInput"
            ></UInput>
        </div>
    </div>
</template>

<script setup lang="ts">
import "/node_modules/flag-icons/css/flag-icons.min.css";
import UInput from "./UInput.vue";
import UElIcon from "./UElIcon.vue";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { i_country } from "visbook-api";

export interface Props {
  modelValue?: string;
  countryDialCode: string;
  label?: string;
  countries: i_country[];
  error?: boolean;
};

export interface Phone {
  code: number;
  phone: string;
  country: string;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: "",
  error: false,
});

const emit = defineEmits(['update:modelValue', 'change-country-code']);

const lazyPhone = ref(props.modelValue);

const phoneCode = ref();

const activeCountry = computed(() => {
    try {
        const phoneNum = parsePhoneNumber(`+${props.countryDialCode} ${props.modelValue}`);
        if (phoneNum.country) {
            const country = props.countries?.find((c: i_country) => c.iso2 === phoneNum.country?.toLocaleLowerCase())
            emit('change-country-code', country);
            return country;
        } else if (phoneNum.countryCallingCode) {
            const country = props.countries?.find((c: i_country) => c.dialCode === phoneNum.countryCallingCode);
            emit('change-country-code', country);
            return country;
        } else return props.countries[0];
    } catch (e) {
        console.log(e);
        return props.countries?.find((c: i_country) => {
            if (phoneCode.value) {
                return c.iso2 === phoneCode.value;
            }
            return c.dialCode === props.countryDialCode;
        }) || props.countries[0];
    }
});

const handlePhoneInput = async (v: string) => {
  if (activeCountry.value){
    emit('update:modelValue', v);
    try {
      if (v && v.length > 3) {
        v = parsePhoneNumber(v, activeCountry.value.iso2.toUpperCase() as CountryCode).formatInternational();
      }
      v = v.replace('+' + String(activeCountry.value.dialCode), '');
    } catch (e) {
      console.log(e);
    }
    lazyPhone.value  = v;
  }
};

const handleCommand = (country: i_country) => {
    emit('change-country-code', country)
    phoneCode.value = country.iso2;
};

watch(props, () => {
  if (props.error) {
    const phoneDropdown = document.querySelector('.phone-dropdown');
    const phoneInput = document.querySelector('.phone-input');
    phoneDropdown?.classList.add('phone-dropdown-error');
    phoneInput?.classList.add('phone-input-error');
  }
});
</script>

<style lang="scss">
.fi.fis {
  width: 24px;
  height: 24px;
  background-size: cover;
}
.u-input-phone .phone-dropdown-error {
    background-color: #FCEEEE;
    outline: 1px solid #B3261E;
}
.phone-input-error {
    .el-input__wrapper {
        background-color: #FCEEEE;
        outline: 1px solid #B3261E;
    }
}
</style>