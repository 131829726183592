<template>
  <div class="roomtype-filter">
    <multiselect
      v-if="!restaurantsState"
      :modelValue="roomTypeSelected"
      @update:model-value="updateRoomType"
      :options="roomTypeList"
      :placeholder="$t('word_all')"
      track-by="label"
      :searchable="false"
      :hide-selected="false"
      mode="multiple"
      :showLabels="false"
      :close-on-select="false"
      class="h-auto min-h-14 text-black"
    />
  </div>
</template>

<script lang="ts">
import {i_select, i_roomTypeItem} from "@/core/declarations";
import Multiselect from "@vueform/multiselect";


import {PropType} from "vue";
import BaseSelect from "@/components/forms/base-select.vue";

interface i_optionWidth {
  id: number;
  width: number;
}

interface i_query {
  checkIn?: string;
  checkOut?: string;
  lang?: string;
  webProductId?: string;
}

interface Data {
  optionWidth: i_optionWidth[];
}

export default {
  components: {Multiselect},
  emits: ["update:modelValue"],
  props: {
    desktopMaxWidth: {
      type: Number as PropType<number>,
      default: () => 410,
    },
    showPersonsSelect: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  data: () =>
      ({
        optionWidth: [],
      } as Data),
  async created() {
    const query: i_query = this.$route.query;
    await this.getRoomTypeList()
        .then(() => {
          if (query.webProductId) {
            const webProductList = JSON.parse(query.webProductId);
            this.updateRoomType(
                this.roomTypeList.filter((el: any) =>
                    webProductList.includes(el.value)
                )
            );
          }
        })
        .catch(() => {
          //??
        });
  },
  computed: {
    filterPeopleOptions(): number {
      return this.$store.state.mod_search.filterPeopleOptions;
    },
    filterPeopleCount: {
      get(): number {
        return this.$store.state.mod_search.filterPeopleCount;
      },
      set(val: number) {
        this.$store.dispatch("mod_search/SET_filterPeopleCount", val);
      },
    },
    restaurantsState() {
      return this.$store.getters["mod_search/GET_restaurantsState"];
    },
    roomTypeSelected(): i_select[] {
      return [
        ...this.$store.state.mod_search.filterSettingsDynamic.roomType,
      ].sort(
          this.sortByDefault
              ? this.sortRoomTypesBySortindex
              : this.sortRoomTypesByLabel
      ).map(room => room.value)
    },
    roomTypeList(): i_select[] {
      return [...this.$store.state.mod_search.roomTypeList].sort(
          this.sortByDefault
              ? this.sortRoomTypesBySortindex
              : this.sortRoomTypesByLabel
      );
    },
    sortByDirection(): number {
      if (
          "2" === this.$store.state.mod_search.filterSettingsDynamic.sortBy?.value
      ) {
        return -1;
      }
      return 1;
    },
    sortByDefault(): boolean {
      return (
          "0" === this.$store.state.mod_search.filterSettingsDynamic.sortBy?.value
      );
    },
    hideOptions(): boolean {
      const width = this.optionWidth.reduce(
          (accumulator: number, option: i_optionWidth) =>
              option.width + accumulator,
          0
      );
      return this.filterFieldWidth() < width;
    },
  },
  methods: {
    async getRoomTypeList() {
      return await this.$store.dispatch("mod_search/GET_roomTypeList");
    },
    updateRoomType(newList: i_select[]) {
      const mappedList = this.roomTypeList.filter((room: any) => newList.includes(room.value))
      this.$store.dispatch("mod_search/SET_roomTypeSelected", mappedList);
      this.optionWidth.forEach((o: any) => {
        if (false === newList.some((e) => o.id === e.value)) {
          this.optionWidth = this.optionWidth.filter(
              (option: any) => o.id !== option.id
          );
        }
      });
      newList.forEach((e) => {
        if (false === this.optionWidth.some((o: any) => o.id === e.value)) {
          this.optionWidth.push({
            id: e.value,
            width: this.selectedOptionWidth(e.label),
          });
        }
      });
      this.$emit("update:modelValue", newList);
    },
    filterFieldWidth() {
      let width = 0;
      if (this.$root.$el && this.$el) {
        if (this.$root.$el.classList.contains("vb-x-mobile")) {
          width = this.desktopMaxWidth;
        } else {
          for (const el of this.$el.getElementsByClassName("multiselect__tags")) {
            width = (el as HTMLElement).scrollWidth - 20; // minus left + right paddings
            break;
          }
        }
      }
      return width;
    },
    selectedOptionWidth(label: string) {
      const template = document.createElement("div");
      template.setAttribute(
          "style",
          "position: absolute;bottom: 0;visibility: hidden;"
      );
      template.innerHTML =
          '<span id="8595022673be" class="multiselect__tag"><span>' +
          label +
          '</span> <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i></span>';
      document.body.appendChild(template);
      const element = document.getElementById("8595022673be") as HTMLElement;
      const style = window.getComputedStyle(element, null);
      const width =
          element.offsetWidth +
          (parseInt(style.marginLeft) || 0) +
          (parseInt(style.marginRight) || 0);
      document.body.removeChild(template);
      return width;
    },
    sortRoomTypesByLabel(a: i_select, b: i_select) {
      if (a.label > b.label) return this.sortByDirection;
      if (a.label < b.label) return -this.sortByDirection;
      return 0;
    },
    sortRoomTypesBySortindex(a: i_roomTypeItem, b: i_roomTypeItem) {
      if ((a.sortIndex || 0) > (b.sortIndex || 0)) return 1;
      if ((a.sortIndex || 0) < (b.sortIndex || 0)) return -1;
      return 0;
    },
  },
};
</script>

<style lang="scss">
.roomtype-filter {
  .multiselect {
    .multiselect-placeholder {
      color: black;
    }
    .multiselect-caret {
      mask-image: url('@/components/forms/assets/Icons/chevron/down.svg');
      background-color: #475467;
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
    .multiselect-dropdown {
      min-width: fit-content;
      font-size: 13px;
    } 
  }
  .multiselect.is-active {
    box-shadow: none;
  }
  .multiselect-tag {
    background-color: #475467;
  }
  .multiselect-option.is-selected, .multiselect-option.is-selected.is-pointed {
    background-color: #475467;
  }
}
</style>
