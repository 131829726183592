<template>
  <div class="vb-search-filter-dynamic vb-form_row vb-small-text tablet:grid tablet:gap-2 mobile:grid mobile:gap-2">
    <label class="vb-form_holder vb-row vb-row--ai-c">
      <span class="vb-filter-dynamic_title min-w-[7em] mobile:min-w-[4em]" v-if="!restaurantsState">{{ $t('quick-booking_select_label') }}</span>
      <roomtype-filter
        class="vb-filter-dynamic_filter w-full"
        :show-persons-select="false"
        @update:modelValue="onUpdate()"
      ></roomtype-filter>
    </label>

    <label class="vb-form_holder vb-row vb-row--ai-c">
      <span class="vb-filter-dynamic_title min-w-[7em] mobile:min-w-[4em]">{{ $t('filter_sort') }}:</span>
      <base-select
          class="vb-filter-dynamic_select"
          v-model="sortBySelected"
          :options="sortByList"
          label="label"
          track-by="label"
          :searchable="false"
          :allowEmpty="false"
          :multiple="false"
          :showLabels="false"
          :disabled="sortDisabled"
      />
    </label>
  </div>
</template>

<script lang="ts">
import {i_select} from "@/core/declarations";
import RoomtypeFilter from "@/components/the-pagination/roomtype-filter.vue";
import BaseSelect from "@/components/forms/base-select.vue";

export default {
  components: {
    BaseSelect,
    RoomtypeFilter,
  },
  emits: ["update-filter"],
  mounted() {
    if (!this.sortBySelected) this.restaurantsState = this.sortByList[0]
  },
  data(){
    return {
      sortDisabled: false
    }
  },
  computed: {
    restaurantsState() {
      return this.$store.getters["mod_search/GET_restaurantsState"];
    },
    sortBySelected: {
      get(){
        return this.$store.state.mod_search.filterSettingsDynamic.sortBy || "0";
      },
      set(newVal: i_select[]){
        this.onUpdate();
        this.$store.dispatch("mod_search/SET_sortBySelected", newVal);
      }
    },
    sortByList(): i_select[] {
      return this.$store.getters["mod_search/GET_sortByList"];
    },
    resultList(): any[] {
      return this.$store.getters["mod_search/GET_filteredRooms"];
    },
  },
  methods: {
    onUpdate() {
      this.$emit("update-filter");
    },
  },
  watch: {
    resultList(resultList: any){
      this.sortDisabled = !resultList.length
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";

.vb-filter-dynamic {
  &_select {
    width: 150px;
  }
}

.vb-search-filter-dynamic {
  &.vb-form_row {
    margin-right: 0;
    flex-wrap: nowrap;
    padding: 1rem;
    background: $white;

    .vb-tablet & {
      margin-right: -5px;
      padding: inherit;
      background: inherit;
    }
  }


  /deep/ {
    .vb-form_holder {
      margin: 0;
    }

    .multiselect__content-wrapper {
      right: 0;
    }
  }
}

.vb-filter-dynamic_title {
  white-space: nowrap;
  margin-right: 0.3em;
}

.vb-x-mobile .vb-filter-dynamic_filter {
  max-width: 450px;
  min-width: 100px;
}
</style>
