<template>
  <div class="vb-people-selector">
    <div
      v-if="undefined !== this.selectPeopleValue && (typeof(this.selectPeopleValue) !== 'number')"
      class="vb-form_holder vb-select-price-message"
    >
      <div class="vb-h5 vb-room-head_noprice">{{ $t('please-select-message') }}</div>
    </div>
    <div class="vb-form_holder">
      <label class="vb-custom-input">
        <span class="vb-form_label" v-if="showLabel">{{ $t('page-search_w-adults') }}{{ onlyNumber }}</span>
        <div class="vb-custom-input_input-box" v-if="!onlyOne">
          <base-select
            :model-value="modelValue"
            @update:modelValue="updateData"
            :options="options"
            :custom-label="customLabel"
            :disabled="disabled || options.length === 0"
            :searchable="false"
            :allowEmpty="false"
            :multiple="multiple"
            :showLabels="false"
            hide-one
            :can-deselect="false"
          >
          </base-select>
        </div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import {PropType} from "vue";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [Number, String],
      required: true
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showLabel: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    options: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    forActivity: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    selectPeopleValue: {},
  },
  computed: {
    onlyOne() {
      return !this.forActivity && this.options.length < 1;
    },
    onlyNumber(): string {
      if (this.onlyOne && this.options.length) {
        return ": " + this.options[0];
      }
      return "";
    },
  },
  methods: {
    customLabel(i: string | number) {
      let label = i;
      if (typeof i === "number") {
        label = `${this.$t("word_tc_person", i)}`;
      }
      return label;
    },
    updateData(val: any) {
      this.$emit("update:modelValue", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.vb-people-selector {
  width: 100%;
  flex: 1 1 50%;
}

.vb-tablet .vb-select-price-message {
  display: none;
}
</style>
