import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "productData.cartId" }
const _hoisted_2 = { class: "vb-order-table_row vb-order-table_row--header" }
const _hoisted_3 = {
  class: "vb-order-table_cell vb-order-table_cell--product-name",
  colspan: "2"
}
const _hoisted_4 = { class: "vb-order-table_cell" }
const _hoisted_5 = { class: "vb-order-table_cell vb-order-table_cell--product-data" }
const _hoisted_6 = { class: "vb-order-table_cell" }
const _hoisted_7 = {
  class: "vb-order-table_cell",
  align: "right"
}
const _hoisted_8 = { class: "vb-order-table_row" }
const _hoisted_9 = {
  class: "vb-order-table_cell vb-order-table_cell--result-price",
  colspan: "6",
  align: "right"
}
const _hoisted_10 = { class: "vb-order-table_rowong" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_currency = _resolveComponent("price-currency")!
  const _component_order_additional_row = _resolveComponent("order-additional-row")!

  return (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
    _createElementVNode("tr", _hoisted_2, [
      _createElementVNode("td", _hoisted_3, _toDisplayString($props.productData.product.unitName || $props.productData.product.name), 1),
      _createElementVNode("td", _hoisted_4, _toDisplayString($options.displayNights), 1),
      _createElementVNode("td", _hoisted_5, _toDisplayString($options.displayPeriod), 1),
      _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.$t('word_tc_person', $props.productData.reservationsData.numberOfPeople)), 1),
      _createElementVNode("td", _hoisted_7, [
        _createVNode(_component_price_currency, {
          "data-price": $props.productData.bookingMeta.priceSelected.calculatedPrice,
          "data-currency": $options.itemCurrency
        }, null, 8, ["data-price", "data-currency"])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.productData.bookingMeta.additionalSelected, (add) => {
      return (_openBlock(), _createBlock(_component_order_additional_row, {
        class: "vb-order-table_row--info",
        key: add.id,
        "add-data": add
      }, null, 8, ["add-data"]))
    }), 128)),
    _createElementVNode("tr", _hoisted_8, [
      _createElementVNode("td", _hoisted_9, [
        _createElementVNode("strong", _hoisted_10, [
          _createVNode(_component_price_currency, {
            "data-price": $props.productData.bookingMeta.totalPriceRoom,
            "data-currency": $options.itemCurrency
          }, null, 8, ["data-price", "data-currency"])
        ])
      ])
    ])
  ]))
}