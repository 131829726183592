<template>
<div class="datepicker__dummy-wrapper gap-8" :class="{'datepicker__dummy-wrapper--is-active': isOpen, 'vb--disable': disabled}">
  <div class="w-full">
    <p v-if="!singleDaySelection" class="text-sm text-black font-medium mb-1">{{ $t("word_checkIn") }}</p>
    <p v-else class="text-sm text-black font-medium mb-1">{{ $t("word_table-reservation-date") }}</p>
    <date-input
      :i18n="i18n"
      :input-date="checkIn"
      input-date-type="check-in"
      :is-open="isOpen"
      :show-datepicker="showDatepicker"
      :hide-datepicker="hideDatepicker"
      :toggle-datepicker="toggleDatepicker"
      :single-day-selection="singleDaySelection"
      :disabled="disabled"
    />
  </div>
  <div v-if="!singleDaySelection" class="w-full">
    <p class="text-sm text-black font-medium mb-1">{{ $t("word_checkOut") }}</p>
    <date-input
      :i18n="i18n"
      :input-date="checkOut"
      input-date-type="check-out"
      :is-open="isOpen"
      :showDatepicker="showDatepicker"
      :hide-datepicker="hideDatepicker"
      :toggle-datepicker="toggleDatepicker"
      :single-day-selection="singleDaySelection"
      :disabled="disabled"
    />
  </div>
  <!-- <button
    v-show="showClearSelectionButton"
    tabindex="0"
    @click='clearSelection'
  /> -->
</div>
</template>

<script>
import DateInput from "./DateInput.vue";

export default {
  name: "DatePickerOpener",
  components: {
    DateInput,
  },
  props: {
    checkIn: { type: String },
    checkOut: { type: String },
    isOpen: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    displayClearButton: { default: true, type: Boolean },
    singleDaySelection: { default: false, type: Boolean },
    i18n: { type: [Object, Function] },
  },
  emits: ["clear-selection", "set-is-open"],
  computed: {
    showClearSelectionButton() {
      return Boolean(
        (this.checkIn || this.checkOut) && this.displayClearButton
      );
    },
  },

  methods: {
    clearSelection() {
      this.$emit("clear-selection");
    },
    hideDatepicker() {
      this.$emit("set-is-open", false);
    },
    showDatepicker() {
      this.$emit("set-is-open", true);
    },
    toggleDatepicker() {
      if (!this.isOpen) {
        this.$emit("set-is-open", !this.isOpen);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../scss/base";
.datepicker__dummy-wrapper-icon {
  text-align: center;
  width: 3rem;
  font-size: 1.5rem;
}
.datepicker__clear-button {
  margin: 0;
  height: $input-height;
  line-height: $input-height;
  text-align: center;
  &:focus {
    outline: 1px dashed var(--vb-color-primary);
    outline-offset: -2px;
  }
}
.datepicker__dummy-wrapper {
  .datepicker__input {
    background-color: white;
    border-radius: 4px;
    color: #475467;
  }
}
</style>
