import { RequestError, i_reservation } from "visbook-api";
import i18n from "@/i18n";
import { i_cart, i_cartItem } from "@/store/store";
import { requestVB, setLocalCart } from "@/core/helpers";
import { ActionContext, Dispatch } from "vuex";
import { app } from "@/main";

import { getItemDetailsGTAG } from "@/store/gtags-helpers";

const namespaced = true;

function okReserve(dispatch: Dispatch) {
  dispatch(
    "mod_globalView/SHOW_notification",
    {
      message: i18n.global.t("cart_mess_reserve-ok"),
      type: "success",
    },
    { root: true }
  );
}
function errorReserve(dispatch: Dispatch, errorMessage: string) {
  dispatch(
    "mod_globalView/SHOW_notification",
    {
      message: [i18n.global.t("cart_mess_reserve-err"), errorMessage],
      type: "error",
    },
    { root: true }
  );
}

const state: i_cart = {
  selectedProducts: [],
  canNextUpdate: true,
  displayCard: false,
};

const getters = {
  displayCard(state: i_cart) {
    return state.displayCard;
  },
  selectedProducts(state: i_cart) {
    return state.selectedProducts;
  },
  cartRequestList(state: i_cart) {
    return state.selectedProducts.map((el) => el.reservationsOk).flat();
  },
  cartTotalPrice(state: i_cart) {
    return state.selectedProducts.reduce((sum: number, el: i_cartItem) => {
      const price = el.bookingMeta?.totalPriceRoom ?? 0;
      return sum + price;
    }, 0);
  },
};

const mutations = {
  set_displayCard(state: i_cart, value: boolean) {
    state.displayCard = value;
  },
  set_productList(state: i_cart, cartList: i_cartItem[]) {
    state.selectedProducts = cartList;
    setLocalCart(state.selectedProducts);
  },
  add_product(state: i_cart, product: i_cartItem) {
    state.selectedProducts.push(product);
    setLocalCart(state.selectedProducts);
  },
  update_product(state: i_cart, newProduct: i_cartItem) {
    const deletedIndex = state.selectedProducts.findIndex(
      (el: i_cartItem) => el.cartId === newProduct.cartId
    );
    state.selectedProducts.splice(deletedIndex, 1, newProduct);
    setLocalCart(state.selectedProducts);
  },
  rem_product(state: i_cart, id: string) {
    state.selectedProducts = [
      ...state.selectedProducts.filter((el: i_cartItem) => el.cartId !== id),
    ];
    setLocalCart(state.selectedProducts);
  },
  set_canNextUpdate(state: i_cart, val: boolean) {
    state.canNextUpdate = val;
  },
};

const actions = {
  SET_displayCard({ commit }: ActionContext<i_cart, any>, value: boolean) {
    commit("set_displayCard", value);
  },
  SET_productList(
    { commit }: ActionContext<i_cart, any>,
    cartList: i_cartItem[]
  ) {
    commit("set_productList", cartList);
  },
  ADD_product(
    { commit, dispatch, rootGetters }: ActionContext<i_cart, any>,
    cartItem: i_cartItem
  ): Promise<any> {
    app.config.globalProperties.$gtag.event(
      "add_to_cart",
      getItemDetailsGTAG(cartItem, rootGetters)
    );

    return requestVB(
      app.config.globalProperties.$visbook.CREATE_reservations,
      cartItem.reservationsData
    )
      .then((reservationsInfo: i_reservation[]) => {
        const reservationsOk = reservationsInfo.filter(
          (el: i_reservation) => el.reservationId !== 0
        );
        const listLength = reservationsInfo.length;
        const okLength = reservationsOk.length;
        if (okLength) {
          const newProduct = {
            ...cartItem,
            reservationsOk,
            cartId:
              reservationsInfo[0].reservationId.toString() +
              reservationsInfo[0].encryptedCompanyId,
          };
          commit("add_product", newProduct);
          dispatch(
            "mod_search/EDIT_availableUnits",
            {
              id: cartItem.product.id,
              checkIn: cartItem.reservationsData.fromDate,
              checkOut: cartItem.reservationsData.toDate,
            },
            { root: true }
          );
          dispatch(
            "mod_globalView/SHOW_notification",
            {
              message:
                okLength === listLength
                  ? i18n.global.t("cart_mess_reserve-ok")
                  : i18n.global.t("cart_mess_reserve-not-ok", {
                      okLength,
                      listLength,
                    }),
              type: "success",
            },
            { root: true }
          );
        } else errorReserve(dispatch, "");
      })
      .catch((error: RequestError) => {
        errorReserve(dispatch, error.data.error);
        throw error;
      });
  },
  UPDATE_product(
    { commit, dispatch, rootGetters }: ActionContext<i_cart, any>,
    cartItem: i_cartItem
  ): Promise<any> {
    app.config.globalProperties.$gtag.event(
      "remove_from_cart",
      getItemDetailsGTAG(cartItem, rootGetters)
    );
    app.config.globalProperties.$gtag.event(
      "add_to_cart",
      getItemDetailsGTAG(cartItem, rootGetters)
    );

    return requestVB(
      app.config.globalProperties.$visbook.UPDATE_reservations,
      cartItem.reservationsData,
      cartItem.reservationsOk[0].encryptedCompanyId,
      cartItem.reservationsOk[0].reservationId
    )
      .then((/*reservationsInfo: i_reservation[]*/) => {
        okReserve(dispatch);
        commit("update_product", cartItem);
        return;
      })
      .catch((error: RequestError) => {
        errorReserve(dispatch, error.data.error);
        throw error;
      });
  },
  REM_product(
    { commit, dispatch, rootGetters }: ActionContext<i_cart, any>,
    cartItem: i_cartItem
  ): Promise<any> {
    app.config.globalProperties.$gtag.event(
      "remove_from_cart",
      getItemDetailsGTAG(cartItem, rootGetters)
    );

    return requestVB(
      app.config.globalProperties.$visbook.DELETE_reservations,
      cartItem.reservationsOk[0]
    )
      .then((/*reservationsInfo: i_reservation[]*/) => {
        removeItem(cartItem);
        return;
      })
      .catch((error: RequestError) => {
        if (error.status === 404) {
          removeItem(cartItem);
        } else {
          dispatch(
            "mod_globalView/SHOW_notification",
            {
              message: [
                i18n.global.t("cart_mess_remove-reserve-err"),
                error.message,
              ],
              type: "error",
            },
            { root: true }
          );
          throw error;
        }
      });

    function removeItem(cartItem: any) {
      commit("rem_product", cartItem.cartId);

      dispatch("mod_search/SET_idToRefresh", cartItem.product.id, {
        root: true,
      });

      dispatch(
        "mod_globalView/SHOW_notification",
        {
          message: i18n.global.t("cart_mess_remove-reserve-ok"),
          type: "success",
        },
        { root: true }
      );
    }
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
