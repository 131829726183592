<template>
  <div
      class="vb-room-head_cost vb-room-offer_aside"
      v-if="isSearchPerformed"
  >
    <div class="vb-h5 vb-room-head_noprice" v-if="!configurationOk">
      {{ $t('invalid-person-setup') }}
    </div>
    <div class="vb-h5 vb-room-head_noprice vb-select-price-message"
         v-else-if="typeof(this.selectPeopleValue) !== 'number'">
      {{ $t('please-select-message') }}
    </div>
    <template v-else-if="modelValue">
      <span class="vb-form_label">{{ $t('word_price') }}</span>
      <multiselect
          class="vb-room-head_price multiselect"
          :model-value="selectedId"
          @update:modelValue="updatePrice($event)"
          :options="priceList"
          label="label"
          value-prop="value"
          track-by="value"
          :allowEmpty="false"
          :showLabels="false"
          :searchable="false"
          :disabled="priceList && priceList.length < 2"
          :caret="!(priceList && priceList.length < 2)"
          :can-deselect="false"
      >
        <template #singlelabel="props">
          <div class="multiselect__label">
            <span class="vb-room-head_price-name multiselect__title">{{ props.value.label }}</span>
            <span class="vb-room-head_price-price multiselect__price">{{ pricePerStep }} {{ currency }}</span>
            <span class="vb-room-head_price-type multiselect__unit">{{ unit }}</span>
          </div>
        </template>
        <template #option="props">
          <span class="vb-room-head_price-option-name">{{ props.option.label }}</span>
          <span class="vb-room-head_price-option-price">{{ props.option.price }}</span>
        </template>
      </multiselect>
    </template>
    <div class="vb-h5 vb-room-head_noprice" v-else>{{ $t('no-valid-price') }}</div>
  </div>
</template>

<script lang="ts">
import {i_select} from "@/core/declarations";
import {productItem_price} from "visbook-api";
import {toMoneyFormat} from "@/core/helpers";
import Multiselect from "@vueform/multiselect";

import {PropType} from "vue";

export default {
  emits: ["update:modelValue"],
  props: {
    priceInfo: {
      type: Array as PropType<productItem_price[]>,
    },
    selectPeopleValue: {},
    modelValue: {
      type: Object as PropType<productItem_price | null>,
    },
    configurationOk: {
      type: Boolean as PropType<boolean>,
    },
  },
  components: {
    Multiselect
  },
  mounted() {
    if (!this.modelValue) {
      if (this.priceList.length > 0) {
        this.updatePrice(this.priceList[0]);
      } else {
        this.updatePrice();
      }
    }
  },
  computed: {
    priceList(): i_select[] {
      return this.priceInfo
          .map((el: any) => {
            return {
              label: el.name,
              price: el.pricePerStep.toFixed(2) + " " + this.currency,
              ptype: el.priceType,
              value: el.id,
              order: el.name.toLowerCase(),
            };
          })
          .sort((a: any, b: any) => {
            if (a.order < b.order) {
              return -1;
            }
            if (a.order > b.order) {
              return 1;
            }
            return 0;
          });
    },
    selectedId() {
      return this.modelValue ? this.modelValue.id : "";
    },
    isSearchPerformed(): boolean {
      return this.$store.getters["mod_search/GET_isSearchPerformed"];
    },
    currency() {
      return this.$store.getters["mod_company/companyCurrency"];
    },
    unit() {
      switch (this.modelValue?.priceType as string) {
        case "unit":
        case "perDay":
          return this.$t("page-search_per-night") as string;
        case "perHour":
          return this.$t("page-search_per-hour") as string;
        case "perPerson":
          return this.$t("page-search_per-person") as string;
        case "perPersonAndHour":
          return this.$t("page-search_per-person-hour") as string;
      }
      return "";
    },
    pricePerStep() {
      return toMoneyFormat(this.modelValue?.pricePerStep);
    },
  },
  watch: {
    priceInfo() {
      this.updatePrice(this.selectedId);
    },
  },
  methods: {
    updatePrice(select?: i_select | "") {
      const selId = select
      const currPrice = this.priceInfo.find((el: any) => el.id === selId);

      const priceInfo = this.priceInfo
          .map((i: any) => i)
          .sort((a: any, b: any) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
      const emitData = currPrice || priceInfo[0];
      this.$emit("update:modelValue", emitData);
    },
  },
};
</script>

<style lang="scss" scoped>
.vb-room-head_price::v-deep {
  &.multiselect--disabled {
    opacity: 1;

    .multiselect__select {
      display: none;
    }
  }

  .multiselect__option {
    display: flex;
  }

  .multiselect__content-wrapper {
    right: 0;
  }

  &.multiselect {
    height: auto;
  }
}

.vb-tablet .vb-room-head_cost {
  padding-left: 9px;
}

.vb-select-price-message {
  display: none;

  .vb-tablet & {
    display: inherit;
  }
}

.vb-room-head_price-price,
.vb-room-head_price-type,
.vb-room-head_price-name {
  display: block;
  font-weight: bold;
  text-align: right;
  overflow: hidden;
}

.vb-room-head_price-type,
.vb-room-head_price-name {
  opacity: 0.6;
}

.vb-room-head_price-price {
  font-size: 1.3em;
}

.vb-room-head_price-currency {
  font-size: smaller;
  margin-left: 8px;
}

.vb-room-head_price-option-name {
  margin-right: 1em;
}

.vb-room-head_price-option-price {
  margin-left: auto;
}

.multiselect {
  border-radius: 0;
  &__label {
    display: flex;
    flex-direction: column;
    margin-right: 6px;
  }

  &__title {
    font-size: 18px;
  }

  &__unit {
    font-size: 16px;
    font-weight: 800 !important;
  }

  :deep(.multiselect-clear) {
    display: none;
  }

  :deep(.multiselect-dropdown) {
    @media screen and (min-width: 790px) {
      width: 350px;
      right: 0;
      left: auto;
    }
  }

  :deep(.is-selected) {
    background: var(--vb-color-primary) !important;
  }


  &.is-active{
    box-shadow: none;
    border: 1px solid var(--vb-color-primary);
  }

  &.is-disabled{
    background: white;
  }

  :deep(.multiselect-dropdown::-webkit-scrollbar) {
    display: none;
  }
}

</style>
