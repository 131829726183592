import {
  out_company,
  i_country,
  out_setup,
  out_order,
  out_orderGroup,
  in_reservations,
  i_reservation,
  i_user,
  i_productItem,
  productItem_price,
  i_reservationsItem,
  productItem_addServe,
} from "visbook-api";
import { i_select, i_roomTypeItem } from "@/core/declarations";
import { RouteLocation } from "vue-router";

// export interface storeActions {
//   commit: (name: string, val?: any, options?: object) => () => void;
//   dispatch: (name: string, val?: any, options?: object) => () => void;
//   state: { [key: string]: any };
//   getters: { [name: string]: any };
//   rootGetters: { [key: string]: () => any };
//   rootState: { [key: string]: any };
// }
/*company*/
export interface i_timeZoneInfo {
  displayName: string;
  iana: string;
  offset: string;
}
export interface i_out_company extends out_company {
  timeZoneInfo: i_timeZoneInfo;
  properties: any | null;
}
export interface i_analytics {
  id: number;
  name: string;
  accountId: string;
}

export interface i_out_setup extends out_setup {
  allowCustomerRegistration: boolean;
  analytics: i_analytics[];
}
export interface i_company {
  companyInfo: i_out_company | null;
  companyInfoPromise: Promise<any> | null;
  companySettings: i_out_setup | null;
  setupPromise: Promise<any> | null;
  companies: {[encryptedCompanyId: string]: Promise<string>}
}

/*globalView*/
export enum loginWay_type {
  EMPTY = "",
  EMAIL = "email",
  PHONE = "phone",
}
export interface i_globalView {
  requestLoader?: number;
  splashScreen: number;
  isDisableScroll?: boolean;
  siteLanguage?: string;
  modalComponent?: string;
  countries?: i_country[];
  notifications: any[];
  loginWay: loginWay_type;
  profileActiveTabName?: string;
}
export interface i_siteLanguage {
  lang: string;
  to: RouteLocation;
  skipReplace?: boolean;
}
export interface i_notification {
  message: string[];
  type: notification_type;
}
export enum notification_type {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}
export enum splashScreenStopper_contant {
  LANGUAGE = 1,
  COMPANYINFO = 2,
}

/*orders*/
export interface i_orders {
  activeOrder: out_order | null,
  activeOrderGroups: out_orderGroup | null
  selectedOrder: {
    id: null | number;
    currency: string;
  };
  orders: out_order[];
  orderGroups: out_orderGroup[];
}

/*user*/
export interface i_userStore {
  userAvatar: any;
  userProfile: i_user | null;
  userPromise: Promise<any> | null;
  paymentMethod: string | null;
  giftCards: any[];
}

/*search*/
export interface i_search {
  allRooms: i_product[];
  filterSettings: i_filterSettings;
  filterSettingsDynamic: i_filterSettingsDynamic;
  roomTypeList: i_roomTypeItem[];
  sortByList: i_select[];
  lastUrl: string;
  sorted: boolean;
  activitiesState: boolean;
  restaurantsState: boolean;
  accommodationsState: boolean;
  filterPeopleCount: any;
  filterPeopleOptions: number[];
  id_to_refresh: number;
  search_performed: boolean;
}
export interface i_filterSettings {
  checkIn: string;
  checkOut: string;
  checkInDate: Date | null;
  checkOutDate: Date | null;
  nights: number;
  adults: string;
  child: string;
  allPersons: number;
}

export interface i_filterSettingsDynamic {
  roomType: i_select[];
  sortBy: i_select | null | string;
}
/*cart*/
export interface i_cart {
  selectedProducts: i_cartItem[];
  canNextUpdate: boolean;
  displayCard: boolean;
}

export interface i_cartAdditional extends productItem_addServe {
  count: number;
  totalPrice: number;
}

export interface i_cartItem {
  product: i_product;
  bookingMeta: {
    totalPriceRoom?: number;
    nights: number;
    priceSelected: null | productItem_price;
    additionalSelected: i_reservationsItem[];
  };
  reservationsOk: i_reservation[];
  reservationsData: in_reservations;
  cartId: string;
  guestsNames: string[];
}

export interface i_productItemSortable extends i_productItem {
  orderNumber: number | null;
  unitName: string | null;
}

export type i_product = i_productItemSortable;

export interface i_bookingMeta {
  totalPriceRoom?: number;
  nights: number;
}
