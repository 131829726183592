import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b4c8c5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-terms" }
const _hoisted_2 = { class: "vb-terms_body" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "vb-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", { innerHTML: $options.termsText }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "vb-btn vb-bg-primary",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.closeModal && $options.closeModal(...args)))
      }, "Ok")
    ])
  ]))
}