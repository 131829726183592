import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c85293c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-room-addit_row" }
const _hoisted_2 = { class: "vb-addit-cell_fix-s vb-addit-cell_ptb" }
const _hoisted_3 = { class: "vb-addit-cell_name vb-addit-cell_pt" }
const _hoisted_4 = { class: "vb-addit-cell_fix-m" }
const _hoisted_5 = { class: "vb-addit-cell_fix-m vb-addit-cell_pt" }
const _hoisted_6 = { class: "vb-addit-cell_fix-m vb-addit-cell_pt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_checkbox = _resolveComponent("base-checkbox")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_price_currency = _resolveComponent("price-currency")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      (_openBlock(), _createBlock(_component_base_checkbox, {
        "model-value": $options.isSelected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.isSelected) = $event)),
        disabled: $options.isDisabled,
        "no-text": true,
        required: $options.isRequired,
        key: $props.rerenderKey
      }, null, 8, ["model-value", "disabled", "required"]))
    ]),
    _createElementVNode("td", _hoisted_3, _toDisplayString($props.itemAdd.name), 1),
    _createElementVNode("td", _hoisted_4, [
      _createVNode(_component_base_input, {
        class: "vb-room-addit_input-num vb-small-text",
        type: "number",
        max: $options.quantityMax,
        min: $options.quantityMin,
        enableReadOnly: "",
        disabled: !$options.isSelected || $options.isDisabled,
        "model-value": $options.quantity,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.quantity) = $event)),
        "model-valueModifiers": { number: true }
      }, null, 8, ["max", "min", "disabled", "model-value"])
    ]),
    _createElementVNode("td", _hoisted_5, [
      _createVNode(_component_price_currency, {
        "data-price": $props.itemAdd.price,
        "data-currency": $options.itemPriceCurrency
      }, null, 8, ["data-price", "data-currency"])
    ]),
    _createElementVNode("td", _hoisted_6, [
      _createVNode(_component_price_currency, {
        "data-price": $props.itemAdd.totalPrice,
        "data-currency": $options.itemCurrency
      }, null, 8, ["data-price", "data-currency"])
    ])
  ]))
}