import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "vb-order-buttons" }
const _hoisted_2 = {
  key: 0,
  class: "vb-form_holder vb-text-center"
}
const _hoisted_3 = {
  key: 1,
  class: "vb-form_holder vb-text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.disabledBtn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          false
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("button", {
                  class: "vb-w-100 vb-btn vb-bg-primary vb-text-uppercase",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.startBooking && $options.startBooking(...args)))
                }, _toDisplayString(_ctx.$t('page-search_btn_book-now')), 1),
                _createElementVNode("span", null, "— " + _toDisplayString(_ctx.$t('word_or')) + " —", 1)
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "vb-btn vb-bg-primary vb-w-100",
            type: "button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.addToCart && $options.addToCart(...args)))
          }, _toDisplayString($options.addCartButtonText), 1)
        ]))
      : ($options.runOutProductCount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('run_out_product_count')), 1),
            _createElementVNode("button", {
              class: "vb-w-100 vb-btn vb-bg-primary vb-text-uppercase",
              type: "button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.moveToCheckout && $options.moveToCheckout(...args)))
            }, _toDisplayString(_ctx.$t('page-search_btn_move-to-checkout')), 1)
          ]))
        : _createCommentVNode("", true)
  ]))
}