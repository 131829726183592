import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a8d5de6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-aside-inner" }
const _hoisted_2 = { class: "vb-custom-text-placeholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_aside = _resolveComponent("search-aside")!
  const _component_custom_text = _resolveComponent("custom-text")!
  const _component_the_pagination = _resolveComponent("the-pagination")!
  const _component_layout_base = _resolveComponent("layout-base")!
  const _component_template_base = _resolveComponent("template-base")!

  return (_openBlock(), _createBlock(_component_template_base, null, {
    default: _withCtx(() => [
      _createVNode(_component_layout_base, null, {
        aside: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_search_aside, { "all-items": $options.resultList }, null, 8, ["all-items"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_custom_text, { type: "A" })
            ])
          ])
        ]),
        main: _withCtx(() => [
          _createVNode(_component_the_pagination, {
            "all-items": $options.resultList,
            "message-empty-list": _ctx.$t('page-search_empty-list')
          }, {
            default: _withCtx(({itemData}) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(itemData.type), { "item-data": itemData }, null, 8, ["item-data"]))
            ]),
            _: 1
          }, 8, ["all-items", "message-empty-list"])
        ]),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}