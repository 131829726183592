import { checkedDateString, convertToDateFormat } from "@/core/helpers";
import {
  in_reservations,
  i_reservationsItem,
  productItem_stepsAv,
} from "visbook-api";
import {
  i_filterSettings,
  i_bookingMeta,
  i_product,
  i_cartItem,
} from "@/store/store";
import {
  i_checkinApi,
  i_checkinPeriod,
  i_productPriceInfo,
} from "@/core/declarations";
import Helpers from "@/components/forms/hotel-date-picker/date-picker/helpers.js";

import { PropType } from "vue";

interface Data {
  filterPeopleCountLocal: number | null;
  searchFilter: i_filterSettings;
  searchFilterDates: i_checkinApi;
  priceCurrentSelected: null | i_productPriceInfo;
  additionalServicesSelected: i_reservationsItem[];
  availableDates: i_checkinApi;
}

export default {
  props: {
    itemData: {
      type: Object as PropType<i_product>,
      default: () => null,
    },
  },
  created() {
    this.searchFilter = { ...this.filterSettings };
    if (
      this.configurationOk &&
      null !== this.itemData.defaultPeople &&
      !this.noValidDefaultPeople
    ) {
      this.searchFilter.allPersons = this.itemData.defaultPeople;
    } else {
      this.searchFilter.allPersons = -1;
    }
    this.filterPeopleCountLocal = this.filterPeopleCount
      ? +this.filterPeopleCount
      : null;
  },
  data: () =>
    ({
      filterPeopleCountLocal: null,
      searchFilter: {
        checkIn: "",
        checkOut: "",
        checkInDate: null,
        checkOutDate: null,
        nights: 0,
        adults: "0",
        child: "0",
        allPersons: 0,
      },
      searchFilterDates: {
        checkIn: "",
        checkOut: "",
      },
      totalPriceRoom: 0,
      priceCurrentSelected: null,
      additionalServicesSelected: [],
      availableNumber: 0,
      availableDates: {
        checkIn: "",
        checkOut: "",
      },
      peopleValue: null,
    } as Data),
  computed: {
    reservationsData(): in_reservations {
      return {
        webProductId: this.itemData.id,
        fromDate:
          this.availableDates.checkIn || (this.searchFilter.checkIn as string),
        toDate:
          this.availableDates.checkOut ||
          (this.searchFilter.checkOut as string),
        priceId: this.priceCurrentSelected?.id ?? 0,
        numberOfPeople: this.allPersonsCurrentRoom,
        notes: "",
        guestsNames: "",
        additionalServices: this.additionalServicesSelected.map((el: any) => ({
          id: el.id,
          encryptedCompanyId: el.encryptedCompanyId,
          count: el.count,
        })),
        additionalMerchandises: [],
      };
    },
    metaData(): i_bookingMeta {
      return {
        totalPriceRoom: this.totalPriceRoom,
        nights: this.searchFilter.nights,
      };
    },
    filterSettings(): i_filterSettings {
      return this.$store.state.mod_search.filterSettings;
    },
    isSearchPerformed(): boolean {
      return this.$store.getters["mod_search/GET_isSearchPerformed"];
    },
    allPersonsCurrentRoom(): number {
      const allPersons: number = this.searchFilter.allPersons;
      if ("string" === typeof allPersons) {
        if (isNaN(allPersons)) {
          return -1;
        }
      }
      return +allPersons;
      // return 'string' === typeof (this.searchFilter.allPersons) ? -1 : this.searchFilter.allPersons;
    },
    selectedProducts(): i_cartItem[] {
      return this.$store.getters["mod_cart/selectedProducts"];
    },
    productsInCart(): number {
      return this.selectedProducts.filter((i: i_cartItem) => {
        if (i.product.id !== this.itemData.id) {
          return false;
        }
        return !(
          this.reservationsData.toDate < i.reservationsData.fromDate ||
          i.reservationsData.toDate < this.reservationsData.fromDate
        );
      }).length;
    },
    productAvailableUnits(): number {
      return (
        this.availableNumber ||
        this.itemData?.availability?.steps?.[0]?.availableUnits ||
        0
      );
    },
    disableAddBtn(): boolean {
      if (this.restaurantsState) {
        return (
          0 >= this.productAvailableUnits ||
          this.peopleValue > this.productAvailableUnits
        );
      }
      return 0 >= this.productAvailableUnits;
    },
    isAvailability(): boolean {
      return (
        this.itemData?.availability.available ||
        // 2022-06-27:
        // #311 No availability calendar button on a product
        // Peter: Perhaps in the future it makes sense to not show it if there's some condition where the api can say "There's no future prices for this either", but for now just remove that check
        // || (this.itemData?.availability.available === false && this.itemData?.availability.reason === 'noPricesFound')
        (!!this.availableDates.checkIn && !!this.availableDates.checkOut)
      );
    },
    showProductCalendar(): boolean {
      const { checkIn, checkOut } = this.filterSettings;
      if ("" === checkIn && "" === checkOut) {
        return true;
      }
      const { from, to } = getProductSearchDays(
        this.itemData?.availability.steps
      );
      return (
        dateToNumber(checkIn) < dateToNumber(from) ||
        dateToNumber(checkOut) > dateToNumber(to)
      );

      function getProductSearchDays(steps: productItem_stepsAv[]) {
        let from = "";
        let to = "";
        if (steps.length) {
          const listDays = new Set();
          steps.forEach((d) => {
            listDays.add(convertToDateFormat(d.from));
            listDays.add(convertToDateFormat(d.to));
          });
          const daysArr: string[] = [...listDays].sort() as string[];
          from = daysArr[0];
          to = daysArr[daysArr.length - 1];
        }
        return { from, to };
      }
      function dateToNumber(date: string): number {
        return +date.split("-").join("");
      }
    },
    restaurantsState() {
      return this.$store.getters["mod_search/GET_restaurantsState"];
    },
    filterPeopleCount() {
      return this.$store.getters["mod_search/GET_filterPeopleCount"];
    },
    noValidDefaultPeople(): boolean {
      if (
        null !== this.itemData.defaultPeople &&
        this.itemData.minPeople <= this.itemData.defaultPeople &&
        this.itemData.defaultPeople <= this.itemData.maxPeople
      ) {
        return false;
      }
      return true;
    },
    selectPeopleValue: {
      get(): any {
        if (this.peopleValue) {
          return this.peopleValue;
        }
        // if ('restaurant' === this.itemData.type && this.itemData.minPeople) {
        //   return this.itemData.minPeople;
        // }
        if (
          null !== this.itemData.defaultPeople &&
          !this.noValidDefaultPeople
        ) {
          return this.itemData.defaultPeople;
        }
        return this.$t("please-select") as string;
      },
      set(value: any) {
        this.searchFilter.allPersons = value;
        this.peopleValue = value;
      },
    },
    selectPeopleOptions(): any[] {
      const list = [];
      // const availableNumberOfPeople = this.itemData.prices.find((i => i.id === this.priceCurrentSelected?.id))?.availableNumberOfPeople;
      // if(availableNumberOfPeople && availableNumberOfPeople.length > 0) {
      //   const totalFrom: any = Math.min(...Array.from(new Set(availableNumberOfPeople.map((i: any) => i.from))));
      //   const totalTo: any = Math.max(...Array.from(new Set(availableNumberOfPeople.map((i: any) => i.to))));
      //   const exclude: any = Array.from(new Set(availableNumberOfPeople.map((i: any) => i.exclude)));

      //   if (!this.peopleValue && this.noValidDefaultPeople) {
      //     list.push(this.$t('please-select') as string)
      //   }
      //   for (let i = totalFrom || 1; i <= totalTo; i++) {
      //     if(!exclude.find((num:number) => num === i)) {
      //       list.push(i)
      //     }
      //   }
      // }
      const from = this.itemData.minPeople || 1;
      let to = this.itemData.maxPeople;
      if ("restaurant" === this.itemData.type) {
        to = Math.min(to, this.productAvailableUnits);
      }
      for (let i = from; i <= to; i++) {
        list.push(i);
      }
      return list;
    },
    configurationOk(): boolean {
      if (this.itemData.minPeople > this.itemData.maxPeople) {
        return false;
      }
      if (this.itemData.maxPeople <= 0 || this.itemData.minPeople <= 0) {
        return false;
      }
      return true;
    },
    needRefreshProduct() {
      return this.$store.getters["mod_search/GET_idToRefresh"];
    },
  },
  watch: {
    needRefreshProduct(productId: number) {
      if (productId === this.itemData.id) {
        const from = convertToDateFormat(this.searchFilter.checkInDate as Date);
        const to = convertToDateFormat(this.searchFilter.checkOutDate as Date);
        if (from) {
          this.$store.dispatch("mod_search/GET_webProductById", {
            id: productId,
            from,
            to,
            numberOfPersons: this.allPersonsCurrentRoom,
            pensionType: "unspecified",
          });
        }
        this.$store.dispatch("mod_search/RESET_idToRefresh");
      }
    },
    allPersonsCurrentRoom(ewVal: number, oldVal: number) {
      if (!oldVal) return;
      const from = convertToDateFormat(this.searchFilter.checkInDate as Date);
      const to = convertToDateFormat(this.searchFilter.checkOutDate as Date);
      if (!from || !to) return;

      const numberOfPersons =
        this.allPersonsCurrentRoom === -1 && this.restaurantsState
          ? this.filterPeopleCount
          : this.allPersonsCurrentRoom;

      this.$store.dispatch("mod_search/GET_webProductById", {
        id: this.itemData.id,
        from,
        to,
        numberOfPersons: numberOfPersons,
        pensionType: "unspecified",
      });
    },
  },
  methods: {
    setAvailableDates(dates: i_checkinPeriod) {
      this.availableDates = checkedDateString(dates.checkIn, dates.checkOut);
    },
    setCheckInOutDates({ checkIn, checkOut }: i_checkinPeriod) {
      this.searchFilter.checkInDate = checkIn;
      this.searchFilter.checkOutDate = checkOut;

      const checkInOut = checkedDateString(checkIn, checkOut);
      this.searchFilter.checkIn = checkInOut.checkIn;
      this.searchFilter.checkOut = checkInOut.checkOut;

      this.searchFilterDates.checkIn = checkInOut.checkIn;
      this.searchFilterDates.checkOut = checkInOut.checkOut;

      this.searchFilter.nights = Helpers.countDays(checkIn, checkOut);
    },
  },
};
