import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "vb-order-table_row" }
const _hoisted_3 = {
  class: "vb-order-table_cell",
  colspan: "6",
  align: "right"
}
const _hoisted_4 = { style: {marginRight: '1rem'} }
const _hoisted_5 = { style: {marginRight: '1rem'} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { style: {marginRight: '1rem'} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_currency = _resolveComponent("price-currency")!

  return ($props.orderGroupInfo)
    ? (_openBlock(), _createElementBlock("tfoot", _hoisted_1, [
        _createElementVNode("tr", _hoisted_2, [
          _createElementVNode("td", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("em", _hoisted_4, _toDisplayString(_ctx.$t('order-item_order-total')) + ":", 1),
              _createVNode(_component_price_currency, {
                class: "vb-text-bold",
                "data-price": $options.orderInformation.orderTotalPrice,
                "data-currency": $options.itemCurrency
              }, null, 8, ["data-price", "data-currency"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("em", _hoisted_5, _toDisplayString(_ctx.$t('order-item_order-total-paid')) + ":", 1),
              _createVNode(_component_price_currency, {
                class: "vb-text-bold",
                "data-price": $options.orderInformation.orderTotalPaid,
                "data-currency": $options.itemCurrency
              }, null, 8, ["data-price", "data-currency"])
            ]),
            ($options.orderInformation.orderTotalPrice - $options.orderInformation.orderTotalPaid > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("em", _hoisted_7, _toDisplayString(_ctx.$t('order-item_order-total-to-be-paid')) + ":", 1),
                  _createVNode(_component_price_currency, {
                    class: "vb-text-bold",
                    "data-price": $options.orderInformation.orderTotalPrice - $options.orderInformation.orderTotalPaid,
                    "data-currency": $options.itemCurrency
                  }, null, 8, ["data-price", "data-currency"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}