import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c23cc726"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vb-room-offer_secondary vb-room-offer_result vb-row--jc-sb"
}
const _hoisted_2 = { class: "vb-form_label" }
const _hoisted_3 = { class: "vb-offer-total_data vb-row vb-row--jc-sb" }
const _hoisted_4 = { class: "vb-offer-total_cell vb-offer-total_cell_nights" }
const _hoisted_5 = { class: "vb-offer-total_cell vb-offer-total_cell_price" }
const _hoisted_6 = { class: "vb-room-head_price-option-name vb-product-price-container-label" }
const _hoisted_7 = { class: "vb-product-price-container-value" }
const _hoisted_8 = { class: "vb-room-head_price-option-name" }
const _hoisted_9 = { class: "vb-room-head_price-option-price" }
const _hoisted_10 = {
  key: 1,
  class: "vb-product-price-container"
}
const _hoisted_11 = { class: "vb-product-price-container-label" }
const _hoisted_12 = { class: "vb-product-price-container-value" }
const _hoisted_13 = { class: "vb-product-price-container-label" }
const _hoisted_14 = { class: "vb-product-price-container-value" }
const _hoisted_15 = { class: "vb-text-uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_currency = _resolveComponent("price-currency")!
  const _component_base_select = _resolveComponent("base-select")!

  return ($props.priceCurrentSelected.calculatedPrice || $options.roomTotalPrice)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('page-search_order-info')), 1),
        _createElementVNode("dl", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            ($props.bookingNights)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('word_nights')) + ":", 1),
                  _createElementVNode("dd", null, _toDisplayString($props.bookingNights), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            ($options.allowPriceSelectionInCart)
              ? (_openBlock(), _createBlock(_component_base_select, {
                  key: 0,
                  class: "vb-product-price-container",
                  value: $options.selectedId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($options.updatePrice($event))),
                  options: $options.priceList,
                  label: "label",
                  "track-by": "value",
                  allowEmpty: false,
                  showLabels: false,
                  searchable: false
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.props.option.label), 1),
                    _createElementVNode("span", _hoisted_7, [
                      _createVNode(_component_price_currency, {
                        "data-price": _ctx.props.option.price,
                        "data-currency": $options.currency
                      }, null, 8, ["data-price", "data-currency"])
                    ]),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.props.option.label), 1),
                    _createElementVNode("span", _hoisted_9, [
                      _createVNode(_component_price_currency, {
                        "data-price": _ctx.props.option.price,
                        "data-currency": $options.currency
                      }, null, 8, ["data-price", "data-currency"])
                    ])
                  ], undefined, true),
                  _: 1
                }, 8, ["value", "options"]))
              : ($props.priceCurrentSelected.calculatedPrice)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString($props.priceCurrentSelected.name) + ":", 1),
                    _createElementVNode("span", _hoisted_12, [
                      _createVNode(_component_price_currency, {
                        "data-price": $props.priceCurrentSelected.calculatedPrice,
                        "data-currency": $options.itemCurrency
                      }, null, 8, ["data-price", "data-currency"])
                    ])
                  ]))
                : _createCommentVNode("", true),
            (this.hasAdditionals)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 2,
                  class: _normalizeClass(["vb-product-price-container", {'vb-product-price-container-narrow': $options.allowPriceSelectionInCart}])
                }, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('page-search_w-additionals')) + ":", 1),
                  _createElementVNode("span", _hoisted_14, [
                    _createVNode(_component_price_currency, {
                      "data-price": $options.additionalTotalPrice,
                      "data-currency": $options.itemCurrency
                    }, null, 8, ["data-price", "data-currency"])
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          ($options.roomTotalPrice)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["vb-offer-total_cell vb-offer-total_cell_total", {'vb-product-price-container-with-select': $options.allowPriceSelectionInCart}])
              }, [
                _createElementVNode("dt", _hoisted_15, _toDisplayString(_ctx.$t('word_total')) + ":", 1),
                _createElementVNode("dd", null, [
                  _createVNode(_component_price_currency, {
                    "data-price": $options.roomTotalPrice,
                    "data-currency": $options.itemCurrency
                  }, null, 8, ["data-price", "data-currency"])
                ])
              ], 2))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}