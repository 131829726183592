<template>
<div v-if="show" class="fixed left-0 right-0 top-0 bottom-0 z-[9999] p-4 bg-modalBg font-[lato]">
  <div class="absolute right-0 bottom-0 m-4 p-8 w-80 flex flex-col gap-4 bg-white rounded-2xl text-left tablet:text-xs mobile:text-xs">
    <div class="font-bold">{{ $t('cookie_consent-header') }}</div>
    <p>{{ $t('cookie_consent-description') }}</p>
    <ul class="decoration-auto">
      <p>{{ $t('word_read-more') }}:</p>
      <li>
        <a
          :href="visboolLink?.url"
          target="_blank"
          class="underline"
        >{{ visboolLink?.text }}</a>
      </li>
      <li v-if="companyLink">
        <a
          :href="companyLink.url"
          target="_blank"
          class="underline"
        >{{ companyLink.text }}</a>
      </li>
    </ul>

    <div class="w-full border-t border-t-solid border-t-iron"></div>

    <p>{{ $t('cookie_consent-question') }}</p>

    <div class="flex flex-col gap-1">
      <base-checkbox :disabled="true" v-model:model-value="mandatoryChecked">
        {{ $t('cookie_consent-mandatory') }}
      </base-checkbox>
      <base-checkbox v-model:model-value="statistics">
        {{ $t('cookie_consent-statistics') }}
      </base-checkbox>
    </div>

    <button class="vb-btn vb-bg-primary vb-w-100 mt-4" @click="acceptAll">
      {{ $t('cookie_consent-accept_all') }}
    </button>

    <button class="vb-btn vb-w-100" @click="saveCurrentSelection">
      {{ $t('cookie_consent-save_current_selection') }}
    </button>
  </div>
</div>
</template>

<script lang="ts">
import BaseCheckbox from "@/components/forms/base-checkbox.vue";
import { CookieConsentFunctional, CookieConsentStatistics } from "@/config";
import { getCookieConsentKey, checkUrl } from "@/core/helpers";
import store from "@/store";

interface Data {
  cLink: {
    url: string;
    text: string;
  } | null;
}

export default {
  components: { BaseCheckbox },
  data: () =>
    ({
      statistics: false,
      show: false,
      cLink: null,
      mandatoryChecked: true,
    } as Data),
  async created() {
    const key = await getCookieConsentKey();

    this.show = !this.$cookies.isKey(key);
    if ("" !== store.getters["mod_company/companyGACode"]) {
      store.dispatch("mod_company/GET_companyInfo").then(() => {
        const url = store.getters["mod_company/getCompanyInfo"]?.website;
        if (url) {
          this.cLink = checkUrl(url.replace(/\/$/, "") + "/cookie-privacy");
        }
      });
    }
  },
  computed: {
    visboolLink() {
      return checkUrl("https://visbook.com/privacy");
    },
    companyLink() {
      return this.cLink;
    },
  },
  methods: {
    acceptAll() {
      this.saveSelection(CookieConsentFunctional | CookieConsentStatistics);
    },
    saveCurrentSelection() {
      let allowed = CookieConsentFunctional;
      if (this.statistics) {
        allowed |= CookieConsentStatistics;
      }
      this.saveSelection(allowed);
    },
    async saveSelection(allowed: number) {
      const key = await getCookieConsentKey();

      this.$cookies.set(key, allowed, "1y");

      this.show = false;
      if (allowed & CookieConsentStatistics) {
        this.$gtag.optIn();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base";
</style>
