import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "vb-pagination-page vb-row vb-row--ai-c" }
const _hoisted_2 = { class: "vb-pagination-page_info" }
const _hoisted_3 = { class: "vb-pagination-page_list" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('pagination_pages-list', {page:$props.modelValue, totalPages: $options.totalPages})), 1),
    _createElementVNode("ul", _hoisted_3, [
      _withDirectives(_createElementVNode("li", null, [
        _createElementVNode("button", {
          class: "vb-btn vb-btn--icon",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.setPage(1)))
        }, "1")
      ], 512), [
        [_vShow, $options.showFirst]
      ]),
      _withDirectives(_createElementVNode("li", null, "...", 512), [
        [_vShow, $options.showStartDots]
      ]),
      _createElementVNode("li", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.pagesList, (page) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(["vb-btn vb-btn--icon", { 'vb-text-secondary': $props.modelValue === page }]),
            type: "button",
            key: page,
            onClick: ($event: any) => ($options.setPage(page))
          }, _toDisplayString(page), 11, _hoisted_4))
        }), 128))
      ]),
      _withDirectives(_createElementVNode("li", null, "...", 512), [
        [_vShow, $options.showStopDots]
      ]),
      _withDirectives(_createElementVNode("li", null, [
        _createElementVNode("button", {
          class: "vb-btn vb-btn--icon",
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.setPage($options.totalPages)))
        }, _toDisplayString($options.totalPages), 1)
      ], 512), [
        [_vShow, $options.showLast]
      ])
    ])
  ]))
}