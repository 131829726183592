import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vb-checkot-list"
}
const _hoisted_2 = {
  key: 1,
  class: "vb-h4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkout_user_info = _resolveComponent("checkout-user-info")!
  const _component_checkout_item_room = _resolveComponent("checkout-item-room")!
  const _component_layout_base = _resolveComponent("layout-base")!
  const _component_template_base = _resolveComponent("template-base")!

  return (_openBlock(), _createBlock(_component_template_base, null, {
    default: _withCtx(() => [
      _createVNode(_component_layout_base, null, {
        aside: _withCtx(() => [
          _createVNode(_component_checkout_user_info)
        ]),
        main: _withCtx(() => [
          ($options.cartList.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.cartList, (cartItem) => {
                  return (_openBlock(), _createBlock(_component_checkout_item_room, {
                    key: cartItem.cartId,
                    "cart-item": cartItem
                  }, null, 8, ["cart-item"]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.$t('cart_mess_empty')), 1))
        ]),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}