<template>
  <span class="vb-price-base">
    <span>{{ unitPrice }}</span>
    <template v-if="bitPrice && showDecimals">
      <span>{{bitPoint}}</span>
      <span>{{ bitPrice }}</span>
    </template>
  </span>
</template>

<script lang="ts">
interface points {
  [key: string]: {
    unit: string;
    bit: string;
  };
}

interface Data {
  points: points
}

export default {
  props: {
    dataPrice: {
      type: [String, Number],
      required: false
    },
    showDecimals: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    points: {
      de: {unit: ".", bit: ","},
      en: {unit: ",", bit: "."},
      no: {unit: " ", bit: ","},
      se: {unit: ".", bit: ","},
      dk: {unit: ".", bit: ","}
    }
  } as Data),
  computed: {
    currentLang() {
      return this.$store.state.mod_globalView.siteLanguage;
    },
    priceNumber(): number {
      const sum = this.dataPrice;
      return typeof sum === "number" ? sum : parseFloat(sum);
    },
    priceNumberUnit(): number {
      return Math.trunc(this.priceNumber);
    },
    unitPrice(): string {
      const unit = this.priceNumberUnit;
      const arrUnit = `${unit}`.split("");
      const sign = arrUnit[0] === "-" ? arrUnit[0] : "";
      if (sign) arrUnit.splice(0, 1);
      const arrUnitLength = arrUnit.length;
      const arrResult = [];
      for (let i = arrUnitLength; i > 0; i--) {
        const currIndex = arrUnitLength - i;
        if (i !== arrUnitLength && !(i % 3))
          arrResult.push(`${this.unitPoint}${arrUnit[currIndex]}`);
        else arrResult.push(arrUnit[currIndex]);
      }
      return [sign, ...arrResult].join("");
    },
    unitPoint(): string {
      return this.points[this.currentLang].unit;
    },
    bitPrice(): string {
      const sumNum = this.priceNumber;
      let sumString = sumNum.toString();
      if (sumString === "0") return "";
      if (sumNum - this.priceNumberUnit !== 0) sumString = sumString + "00";
      const arr = parseFloat(sumString)
          .toFixed(2)
          .toString()
          .split(".");
      return arr[1] !== "00" ? arr[1] : "";
    },
    bitPoint(): string {
      return this.points[this.currentLang].bit;
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "../../scss/base";
.vb-price-base {
  white-space: nowrap;
}
</style>
