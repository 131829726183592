import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "vb-room-head_info" }
const _hoisted_2 = { class: "vb-h4 vb-text-uppercase" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_info = _resolveComponent("details-info")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString($props.itemData.unitName || $props.itemData.name), 1),
    (!$props.forCheckout && $props.itemData.description.short)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          innerHTML: $options.shortDescription
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (!$props.forCheckout && $props.itemData.description.long)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("button", {
            class: "vb-btn vb-btn--link",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggleInfo && $options.toggleInfo(...args)))
          }, _toDisplayString($options.readMoreTxt), 1),
          _createVNode(_component_details_info, {
            class: "vb-booking-content--details",
            "show-info": _ctx.showInfo
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", { innerHTML: $options.longDescription }, null, 8, _hoisted_4)
            ], undefined, true),
            _: 1
          }, 8, ["show-info"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}