import { i_cartItem } from "../store";
import router from "../../router";
import { out_company } from "visbook-api";
import { gtag_cart_item, gtag_order_item } from "./gtags-interfaces";
import { order_bookings } from "visbook-api";

export function getSingleItemDetailsGTAG(
  cartItem: i_cartItem,
  companyInfo: out_company | any
) {
  const entityId = (router?.currentRoute as any)?.params?.entity;
  const item: gtag_cart_item = {
    item_id: cartItem.product.id,
    item_name: cartItem.product.name, // Name or ID is required.
    item_brand: companyInfo?.name ? companyInfo.name : null,
    item_list_id: entityId ? entityId : null,
    price: cartItem.bookingMeta.totalPriceRoom,
    item_category: cartItem.reservationsData.fromDate,
    item_category2: cartItem.reservationsData.toDate,
    item_variant: cartItem.product.type,
    quantity: 1,
  };
  return item;
}

export function getItemDetailsGTAG(
  cartItem: i_cartItem,
  rootGetters: { [key: string]: () => any }
) {
  const companyInfo = rootGetters["mod_company/getCompanyInfo"];
  const item = {
    currency: rootGetters["mod_company/companyCurrency"],
    items: [getSingleItemDetailsGTAG(cartItem, companyInfo)],
  };
  return item;
}

export function getSingleItemOrderDetailsDetailsGTAG(
  orderItem: order_bookings,
  companyInfo: out_company | any
) {
  const entityId = (router?.currentRoute as any)?.params?.entity;
  const item: gtag_order_item = {
    item_id: orderItem.id,
    item_name: orderItem.name,
    item_brand: companyInfo?.name ? companyInfo.name : null,
    item_category: orderItem.fromDate,
    item_category2: orderItem.toDate,
    item_list_id: entityId ? entityId : null,
    item_variant: orderItem.type,
    price: orderItem.price,
    quantity: orderItem.numberOfPeople,
  };
  return item;
}
