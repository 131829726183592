import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6644944"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vb-content-wrap"
}
const _hoisted_2 = { class: "vb-testmode_enabled" }
const _hoisted_3 = { class: "vb-testmode_enabled-title" }
const _hoisted_4 = { class: "vb-testmode_enabled-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($options.testModeEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('testmode-enabled-header')), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('testmode-enabled-message')), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}