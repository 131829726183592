import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-799993e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-aside-inner vb-order-summary-partial" }
const _hoisted_2 = { class: "vb-order-summary-row vb-order-summary-total" }
const _hoisted_3 = { class: "vb-order-summary_name_cell" }
const _hoisted_4 = { class: "vb-order-summary_price_cell" }
const _hoisted_5 = { class: "vb-order-summary-row" }
const _hoisted_6 = { class: "vb-order-summary_name_cell" }
const _hoisted_7 = { class: "vb-order-summary_price_cell" }
const _hoisted_8 = { class: "vb-order-summary-row vb-order-summary-topay" }
const _hoisted_9 = { class: "vb-order-summary_name_cell" }
const _hoisted_10 = { class: "vb-order-summary_price_cell" }
const _hoisted_11 = {
  key: 0,
  class: "vb-order-summary-row vb-order-summary-topay-rest"
}
const _hoisted_12 = { class: "vb-order-summary_name_cell" }
const _hoisted_13 = { class: "vb-order-summary_price_cell" }
const _hoisted_14 = { class: "vb-order-summary-row vb-order-summary-giftcards-rest" }
const _hoisted_15 = { class: "vb-order-summary_name_cell" }
const _hoisted_16 = { class: "vb-order-summary-row" }
const _hoisted_17 = { class: "vb-order-summary_name_cell" }
const _hoisted_18 = { class: "vb-order-summary_price_cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_currency = _resolveComponent("price-currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('word_total')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_price_currency, {
          "data-price": $options.cartTotalPrice,
          "data-currency": $options.itemCurrency
        }, null, 8, ["data-price", "data-currency"])
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('word_giftcards')), 1),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_price_currency, {
          "data-price": $options.giftCartPart,
          "data-currency": $options.itemCurrency
        }, null, 8, ["data-price", "data-currency"])
      ])
    ], 512), [
      [_vShow, $options.giftCartPart != 0]
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('order-summary_topay')), 1),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_price_currency, {
          "data-price": $options.amountToPay,
          "data-currency": $options.itemCurrency
        }, null, 8, ["data-price", "data-currency"])
      ])
    ]),
    ($options.showRestToPay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString($options.restToPayLabel), 1),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_price_currency, {
              "data-price": $options.restToPay,
              "data-currency": $options.itemCurrency
            }, null, 8, ["data-price", "data-currency"])
          ])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('giftcard_new_balance', $props.giftcards.length)), 1)
    ], 512), [
      [_vShow, $options.giftCartPart != 0]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.giftcardsRest, (card) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, _toDisplayString(card.code), 1),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_price_currency, {
            "data-price": card.balance,
            "data-currency": $options.itemCurrency
          }, null, 8, ["data-price", "data-currency"])
        ])
      ], 512)), [
        [_vShow, $options.giftCartPart != 0]
      ])
    }), 256))
  ]))
}