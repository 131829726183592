import { createI18n } from "vue-i18n";
import en from "./translations/en.json";
import de from "./translations/de.json";
import dk from "./translations/dk.json";
import no from "./translations/no.json";
import se from "./translations/se.json";

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  allowComposition: true,
  messages: {
    en,
    de,
    dk,
    no,
    se,
  },
});
