import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-440f6332"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-modal__holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "vb-modal_overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.closeModal && $options.closeModal(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "vb-btn vb-btn--icon vb-icon vb-icon-reset vb-icon-error-notification",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.closeModal && $options.closeModal(...args))),
        type: "button"
      }),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}