import { RequestError, out_userSet, i_user } from "visbook-api";
import i18n from "@/i18n";
import { i_userStore, loginWay_type } from "@/store/store";
import { i_validateCode } from "@/core/declarations";
import {
  requestVB,
  noNulls,
  preparePhoneNumber,
  toRouterUrl,
} from "@/core/helpers";
import router from "@/router";
import { ActionContext } from "vuex";
import { app } from "@/main";

const namespaced = true;

const state: i_userStore = {
  userAvatar: null,
  userProfile: null,
  userPromise: null,
  paymentMethod: null,
  giftCards: [],
};

const getters = {
  fullName(state: i_userStore) {
    if (state.userProfile) {
      const fName = state.userProfile.firstName || "";
      const lName = state.userProfile.lastName || "";
      const email = state.userProfile.email || "";
      const fn = `${fName} ${lName}`;
      return fn.length > 1
        ? fn
        : email.length > 1
        ? state.userProfile.email.split("@")[0]
        : i18n.global.t("page-account_unregistered");
    } else return "";
  },
  avatar(state: i_userStore) {
    return state.userAvatar;
  },
  initials(state: i_userStore) {
    if (state.userProfile) {
      const fName = getInitials(state.userProfile.firstName);
      const lName = getInitials(state.userProfile.lastName);
      return `${fName} ${lName}`;
    } else return "";

    function getInitials(name: string | null): string {
      return name ? name.substring(0, 1).toUpperCase() : "";
    }
  },
  isAuthorized(state: i_userStore) {
    return !!state.userProfile;
  },
  isAuthenticated(state: i_userStore) {
    return state.userProfile;
  },
  isNewUser(state: i_userStore) {
    return !state.userProfile?.id;
  },
  paymentMethod(state: i_userStore) {
    return state.paymentMethod;
  },
  giftCards(state: i_userStore) {
    return state.giftCards;
  },
};

const mutations = {
  set_userAvatar(state: i_userStore, avatar: any) {
    state.userAvatar = avatar;
  },
  set_userProfile(state: i_userStore, profile: i_user | null) {
    state.userProfile = profile;
  },
  set_paymentMethod(state: i_userStore, method: string | null) {
    state.paymentMethod = method;
  },
  set_giftCard(state: i_userStore, cards: any[]) {
    state.giftCards = cards;
  },
};

const actions = {
  SET_userAvatar({ commit }: ActionContext<i_userStore, any>, avatar: any) {
    commit("set_userAvatar", avatar);
  },
  EVENT_logOut({
    dispatch,
    rootGetters,
  }: ActionContext<i_userStore, any>): Promise<any> {
    dispatch("CLEAR_userProfile");
    dispatch("mod_orders/CLEAR_selectedOrderCurrency", null, { root: true });
    dispatch("mod_globalView/SET_loginWay", loginWay_type.EMPTY, {
      root: true,
    });
    return requestVB(app.config.globalProperties.$visbook.SEND_logout)
      .then((data: any) => data)
      .catch((err: RequestError) => {
        throw err;
      })
      .finally(() => {
        if (rootGetters["mod_company/mustLogin"] || router.currentRoute.value.path.includes('my-page')) {
          toRouterUrl("login");
        }
      });
  },
  CLEAR_userProfile({ commit }: ActionContext<i_userStore, any>) {
    commit("set_userProfile", null);
  },
  GET_userProfile(
    { state, commit, dispatch }: ActionContext<i_userStore, any>,
    type?: string
  ): Promise<any> {
    if (null === state.userPromise) {
      state.userPromise = requestVB(
        app.config.globalProperties.$visbook.GET_user
      )
        .then((response: i_user) => {
          if (response.mobile && !response.mobile.startsWith("+")) {
            response.mobile = "+47 " + response.mobile;
          }
          commit("set_userProfile", response);
          if (type) {
            app.config.globalProperties.$gtag.event("login", {
              method: type,
            });
          } else {
            const rememberLoginType = localStorage.getItem("loginWay");
            if (rememberLoginType) {
              dispatch("mod_globalView/SET_loginWay", rememberLoginType, {
                root: true,
              });
            }
          }
        })
        .catch((err: RequestError) => {
          throw err;
        });
    }
    return state.userPromise;
  },
  UPDATE_userProfile(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { state }: ActionContext<i_userStore, any>,
    profile: i_user
  ): Promise<any> {
    const profileReq = noNulls(profile);

    if (profileReq) {
      profileReq.mobile = preparePhoneNumber(profileReq.mobile);
      profileReq.phone = preparePhoneNumber(profileReq.phone);
    }

    if (profileReq) {
      if(!profileReq.mobileToken){
        delete(profileReq.mobile);
      }
    }

    return requestVB(app.config.globalProperties.$visbook.SET_user, profileReq)
      .then((data: out_userSet) => data)
      .catch((err: RequestError) => {
        throw err;
      });
  },
  VALIDATE_code(
    { state, dispatch }: ActionContext<i_userStore, any>,
    { isEmail, code }: i_validateCode
  ) {
    const email: any = app.config.globalProperties.$visbook.GET_validationEmail;
    const sms: any = app.config.globalProperties.$visbook.GET_validationSMS;
    const type = email ? "email" : "sms";
    return requestVB(isEmail ? email : sms, code)
      .then(() => {
        state.userPromise = null;
        dispatch("GET_userProfile", type);
      })
      .catch((error: RequestError) => {
        throw error;
      });
  },
  SET_paymentMethod(
    { commit }: ActionContext<i_userStore, any>,
    method: string
  ) {
    commit("set_paymentMethod", method);
  },
  SET_giftCard({ commit }: ActionContext<i_userStore, any>, cards: any[]) {
    commit("set_giftCard", cards);
  },
  CLEAR_giftCards({ commit }: ActionContext<i_userStore, any>) {
    commit("set_giftCard", []);
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
