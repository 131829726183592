import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "vb-room-offer" }
const _hoisted_2 = { class: "vb-room-offer_head" }
const _hoisted_3 = {
  key: 0,
  class: "vb-room-head_img_box"
}
const _hoisted_4 = { class: "vb-room-offer_secondary" }
const _hoisted_5 = { class: "vb-room-offer_addit" }
const _hoisted_6 = { class: "vb-room-offer_total vb-room-offer_aside" }
const _hoisted_7 = { class: "vb-form_row vb-search-filter_section" }
const _hoisted_8 = { class: "vb-form_row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_slider = _resolveComponent("product-slider")!
  const _component_product_header = _resolveComponent("product-header")!
  const _component_product_select_price = _resolveComponent("product-select-price")!
  const _component_product_additional = _resolveComponent("product-additional")!
  const _component_product_select_people = _resolveComponent("product-select-people")!
  const _component_product_result_info = _resolveComponent("product-result-info")!
  const _component_product_cta = _resolveComponent("product-cta")!
  const _component_product_date_picker = _resolveComponent("product-date-picker")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.itemData.images.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_product_slider, {
              class: "vb-room-head_img",
              "image-list": _ctx.itemData.images
            }, null, 8, ["image-list"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_product_header, {
        class: "vb-room-head_info",
        "item-data": _ctx.itemData
      }, null, 8, ["item-data"]),
      _createVNode(_component_product_select_price, {
        modelValue: _ctx.priceCurrentSelected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.priceCurrentSelected) = $event)),
        "price-info": _ctx.itemData.prices,
        "select-people-value": _ctx.selectPeopleValue,
        "configuration-ok": _ctx.configurationOk
      }, null, 8, ["modelValue", "price-info", "select-people-value", "configuration-ok"])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.itemData.additionalServices.length)
          ? (_openBlock(), _createBlock(_component_product_additional, {
              key: 0,
              "model-value": _ctx.additionalServicesSelected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.additionalServicesSelected) = $event)),
              "add-list": _ctx.itemData.additionalServices,
              "all-nights": _ctx.searchFilter.nights,
              "all-persons": _ctx.allPersonsCurrentRoom
            }, null, 8, ["model-value", "add-list", "all-nights", "all-persons"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.configurationOk)
            ? (_openBlock(), _createBlock(_component_product_select_people, {
                key: 0,
                modelValue: _ctx.selectPeopleValue,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectPeopleValue) = $event)),
                "select-people-value": _ctx.selectPeopleValue,
                options: _ctx.selectPeopleOptions
              }, null, 8, ["modelValue", "select-people-value", "options"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isAvailability && _ctx.configurationOk && typeof(_ctx.selectPeopleValue) === 'number')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_product_result_info, {
                  "model-value": _ctx.totalPriceRoom,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.totalPriceRoom) = $event)),
                  "all-nights": _ctx.searchFilter.nights,
                  "additional-services": _ctx.additionalServicesSelected,
                  "price-selected": _ctx.priceCurrentSelected,
                  "filter-dates": _ctx.searchFilterDates,
                  "has-additionals": _ctx.itemData.additionalServices.length > 0
                }, null, 8, ["model-value", "all-nights", "additional-services", "price-selected", "filter-dates", "has-additionals"])
              ]),
              _createVNode(_component_product_cta, {
                "item-data": _ctx.itemData,
                "reservations-data": _ctx.reservationsData,
                "meta-data": _ctx.metaData,
                "price-selected": _ctx.priceCurrentSelected,
                "additional-selected": _ctx.additionalServicesSelected,
                "disabled-btn": _ctx.disableAddBtn
              }, null, 8, ["item-data", "reservations-data", "meta-data", "price-selected", "additional-selected", "disabled-btn"])
            ], 64))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_product_date_picker, {
          "product-id": _ctx.itemData.id,
          "all-persons": _ctx.searchFilter.allPersons,
          onUpdateAvailableDates: _ctx.setAvailableDates,
          onUpdateCheckinoutDates: _ctx.setCheckInOutDates
        }, null, 8, ["product-id", "all-persons", "onUpdateAvailableDates", "onUpdateCheckinoutDates"]), [
          [_vShow, _ctx.isSearchPerformed && (_ctx.configurationOk && !_ctx.isAvailability && _ctx.showProductCalendar || !!_ctx.searchFilterDates.checkIn)]
        ])
      ])
    ])
  ]))
}