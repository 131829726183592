import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e2dac45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-room-offer_total vb-room-offer_aside vb-activity-offer_aside" }
const _hoisted_2 = {
  key: 0,
  class: "vb-h5 vb-room-head_noprice"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_result_activity_select_box = _resolveComponent("search-result-activity-select-box")!
  const _component_product_additional = _resolveComponent("product-additional")!
  const _component_product_result_info = _resolveComponent("product-result-info")!
  const _component_product_cta = _resolveComponent("product-cta")!
  const _component_product_date_picker = _resolveComponent("product-date-picker")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["vb-room-offer_secondary", {'vb-room-offer_secondary__restaurant': _ctx.itemData.type === 'restaurant'}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["vb-room-offer_addit", {'vb-room-offer_addit__restaurant': _ctx.itemData.type === 'restaurant'}])
    }, [
      _createVNode(_component_search_result_activity_select_box, {
        ref: "activitySelectBox",
        "item-data": _ctx.itemData,
        onUpdateActivity: $options.updateItemData,
        onUpdatePricelistCount: $options.updateUpdatePricelistCount,
        "configuration-ok": _ctx.configurationOk,
        "select-people-options": _ctx.selectPeopleOptions,
        "select-people-value": _ctx.selectPeopleValue
      }, null, 8, ["item-data", "onUpdateActivity", "onUpdatePricelistCount", "configuration-ok", "select-people-options", "select-people-value"]),
      (_ctx.itemData.additionalServices.length)
        ? (_openBlock(), _createBlock(_component_product_additional, {
            key: 0,
            "model-value": _ctx.additionalServicesSelected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.additionalServicesSelected) = $event)),
            "add-list": _ctx.itemData.additionalServices,
            "all-nights": _ctx.searchFilter.nights,
            "all-persons": _ctx.allPersonsCurrentRoom,
            disabled: $options.productAdditionalDisabled
          }, null, 8, ["model-value", "add-list", "all-nights", "all-persons", "disabled"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.configurationOk)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('invalid-person-setup')), 1))
        : (_ctx.isAvailability)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_product_result_info, {
                "model-value": _ctx.totalPriceRoom,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.totalPriceRoom) = $event)),
                "additional-services": _ctx.additionalServicesSelected,
                "price-selected": _ctx.priceCurrentSelected,
                "select-people-value": _ctx.selectPeopleValue,
                "price-list-count": _ctx.priceListCount,
                "available-number": _ctx.availableNumber,
                "products-in-cart": $options.productsInCart,
                "filter-dates": _ctx.searchFilterDates,
                "has-additionals": _ctx.itemData.additionalServices.length > 0,
                "item-type": _ctx.itemData.type
              }, null, 8, ["model-value", "additional-services", "price-selected", "select-people-value", "price-list-count", "available-number", "products-in-cart", "filter-dates", "has-additionals", "item-type"]),
              _createVNode(_component_product_cta, {
                "item-data": _ctx.itemData,
                "reservations-data": _ctx.reservationsData,
                "meta-data": _ctx.metaData,
                "price-selected": _ctx.priceCurrentSelected,
                "additional-selected": _ctx.additionalServicesSelected,
                "disabled-btn": $options.disableAddBtn
              }, null, 8, ["item-data", "reservations-data", "meta-data", "price-selected", "additional-selected", "disabled-btn"])
            ], 64))
          : _createCommentVNode("", true),
      (_ctx.isSearchPerformed && (_ctx.configurationOk && !_ctx.isAvailability && _ctx.showProductCalendar || !!_ctx.searchFilterDates.checkIn))
        ? (_openBlock(), _createBlock(_component_product_date_picker, {
            key: 2,
            "product-id": _ctx.itemData.id,
            "all-persons": _ctx.allPersonsCurrentRoom,
            onUpdateAvailableDates: _ctx.setAvailableDates,
            onUpdateCheckinoutDates: _ctx.setCheckInOutDates
          }, null, 8, ["product-id", "all-persons", "onUpdateAvailableDates", "onUpdateCheckinoutDates"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}