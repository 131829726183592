<template>
<label class="vb-custom-input" :class="componentStyles">
  <slot name="label">
    <span v-if="labelText" class="vb-form_label">{{ labelText }}</span>
  </slot>
  <slot>
    <div class="vb-custom-input_input-box">
      <input
        ref="inputDom"
        :id="inputId"
        v-bind="$attrs"
        :value="modelValue === null ? '' : modelValue "
        :pattern="inputPattern"
        :readonly="isReadOnly"
        class="vb-input vb-custom-input_input"
        @input="updateData($event.target.value)"
        @keydown="handleKeyDown"
        @keyup.enter="submitInput"
      />

      <div v-if="showActions" class="vb-action-btn">
        <button
          type="button"
          v-show="String(modelValue).length"
          aria-label="reset input"
          class="vb-btn vb-btn--icon vb-icon vb-icon-reset vb-text-disable"
          @click="resetInput"
        ></button>
        <span v-if="doneSuccess" class="vb-btn vb-btn--icon vb-icon vb-icon-checked vb-text-primary"></span>
        <button
          v-else
          type="button"
          aria-label="submit"
          class="vb-btn vb-btn--icon vb-icon vb-icon-active-input"
          :class="inputIsValid ? 'vb-text-primary' : 'vb-text-disable'"
          :disabled="!inputIsValid"
          @click="submitInput"
        ></button>
      </div>

      <div v-if="showBtnNum" class="vb-action-btn vb-num-btn">
        <button
          type="button"
          class="vb-num-btn--inc vb-btn vb-btn--icon vb-icon vb-icon-chevron-top"
          :disabled="btnNumIncDisabled"
          @click="valueInc"
        ></button>
        <button
          type="button"
          class="vb-num-btn--dec vb-btn vb-btn--icon vb-icon vb-icon-chevron-down"
          :disabled="btnNumDecDisabled"
          @click="valueDec"
        ></button>
      </div>
    </div>
  </slot>
  <div
    v-if="textError.length && !$attrs.disabled"
    class="vb-custom-input_text vb-small-text vb-text-error vb-ellipsis"
  >{{ textError }}</div>
</label>
</template>

<script lang="ts">
import { emailPattern, phonePattern } from "@/core/helpers";

export default {
  name: "BaseInput",
  emits: ["update:modelValue", "input-validity", "send-form"],
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    labelText: {
      type: String,
      default: "",
    },
    inputId: {
      type: String,
    },
    textError: {
      type: String,
      default: "",
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    doneSuccess: {
      type: Boolean,
      default: false,
    },
    enableReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    inputValidity: false,
  }),
  computed: {
    btnNumIncDisabled() {
      return +this.$attrs.max === 0
        ? false
        : this.modelValue == this.$attrs.max;
    },
    btnNumDecDisabled() {
      return this.modelValue == this.$attrs.min || this.modelValue == "1";
    },
    showBtnNum() {
      return (
        this.isTypeNumber &&
        !this.$attrs.disabled &&
        !this.showActions &&
        this.$attrs.min != this.$attrs.max &&
        !(this.btnNumIncDisabled && this.btnNumDecDisabled)
      );
    },
    inputIsValid() {
      return (
        !!this.modelValue && !!this.modelValue.length && this.inputValidity
      );
    },
    inputPattern() {
      let customPattern = ".*";
      switch (this.$attrs.type) {
        case "email":
          customPattern = emailPattern;
          break;
        case "tel":
          customPattern = phonePattern
          break;
      }
      return this.$attrs.pattern || customPattern;
    },
    componentStyles() {
      return {
        "vb-custom-input--number": this.isTypeNumber,
        "vb-custom-input--show-actions": this.showActions,
        "vb-custom-input_active": this.modelValue && this.modelValue.length,
        "vb-text-disable": this.$attrs.disabled,
      };
    },
    isTypeNumber() {
      return this.$attrs.type === "number";
    },
    isReadOnly() {
      return (
        this.enableReadOnly &&
        !this.showActions &&
        // this.isTypeNumber &&
        !this.showBtnNum
      );
    },
  },
  methods: {
    updateData(data: string | number) {
      this.$emit("update:modelValue", data);
      const input = this.$refs.inputDom as HTMLInputElement;
      if (input) this.inputValidity = input.validity.valid;
      this.$nextTick(() => {
        this.$emit("input-validity", this.inputIsValid);
      });
    },
    resetInput() {
      this.updateData("");
    },
    handleKeyDown(event: any){
      if (event.key === 'e' && this.$refs.inputDom.type === 'number') event.preventDefault()
    },
    submitInput() {
      if (this.inputIsValid) this.$emit("send-form");
    },
    focus() {
      const input = this.$refs.inputDom as HTMLInputElement;
      input.focus();
    },
    valueInc() {
      const max = this.$attrs.max || 9999;
      let val = 1;
      if (this.modelValue) {
        val = +this.modelValue < +max ? +this.modelValue + 1 : +max;
      }
      this.updateData(val.toString());
    },
    valueDec() {
      const min = this.$attrs.min || 0;
      let val = 0;
      if (this.modelValue) {
        val = +this.modelValue > +min ? +this.modelValue - 1 : +min;
      }
      this.updateData(val.toString());
    },
  },
  watch: {
    "$attrs.max": {
      handler: function (newMax: number) {
        if (
          this.modelValue &&
          newMax &&
          newMax > 0 &&
          newMax < +this.modelValue
        ) {
          this.updateData(newMax);
        }
      },
      immediate: true,
    },
    "$attrs.min": {
      handler: function (newMin: number) {
        if (
          this.modelValue &&
          newMin &&
          newMin > 0 &&
          newMin > +this.modelValue
        ) {
          this.updateData(newMin);
        }
      },
      immediate: true,
    },
    modelValue(newValue: number) {
      if (this.$attrs.max && +newValue > +this.$attrs.max) {
        this.updateData(this.$attrs.max);
      }
      if (this.$attrs.min && +newValue < +this.$attrs.min) {
        this.updateData(this.$attrs.min);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";
.vb-custom-input {
  display: block;
  position: relative;
  //padding: 1.25em 0;
}
.vb-custom-input_input-box {
  position: relative;
}
.vb-custom-input_label {
  //display: block;
}
.vb-custom-input_input {
  flex: 1 1 100%;
  .vb-custom-input--number & {
    padding-right: 1.5em;
  }
  .vb-custom-input--show-actions & {
    padding-right: 4em;
  }
  &:disabled {
    color: $col-disabled;
  }
}
.vb-custom-input_actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: -0.5em;
}

.vb-custom-input_text {
  position: absolute;
  bottom: 2px;
  left: $form-gap;
  right: $form-gap;
  width: 100%;
  line-height: 1.1;
}
.vb-action-btn {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  .vb-btn {
    padding: 0.25em 10px;
  }
}
.vb-num-btn {
  display: flex;
  flex-direction: column;
}
</style>
