import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "roomtype-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$options.restaurantsState)
      ? (_openBlock(), _createBlock(_component_multiselect, {
          key: 0,
          modelValue: $options.roomTypeSelected,
          "onUpdate:modelValue": $options.updateRoomType,
          options: $options.roomTypeList,
          placeholder: _ctx.$t('word_all'),
          "track-by": "label",
          searchable: false,
          "hide-selected": false,
          mode: "multiple",
          showLabels: false,
          "close-on-select": false,
          class: "h-auto min-h-14 text-black"
        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"]))
      : _createCommentVNode("", true)
  ]))
}