<template>
<div class="vb-search-filter vb-aside-inner mobile:p-0">
  <div class="vb-form_row vb-search-filter_section">
    <div class="vb-form_holder">
      <hotel-date-picker v-if="allItems.length > 1" />
      <product-date-picker
        v-else-if="allItems.length === 1"
        :product-id="singleProductId"
        :all-persons="filterSettings.allPersons"
        :show-cta-availability="false"
        :preload="true"
        class="vb-custom-date-picker"
        @default-date-ready="defaultDateReady"
      />
    </div>
  </div>

  <div class="vb-form_row vb-search-filter_section">
    <div class="vb-form_holder">
      <button
        type="button"
        class="vb-btn vb-bg-primary vb-w-100"
        @click="searchWebProducts()"
        :disabled="disabled"
      >{{$t('word_search')}}</button>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import HotelDatePicker from "@/components/forms/hotel-date-picker/hotel-date-picker.vue";
import { i_checkinPeriod, i_select } from "@/core/declarations";
import { cloneDate, cloneDateOrNull } from "@/core/helpers";
import { i_filterSettings } from "@/store/store";

import ProductDatePicker from "@/components/product-cart/product-date-picker.vue";

import { PropType } from "vue";

interface i_filterParams {
  adults: string;
  child: string;
}
interface i_query {
  checkIn?: string;
  checkOut?: string;
  lang?: string;
  webProductId?: string;
}

interface Data {
  filterParams: i_filterParams;
}

export default {
  components: {
    HotelDatePicker,
    ProductDatePicker,
  },
  props: {
    allItems: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },
  data: () =>
    ({
      filterParams: {
        adults: "1",
        child: "0",
      },
    } as Data),
  beforeUnmount() {
    this.$store.dispatch("mod_search/CLEAR_searchResult");
  },
  computed: {
    restaurantsState() {
      return this.$store.getters["mod_search/GET_restaurantsState"];
    },
    filterPeopleCount: {
      get(): number {
        return this.$store.state.mod_search.filterPeopleCount;
      },
      set(val: number) {
        this.$store.dispatch("mod_search/SET_filterPeopleCount", val);
      },
    },
    filterPeopleOptions(): number {
      return this.$store.state.mod_search.filterPeopleOptions;
    },
    minNights() {
      if (this.$store.state.mod_company.companySettings) {
        return this.$store.state.mod_company.companySettings.timeSettings
          .minimumBookingLength;
      }
      return 0;
    },
    selectedRoomType(): i_select[] {
      return this.$store.state.mod_search.filterSettingsDynamic.roomType;
    },
    numberOfPersons() {
      return +this.filterParams.adults + +this.filterParams.child;
    },
    disabled(): boolean {
      if (this.$store.state.mod_search.filterSettings) {
        return null === this.$store.state.mod_search.filterSettings.checkInDate;
      }
      return true;
    },
    filterSettings(): i_filterSettings {
      return this.$store.state.mod_search.filterSettings;
    },
    singleProductId(): number {
      if (this.allItems.length === 1) {
        return this.allItems[0].id;
      }
      return -1;
    },
    // checkinPeriod: {
    //   get(): i_checkinPeriod {
    //     return this.$store.getters['mod_search/GET_searchDates']
    //   },
    //   set(value: i_checkinPeriod) {
    //     this.setDateSettings(value);
    //   },
    // },
  },
  methods: {
    setNumberOfPersons(value: string) {
      this.$store.dispatch("mod_search/SET_filterPeopleCount", value);
    },
    setDateSettings(value: i_checkinPeriod) {
      this.$store.dispatch("mod_search/SET_dateSettings", value);
    },
    searchWebProducts() {
      this.$store.dispatch("mod_search/SEARCH_products");
    },
    defaultDateReady() {
      this.searchWebProducts();
    },
  },
  watch: {
    minNights: {
      handler() {
        const query: i_query = this.$route.query;

        const checkIn = cloneDateOrNull(query.checkIn);
        let checkOut = cloneDateOrNull(query.checkOut);
        if (checkIn && !checkOut && this.minNights) {
          checkOut = cloneDate(checkIn);
          checkOut.setDate(checkOut.getDate() + this.minNights);
        }
        if (checkIn && checkOut) this.setDateSettings({ checkIn, checkOut });
        const numberOfPersons: any = this.$route.query.numberOfPersons;
        if (numberOfPersons) {
          if (!isNaN(numberOfPersons)) {
            this.setNumberOfPersons(numberOfPersons as string);
          }
        }
        this.searchWebProducts();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/base";
.vb-search-filter {
  .date-input {
    border: 1px solid #E0E0E0;
  }
}
.vb-custom-date-picker {
  display: block;
  height: $input-height;
  color: $col-txt;
  font-size: inherit;
  background: $bg-page;
}
</style>
