import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a7f4a63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-product-date-picker" }
const _hoisted_2 = {
  key: 0,
  class: "vb-form_holder"
}
const _hoisted_3 = { class: "vb-room-dates_error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_cta_availability = _resolveComponent("product-cta-availability")!
  const _component_base_picker = _resolveComponent("base-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.showErrorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('page-search_not-availability_text')), 1)
        ]))
      : _createCommentVNode("", true),
    ($options.settings && $props.showDatePicker)
      ? (_openBlock(), _createBlock(_component_base_picker, {
          key: 1,
          class: _normalizeClass(["onright vb-datepicker__wrapper-empty", {ontop: $props.showCtaAvailability}]),
          ref: "datePicker",
          disabled: $options.isDisable,
          "disabled-dates": _ctx.disabledDates,
          "starting-show-date": $options.searchDates.checkIn,
          "starting-date-value": _ctx.checkedDates.checkIn,
          "ending-date-value": _ctx.checkedDates.checkOut,
          "start-date": $options.startBooking,
          "end-date": $options.stopBooking,
          "min-nights": $options.minNights,
          "max-nights": $options.maxNights,
          showYear: true,
          format: _ctx.$_datePicker_dateFormat(),
          i18n: $options.i18nData,
          "first-day-of-week": _ctx.$_datePicker_firstDayOfWeek(),
          "current-date-style": _ctx.$_datePicker_dateStyle(),
          "show-overlay": $options.showRequestLoader,
          "single-day-selection": $options.singleDaySelection,
          "show-half-day": $options.showHalfDay,
          onCheckInChanged: _cache[0] || (_cache[0] = ($event: any) => ($options.updateInfo($event, true))),
          onCheckOutChanged: _cache[1] || (_cache[1] = ($event: any) => ($options.updateInfo($event, false))),
          onUpdateMonths: $options.updateMonths,
          onCalendarIdOpened: $options.resetAvailableDates
        }, _createSlots({ _: 2 }, [
          ($props.showCtaAvailability)
            ? {
                name: "opener",
                fn: _withCtx(({isOpen, setIsOpen}) => [
                  _createVNode(_component_product_cta_availability, {
                    "is-open": isOpen,
                    "set-is-open": setIsOpen
                  }, null, 8, ["is-open", "set-is-open"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["class", "disabled", "disabled-dates", "starting-show-date", "starting-date-value", "ending-date-value", "start-date", "end-date", "min-nights", "max-nights", "format", "i18n", "first-day-of-week", "current-date-style", "show-overlay", "single-day-selection", "show-half-day", "onUpdateMonths", "onCalendarIdOpened"]))
      : _createCommentVNode("", true)
  ]))
}