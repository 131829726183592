import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vb-search-filter vb-aside-inner mobile:p-0" }
const _hoisted_2 = { class: "vb-form_row vb-search-filter_section" }
const _hoisted_3 = { class: "vb-form_holder" }
const _hoisted_4 = { class: "vb-form_row vb-search-filter_section" }
const _hoisted_5 = { class: "vb-form_holder" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hotel_date_picker = _resolveComponent("hotel-date-picker")!
  const _component_product_date_picker = _resolveComponent("product-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($props.allItems.length > 1)
          ? (_openBlock(), _createBlock(_component_hotel_date_picker, { key: 0 }))
          : ($props.allItems.length === 1)
            ? (_openBlock(), _createBlock(_component_product_date_picker, {
                key: 1,
                "product-id": $options.singleProductId,
                "all-persons": $options.filterSettings.allPersons,
                "show-cta-availability": false,
                preload: true,
                class: "vb-custom-date-picker",
                onDefaultDateReady: $options.defaultDateReady
              }, null, 8, ["product-id", "all-persons", "onDefaultDateReady"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "vb-btn vb-bg-primary vb-w-100",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.searchWebProducts())),
          disabled: $options.disabled
        }, _toDisplayString(_ctx.$t('word_search')), 9, _hoisted_6)
      ])
    ])
  ]))
}