import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51d730b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vb-page_meta-info vb-content-wrap vb-row vb-row--ai-c vb-row--jc-sb"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_testmode = _resolveComponent("the-testmode")!
  const _component_the_breadcrumbs = _resolveComponent("the-breadcrumbs")!
  const _component_the_notification = _resolveComponent("the-notification")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vb-page", {'vb-page-testmode-aside':!$props.showBreadcrumbs}])
  }, [
    _createVNode(_component_the_header, { class: "vb-page_header" }),
    _createVNode(_component_the_testmode),
    ($props.showBreadcrumbs)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createVNode(_component_the_breadcrumbs)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_the_notification),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_component_the_footer)
  ], 2))
}