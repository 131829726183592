import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "vb-form_label" }
const _hoisted_2 = {
  key: 0,
  class: "vb-form_row"
}
const _hoisted_3 = ["placeholder", "value", "onBlur"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('page-search_w-adults')) + ": " + _toDisplayString($props.numberOfPeople), 1),
    ($props.type !== 'restaurant')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($options.useGuestsNames)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.numberOfPeople, (n) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "vb-form_holder vb-w-100",
                  key: n
                }, [
                  _createElementVNode("input", {
                    class: "vb-input",
                    type: "text",
                    ref_for: true,
                    ref: "input",
                    autocomplete: "name",
                    placeholder: _ctx.$t('page-account_person-name'),
                    value: _ctx.names[n - 1],
                    onBlur: ($event: any) => ($options.blur($event.target.value, n - 1))
                  }, null, 40, _hoisted_3)
                ]))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}