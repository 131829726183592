<template>
  <multiselect
    class="base-select"
    v-model="lazyValue"
    :disabled="disabled"
    :options="compatibility?processedOptions:options"
    :label="label"
    :value-prop="valueProp"
    :canClear="false"
  />
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  name: "BaseSelect",
  emits: ['update:modelValue'],
  components: {
    Multiselect
  },
  data() {
    return {
      hiddenOptions: false,
    };
  },
  mounted() {
    this.lazyValue = this.modelValue
  },
  props: {
    hideOne: {
      type: Boolean,
      default: false,
    },
    modelValue: {},
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    valueProp: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: 'label'
    },
    compatibility: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lazyValue: {
      get() {
        if (this.compatibility){
          let value
          if (Array.isArray(this.modelValue)) {
            value = this.modelValue.map(v => v[this.valueProp])
          } else {
            value = this.modelValue[this.valueProp]
          }
          return value
        } else {
          return this.modelValue
        }
      },
      set(v) {
        if (this.compatibility){
          let value
          if (Array.isArray(v)) {
            value = v.map(v => this.processedOptions.find(option => option[this.valueProp] === v))
          } else {
            value = this.processedOptions.find(option => option[this.valueProp] === v)
          }
          this.$emit('update:modelValue', value)
        } else {
          this.$emit('update:modelValue', v)
        }
      }
    },
    processedOptions() {
      return this.options.map(option => {
        if (typeof option !== 'object' || Array.isArray(option)) {
          return {
            [this.valueProp]: option,
            [this.label]: option
          }
        } else return option
      })
    }
  },
  watch: {
    modelValue: {
      handler(mv) {
        if (this.compatibility) {
          if (Array.isArray(mv)) {
            if (mv.length !== this.lazyValue.length || !mv.every(mv => this.lazyValue.includes(mv[this.valueProp]))) {
              this.lazyValue = mv.map(mv => mv[this.valueProp])
            }
          } else {
            if (mv[this.valueProp] !== this.lazyValue) this.lazyValue = mv[this.valueProp]
          }
        } else {
          this.lazyValue = mv
        }
      },
      immediate: true
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";
@import "@vueform/multiselect/themes/default.css";

.vb-base-select-readonly {
  pointer-events: none;

  .multiselect__select {
    opacity: 0.3;
  }
}

.vb-base-select-invalid {
  outline: none;
  box-shadow: inset 0 0 0 2px $col-error;
  border-color: $col-error;
}

.base-select :deep(.multiselect-dropdown::-webkit-scrollbar) {
  width: 0;
}

</style>
