import { ActionContext } from "vuex";

export interface i_storeCheckout {
  pauseCheckout: boolean;
}

const namespaced = true;

const state: i_storeCheckout = {
  pauseCheckout: false,
};

const getters = {};

const mutations = {
  set_pauseCheckout(state: i_storeCheckout, val: boolean) {
    state.pauseCheckout = val;
  },
};

const actions = {
  SET_pauseCheckout(
    { commit }: ActionContext<i_storeCheckout, any>,
    val: boolean
  ) {
    commit("set_pauseCheckout", val);
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
