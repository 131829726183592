<template>
<div class="vb-page_main vb-content-wrap">
  <aside v-if="showAside" class="vb-page-main_sidebar min-w-[400px] mobile:min-w-full">
    <slot name="aside" />
  </aside>
  <main class="vb-page-main_content">
    <slot name="main" />
  </main>
</div>
</template>

<script lang="ts">
export default {
  props: {
    showAside: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";
.vb-page_main {
  display: flex;
  flex-wrap: wrap;
}
.vb-page-main_sidebar {
  position: relative;
  z-index: 5;
  // min-width: 400px;
  width: 100%;
  margin-bottom: 1em;
}
.vb-page-main_content {
  width: 100%;
  display: flex;
  flex: 1;
  padding-bottom: 2rem;
  > * {
    width: 100%;
  }
}
.vb-rebuild-point {
  .vb-page_main {
    flex: 1 1 100%;
  }
  .vb-page-main_sidebar {
    flex: 0 0 25%;
    width: auto;
    max-width: 320px;
    margin-right: 1.5%;
  }
  .vb-page-main_content {
    width: auto;
  }
}
</style>
