import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f4ef5e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vb-custom-text"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($options.content)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "vb-custom-text_content",
          innerHTML: $options.content
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}