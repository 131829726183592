import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5685662b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-aside-inner" }
const _hoisted_2 = { class: "vb-form_label" }
const _hoisted_3 = { class: "vb-order-summary-container" }
const _hoisted_4 = { class: "vb-order-summary-row" }
const _hoisted_5 = { class: "vb-order-summary_name_cell" }
const _hoisted_6 = { class: "vb-order-summary_price_cell" }
const _hoisted_7 = {
  key: 0,
  class: "vb-order-summary-row vb-order-summary-row-additionals"
}
const _hoisted_8 = { class: "vb-order-summary_name_cell" }
const _hoisted_9 = { class: "vb-order-summary_price_cell" }
const _hoisted_10 = { class: "vb-order-summary-row vb-order-summary-total" }
const _hoisted_11 = { class: "vb-order-summary_name_cell" }
const _hoisted_12 = { class: "vb-order-summary_price_cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_currency = _resolveComponent("price-currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('order-summary_title')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.cartList, (cartItem) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: cartItem.cartId
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(cartItem.name), 1),
            _createElementVNode("div", _hoisted_6, [
              (cartItem.type !== 'restaurant')
                ? (_openBlock(), _createBlock(_component_price_currency, {
                    key: 0,
                    "data-price": cartItem.price,
                    "data-currency": $options.itemCurrency
                  }, null, 8, ["data-price", "data-currency"]))
                : _createCommentVNode("", true)
            ])
          ]),
          (cartItem.additionalPrice)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('page-search_w-additionals')), 1),
                _createElementVNode("div", _hoisted_9, [
                  (cartItem.type !== 'restaurant')
                    ? (_openBlock(), _createBlock(_component_price_currency, {
                        key: 0,
                        "data-price": cartItem.additionalPrice,
                        "data-currency": $options.itemCurrency
                      }, null, 8, ["data-price", "data-currency"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('word_total')), 1),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_price_currency, {
            "data-price": _ctx.cartTotalPrice,
            "data-currency": $options.itemCurrency
          }, null, 8, ["data-price", "data-currency"])
        ])
      ])
    ])
  ]))
}