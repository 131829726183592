import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d1870b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-page-account_holder" }
const _hoisted_2 = { class: "vb-form_row" }
const _hoisted_3 = {
  key: 0,
  class: "vb-form_row"
}
const _hoisted_4 = { class: "vb-form_holder vb-page-account_holder-input" }
const _hoisted_5 = { class: "vb-form_holder vb-page-account_holder-btn vb-w-100" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 1,
  class: "vb-form_row"
}
const _hoisted_8 = { class: "vb-form_holder vb-page-account_holder-input" }
const _hoisted_9 = { class: "vb-form_holder vb-page-account_holder-btn vb-w-100" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 2,
  class: "vb-form_row vb-form_row-code_confirm"
}
const _hoisted_12 = { class: "vb-text-center" }
const _hoisted_13 = { class: "vb-form_holder vb-page-account_holder-input vb-w-100" }
const _hoisted_14 = { class: "vb-form_holder vb-page-account_holder-btn vb-w-100" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 3,
  class: "vb-form_row vb-form_row-code_confirm"
}
const _hoisted_17 = { class: "vb-text-center" }
const _hoisted_18 = { class: "vb-form_holder vb-page-account_holder-input" }
const _hoisted_19 = { class: "vb-form_holder vb-page-account_holder-btn vb-w-100" }
const _hoisted_20 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_input_phone = _resolveComponent("base-input-phone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass(["vb-btn vb-btn--outline vb-btn-swither", {'vb-btn-swither-active': _ctx.mode === 'email'}]),
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mode='email'))
      }, _toDisplayString(_ctx.$t('word_email')), 3),
      _createElementVNode("button", {
        class: _normalizeClass(["vb-btn vb-btn--outline vb-btn-swither", {'vb-btn-swither-active': _ctx.mode === 'phone'}]),
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mode='phone'))
      }, _toDisplayString(_ctx.$t('page-login_checked-phone')), 3)
    ]),
    (_ctx.mode === 'email')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_base_input, {
              type: "email",
              ref: "email",
              autocomplete: "email",
              "model-value": _ctx.newEmailValue,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newEmailValue) = $event)),
                $options.resetEmailCode
              ],
              "model-valueModifiers": { trim: true },
              required: $props.required,
              placeholder: _ctx.$t('word_email'),
              onSendForm: $options.verifyEmail,
              showActions: true
            }, null, 8, ["model-value", "required", "placeholder", "onSendForm", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "vb-btn vb-bg-primary vb-w-100",
              type: "button",
              disabled: !_ctx.pattern.test(_ctx.newEmailValue),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.verifyEmail && $options.verifyEmail(...args)))
            }, _toDisplayString(_ctx.showCodeEmail ? _ctx.$t('page-login_btn-resend-code') : _ctx.$t('page-login_btn-send-code')), 9, _hoisted_6)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_base_input_phone, {
              class: "vb-input-section_input",
              ref: "phone",
              type: "tel",
              autocomplete: "tel",
              "model-value": _ctx.newPhoneValue,
              "onUpdate:modelValue": [
                _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newPhoneValue) = $event)),
                $options.resetPhoneCode
              ],
              "model-valueModifiers": { trim: true },
              "input-placeholder": _ctx.$t('word_mobile'),
              "input-options": {showDialCode: true},
              "disabled-fetching-country": $props.disabledFetchingCountry,
              onUpdateNumber: _cache[5] || (_cache[5] = ($event: any) => ($options.getPhoneNum($event))),
              onSendForm: $options.verifyMobile,
              showActions: true
            }, null, 8, ["model-value", "input-placeholder", "disabled-fetching-country", "onSendForm", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              class: "vb-btn vb-bg-primary vb-w-100",
              type: "button",
              disabled: _ctx.newPhoneValue.length < 5,
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.verifyMobile && $options.verifyMobile(...args)))
            }, _toDisplayString(_ctx.showCodePhone ? _ctx.$t('page-login_btn-resend-code') : _ctx.$t('page-login_btn-send-code')), 9, _hoisted_10)
          ])
        ])),
    (_ctx.mode === 'email' && _ctx.showCodeEmail && $options.showVerifyEmail)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('enter_validation_code')), 1),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_base_input, {
              class: _normalizeClass(["vb-form-account_confirm-code", { 'vb-form-account_confirm-code_spacing': _ctx.emailCode }]),
              ref: "emailCode",
              type: "text",
              inputmode: "numeric",
              pattern: _ctx.сodePatternRule,
              required: "",
              "model-value": _ctx.emailCode,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.emailCode) = $event)),
              "model-valueModifiers": { trim: true },
              placeholder: _ctx.$t('page-login_form-code-plh'),
              disabled: _ctx.disableConfirmEmail,
              onSendForm: $options.confirmEmail
            }, null, 8, ["pattern", "model-value", "placeholder", "class", "disabled", "onSendForm"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", {
              class: "vb-btn vb-bg-primary vb-w-100",
              type: "button",
              disabled: !_ctx.сodePattern.test(_ctx.emailCode) || _ctx.disableConfirmEmail,
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($options.confirmEmail && $options.confirmEmail(...args)))
            }, _toDisplayString(_ctx.$t('page-account_btn_confirm-field')), 9, _hoisted_15)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode === 'phone' && _ctx.showCodePhone && $options.showVerifyPhone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('enter_validation_code')), 1),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_base_input, {
              class: _normalizeClass(["vb-form-account_confirm-code", { 'vb-form-account_confirm-code_spacing': _ctx.phoneCode }]),
              ref: "phoneCode",
              type: "text",
              inputmode: "numeric",
              autocomplete: "one-time-code",
              pattern: _ctx.сodePatternRule,
              required: "",
              "model-value": _ctx.phoneCode,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.phoneCode) = $event)),
              "model-valueModifiers": { trim: true },
              placeholder: _ctx.$t('page-login_form-code-plh'),
              disabled: _ctx.disableConfirmPhone,
              onSendForm: $options.confirmMobile
            }, null, 8, ["pattern", "model-value", "placeholder", "class", "disabled", "onSendForm"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("button", {
              class: "vb-btn vb-bg-primary vb-w-100",
              type: "button",
              disabled: !_ctx.сodePattern.test(_ctx.phoneCode) || _ctx.disableConfirmPhone,
              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => ($options.confirmMobile && $options.confirmMobile(...args)))
            }, _toDisplayString(_ctx.$t('page-account_btn_confirm-field')), 9, _hoisted_20)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}