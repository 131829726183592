import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bee517e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-room-offer_total vb-room-offer_aside vb-small-text" }
const _hoisted_2 = { class: "vb-form_label" }
const _hoisted_3 = { class: "vb-form_holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePeriod = _resolveComponent("DatePeriod")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('word_date')) + ":", 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DatePeriod, {
        "data-from": $props.reservationsData?.fromDate,
        "data-to": $props.reservationsData?.toDate,
        "hide-end-time": $props.hideEndTime
      }, null, 8, ["data-from", "data-to", "hide-end-time"])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}