import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_template_error = _resolveComponent("template-error")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_template_model = _resolveComponent("template-model")!
  const _component_the_request_loader = _resolveComponent("the-request-loader")!
  const _component_cookie_consent = _resolveComponent("cookie-consent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vb-app mobile:overflow-x-hidden", $options.appClass]),
    ref: "rootApp"
  }, [
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_template_error, {
          key: 0,
          "page-title": _ctx.$t('page-error_title'),
          "retry-btn": true,
          onRetry: $options.getCompany
        }, null, 8, ["page-title", "onRetry"]))
      : _createCommentVNode("", true),
    (!_ctx.showError)
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 1,
          "from-route-name": _ctx.fromRouteName,
          "to-route-name": _ctx.toRouteName
        }, null, 8, ["from-route-name", "to-route-name"]))
      : _createCommentVNode("", true),
    ($options.showModal.length)
      ? (_openBlock(), _createBlock(_component_template_model, { key: 2 }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent($options.showModal)))
          ], undefined, true),
          _: 1
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_the_request_loader, null, null, 512), [
      [_vShow, $options.showRequestLoader]
    ]),
    _createVNode(_component_cookie_consent)
  ], 2))
}