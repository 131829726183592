import {
    out_webProductsAll,
    in_webProductsList,
    RequestError,
    out_webProductsList,
    productItem_stepsAv,
    i_productItem,
} from "visbook-api";
import router from "@/router";
import i18n from "@/i18n";
import {
    i_search,
    i_filterSettings,
    i_product,
    i_productItemSortable,
} from "@/store/store";
import {i_select, i_roomTypeItem, i_checkinPeriod} from "@/core/declarations";
import {
    convertToDateFormat,
    requestVB,
    toRouterUrl,
    checkedDateString,
    cloneDate,
    hotelNow,
} from "@/core/helpers";
import {ActionContext} from "vuex";
import {app} from "@/main";

interface out_webProductsListWithRestaurants extends out_webProductsList {
    restaurants: i_productItem[];
}

const namespaced = true;

const state: i_search = {
    allRooms: [],
    filterSettings: {
        checkIn: "",
        checkOut: "",
        checkInDate: null,
        checkOutDate: null,
        nights: 0,
        adults: "1",
        child: "0",
        allPersons: 1,
    },
    filterSettingsDynamic: {
        roomType: [],
        sortBy: "0",
    },
    roomTypeList: [],
    sortByList: [],
    lastUrl: "",
    sorted: false,

    restaurantsState: false,
    activitiesState: false,
    accommodationsState: false,

    filterPeopleCount: 2,
    filterPeopleOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

    id_to_refresh: 0,

    search_performed: false,
};

const getters = {
    GET_sortByList(/*state: i_search*/) {
        return [
            {
                label: i18n.global.t("page-search_sort-default") as string,
                value: "0",
            },
            {label: i18n.global.t("page-search_sort-A-Z") as string, value: "1"},
            {label: i18n.global.t("page-search_sort-Z-A") as string, value: "2"},
        ];
    },
    GET_filteredRooms(state: i_search): i_product[] {
        const sortedList = [...state.allRooms];
        if (state.filterSettingsDynamic.sortBy === '0') {
            sortedList.sort((a: i_product, b: i_product) => {
                if (a.availability.available === b.availability.available)
                    return (a.sortIndex || 0) > (b.sortIndex || 0) ? 1 : -1;
                return b.availability.available ? 1 : -1;
            });
        }
        if (state.filterSettingsDynamic.sortBy === '1') sortedList.sort((a: any, b: any) => a.name.localeCompare(b.name))
        if (state.filterSettingsDynamic.sortBy === '2') sortedList.sort((a: any, b: any) => a.name.localeCompare(b.name)).reverse()
        return filterType(sortedList);

        function filterType(list: i_product[]) {
            const selectedName = state.filterSettingsDynamic.roomType.map(
                (i) => i.label
            );
            return list.filter((room: i_product) =>
                selectedName.length
                    ? selectedName.includes(room.unitName || room.name)
                    : true
            );
        }

        function doSortList(list: i_product[]) {
            if (list.length) {
                if (state.sorted) {
                    list.sort((a: i_product, b: i_product) =>
                        (a.orderNumber ?? -1) > (b.orderNumber ?? -1) ? 1 : -1
                    );
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    switch (state.filterSettingsDynamic.sortBy?.value ?? "0") {
                        case "0":
                            list.sort((a: i_product, b: i_product) => {
                                if (a.availability.available === b.availability.available)
                                    return (a.sortIndex || 0) > (b.sortIndex || 0) ? 1 : -1;
                                return b.availability.available ? 1 : -1;
                            });
                            break;
                        case "1":
                            list.sort((a: i_product, b: i_product) =>
                                a.name > b.name ? 1 : -1
                            );
                            break;
                        case "2":
                            list.sort((a: i_product, b: i_product) =>
                                a.name > b.name ? -1 : 1
                            );
                            break;
                    }
                    state.sorted = true;
                    for (let i = 0; i < list.length; i++) {
                        list[i].orderNumber = i;
                    }
                }
            }
        }
    },
    GET_searchDates(state: i_search): i_checkinPeriod {
        return {
            checkIn: state.filterSettings.checkInDate,
            checkOut: state.filterSettings.checkOutDate,
        };
    },
    GET_activitiesState: (state: i_search) => state.activitiesState,
    GET_restaurantsState: (state: i_search) => state.restaurantsState,
    GET_accommodationsState: (state: i_search) => state.accommodationsState,
    GET_filterPeopleCount: (state: i_search) => state.filterPeopleCount,
    GET_idToRefresh(state: i_search): number {
        return state.id_to_refresh;
    },
    GET_isSearchPerformed: (state: i_search) => state.search_performed,
    GET_productType: (state: i_search) => (productId: number) => {
        const roomType = state.roomTypeList.find((e: any) => e.value === productId);
        return roomType ? roomType.type : "undefined";
    },
};

const mutations = {
    set_roomTypeList(state: i_search, list: i_roomTypeItem[]) {
        state.roomTypeList = list;
    },
    set_roomTypeSelected(state: i_search, list: i_select[]) {
        state.filterSettingsDynamic.roomType = list;
    },

    set_filterPeopleCount(state: i_search, val: number) {
        state.filterPeopleCount = val;
    },

    set_filterPeopleOptions(state: i_search, data: any) {
        const optionsArr: number[] = [];
        for (let i = data.min || 1; i <= data.max; i++) {
            optionsArr.push(i);
        }
        state.filterPeopleOptions = optionsArr;
    },
    set_sortBySelected(state: i_search, sortBy: i_select) {
        state.sorted = false;
        state.filterSettingsDynamic.sortBy = sortBy;
    },

    set_filterSettings(state: i_search, settings: i_filterSettings) {
        state.filterSettings = {
            ...state.filterSettings,
            ...settings,
        };
    },
    set_allRooms(state: i_search, list: any[]) {
        state.sorted = false;
        state.allRooms = list;
    },
    set_searchPerformed(state: i_search, value: boolean) {
        state.search_performed = value;
    },
    set_lastUrl(state: i_search, url: string) {
        state.lastUrl = url;
    },
    decrease_availableUnits(state: i_search, step: productItem_stepsAv) {
        step.availableUnits -= 1;
    },
    update_room(state: i_search, room: i_productItemSortable) {
        const index = state.allRooms.findIndex((el) => el.id === room.id);
        if (index >= 0) {
            const newList = [...state.allRooms];
            room.orderNumber = newList[index].orderNumber;
            newList[index] = room;
            state.allRooms = newList;
        }
    },
    set_restaurants_state(state: i_search, value: boolean) {
        state.restaurantsState = value;
    },
    set_accommodations_state(state: i_search, value: boolean) {
        state.accommodationsState = value;
    },
    set_activities_state(state: i_search, value: boolean) {
        state.activitiesState = value;
    },
    set_defaultAllRooms(state: i_search, list: any[]) {
        if (0 === state.allRooms.length) {
            state.sorted = false;
            state.allRooms = list;
        }
    },
    set_idToRefresh(state: i_search, id: number) {
        state.id_to_refresh = id;
    },
};

const actions = {
    async GET_roomTypeList({
                               commit,
                           }: ActionContext<i_search, any>): Promise<any> {
        return await requestVB(
            app.config.globalProperties.$visbook.GET_webProductsAll
        )
            .then((response: out_webProductsAll[]) => {
                const webProductIdList: i_roomTypeItem[] = [];
                const products: i_product[] = [];
                response.forEach((item: out_webProductsAll) => {
                    webProductIdList.push({
                        label: item.unitName || item.name,
                        value: item.webProductId,
                        sortIndex: item.sortIndex || 0,
                        type: item.type,
                    });
                    products.push({
                        id: item.webProductId,
                        type: item.type,
                        sortIndex: item.sortIndex,
                        encryptedCompanyId: item.encryptedCompanyId,
                        name: item.name,
                        defaultName: item.defaultName,
                        maxPeople: item.maxPeople,
                        minPeople: item.minPeople,
                        defaultPeople: item.defaultPeople,
                        description: item.description,
                        availability: {
                            available: false,
                            reason: "",
                            reasonText: "",
                            steps: [],
                        },
                        prices: [],
                        images: item.images,
                        additionalServices: item.additionalServices,
                        additionalMerchandises: item.additionalMerchandises,
                        productGroup: item.productGroup,
                        properties: item.properties,
                        orderNumber: null,
                        unitName: item.unitName,
                    });
                });

                const restaurants = !!webProductIdList.find(
                    (i) => i.type === "restaurant"
                );
                const activities = !!webProductIdList.find(
                    (i) => i.type === "activity"
                );
                const accommodations = !!webProductIdList.find(
                    (i) => i.type === "accommodation"
                );

                // const restaurants = false;
                // const activities = true;
                // const accommodations = false;

                const onlyRestaurants = restaurants && !activities && !accommodations;
                const onlyActivities = activities && !restaurants && !accommodations;
                const onlyAccommodations =
                    !activities && !restaurants && accommodations;

                if (onlyRestaurants) {
                    commit("set_restaurants_state", true);
                }
                if (onlyActivities) {
                    commit("set_activities_state", true);
                }
                if (onlyAccommodations) {
                    commit("set_accommodations_state", true);
                }

                commit("set_roomTypeList", webProductIdList);
                commit("set_defaultAllRooms", products);
                return webProductIdList;
            })
            .catch((error: RequestError) => {
                console.log(error);
            });
    },
    GET_webProductsList(
        {commit, rootGetters, dispatch}: ActionContext<i_search, any>,
        inData: in_webProductsList
    ) {
        requestVB(app.config.globalProperties.$visbook.GET_webProductsList, inData)
            .then((response: out_webProductsListWithRestaurants) => {
                let data = [...response.accommodations, ...response.activities];
                if (response.restaurants) {
                    data = [...data, ...response.restaurants];
                }
                commit("set_allRooms", data);
                commit("set_searchPerformed", true);
                app.config.globalProperties.$gtag.event(
                    "view_item_list",
                    dispatch('getSearchDetailsGTAG', data)
                );
            })
            .catch((error: RequestError) => {
                console.log("GET_webProductsList", error);
                //this.inputEmail.error = error.message;
            });
    },
    GET_webProductById(
        {commit}: ActionContext<i_search, any>,
        inData: in_webProductsList
    ) {
        requestVB(app.config.globalProperties.$visbook.GET_webProductById, inData)
            .then((response: i_productItemSortable) => {
                const data = response;
                commit("update_room", data);
            })
            .catch((error: RequestError) => {
                console.log(error);
            });
    },
    SET_filterSettings({commit}: ActionContext<i_search, any>, settings: any) {
        const filterSettings = {
            adults: settings.adults,
            child: settings.child,
            allPersons: +settings.adults + +settings.child,
        };
        commit("set_filterSettings", filterSettings);
    },
    SET_filterPeopleCount({commit}: ActionContext<i_search, any>, val: number) {
        commit("set_filterPeopleCount", val);
    },
    SET_dateSettings(
        {commit}: ActionContext<i_search, any>,
        settings: i_checkinPeriod
    ) {
        const {checkIn, checkOut} = checkedDateString(
            settings.checkIn,
            settings.checkOut
        );
        const filterSettings = {
            checkInDate: settings.checkIn,
            checkOutDate: settings.checkOut,
            checkIn,
            checkOut,
            nights: calcNights(settings.checkIn as Date, settings.checkOut as Date),
            numberOfPersons: router.currentRoute.value.query.numberOfPersons
                ? router.currentRoute.value.query.numberOfPersons
                : null,
        };
        commit("set_filterSettings", filterSettings);

        function calcNights(checkIn: Date, checkOut: Date): number {
            const outDate = checkOut || checkIn;
            return !checkIn
                ? 0
                : Math.ceil((outDate.getTime() - checkIn.getTime()) / 86400000); // days
        }
    },
    SET_roomTypeSelected(
        {commit, rootGetters, dispatch}: ActionContext<i_search, any>,
        list: i_select[]
    ) {
        app.config.globalProperties.$gtag.event(
            "select_item",
            dispatch('getSearchDetailsGTAG', list)
        );
        commit("set_roomTypeSelected", list);
    },
    SET_sortBySelected({commit}: ActionContext<i_search, any>, sort: i_select) {
        commit("set_sortBySelected", sort);
    },
    CLEAR_searchResult({commit}: ActionContext<i_search, any>) {
        commit("set_allRooms", []);
        commit("set_searchPerformed", false);
    },
    SET_lastUrl({commit}: ActionContext<i_search, any>, url: string) {
        commit("set_lastUrl", url);
    },
    BACK_toSearchPage({state}: ActionContext<i_search, any>) {
        state.lastUrl ? router.push(state.lastUrl) : toRouterUrl("search");
    },
    async SEARCH_products(
        {state, dispatch, rootState, commit}: ActionContext<i_search, any>,
        newDates: i_checkinPeriod
    ) {
        if (!state.roomTypeList || state.roomTypeList.length === 0) {
            await dispatch("GET_roomTypeList");
        }

        if (newDates) {
            dispatch("SET_dateSettings", newDates);
        }
        if (!dateRangeIsOK(state.filterSettings as i_filterSettings)) {
            state.filterSettings.checkInDate = null;
            state.filterSettings.checkOutDate = null;
            dispatch(
                "mod_globalView/SHOW_notification",
                {message: i18n.global.t("wrong-date-period"), type: "error"},
                {root: true}
            );
        }
        const {checkInDate, checkOutDate} =
            state.filterSettings as i_filterSettings;
        const from = convertToDateFormat(checkInDate as Date);
        const to = convertToDateFormat(checkOutDate as Date);
        if (!from || !to) {
            commit("set_searchPerformed", true);
            return;
        }

        dispatch("CLEAR_searchResult");
        await dispatch("GET_webProductsList", {
            from,
            to,
        });

        const query: { [key: string]: string } = {
            checkIn: from,
            checkOut: to,
        };
        const selectedRoomType: i_select[] = state.filterSettingsDynamic.roomType;
        if (selectedRoomType.length) {
            const webProductList = selectedRoomType.map((el) => el?.value);
            query.webProductId = JSON.stringify(webProductList);
        }

        const route = router.currentRoute.value;
        const {...currentQuery} = route.query;

        if (JSON.stringify(currentQuery) !== JSON.stringify(query)) {
            router
                .push({
                    path: route.path,
                    query: {
                        ...route.query,
                        ...query,
                    },
                })
                .catch((err) => console.error(err));
        }

        function dateRangeIsOK(settings: i_filterSettings): boolean {
            if (settings.checkInDate !== null && settings.checkOutDate !== null) {
                const now = hotelNow();
                const today = new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate()
                );
                // dates in past
                if (settings.checkInDate < today || settings.checkOutDate < today) {
                    return false;
                }
                // dateOut is less than dateIn
                if (settings.checkOut < settings.checkIn) {
                    return false;
                }

                const timeSettings = rootState.mod_company.companySettings.timeSettings;
                // parameters to check
                // "timeSettings": {
                //   "closingTime": "string",
                //   "openingDate": "string",
                //   "closingDate": "string",
                //   "maximumBookingLength": 0,
                //   "minimumBookingLength": 0
                // },
                if (timeSettings.openingDate) {
                    const openingDate = cloneDate(timeSettings.openingDate);
                    if (settings.checkInDate < openingDate) {
                        return false;
                    }
                }
                if (timeSettings.closingDate) {
                    const closingDate = cloneDate(timeSettings.closingDate);
                    if (closingDate < settings.checkOutDate) {
                        return false;
                    }
                }
                if (timeSettings.maximumBookingLength) {
                    if (settings.nights > timeSettings.maximumBookingLength) {
                        return false;
                    }
                }
                if (timeSettings.minimumBookingLength) {
                    if (settings.nights < timeSettings.minimumBookingLength) {
                        return false;
                    }
                }
                if (timeSettings.closingTime) {
                    // @todo: closingTime - it is required to have hotel timezone to process this param correctly
                }
            }
            return true;
        }
    },
    EDIT_availableUnits(
        {commit}: ActionContext<i_search, any>,
        newProduct: any
    ) {
        state.allRooms.forEach((room: i_productItemSortable) => {
            if (room.id == newProduct.id) {
                room.availability.steps.forEach((step) => {
                    if (newProduct.checkIn < step.to && newProduct.checkOut > step.from) {
                        commit("decrease_availableUnits", step);
                    }
                });
            }
        });
    },
    SET_idToRefresh({commit}: ActionContext<i_search, any>, id: number) {
        commit("set_idToRefresh", id);
    },
    RESET_idToRefresh({commit}: ActionContext<i_search, any>) {
        commit("set_idToRefresh", 0);
    },
    getSearchDetailsGTAG({rootGetters}: ActionContext<i_search, any>, rooms: any[]) {
        const companyInfo = rootGetters["mod_company/getCompanyInfo"];
        const entityId = (router?.currentRoute as any)?.params?.entity;
        const eventData: any = {
            item_list_id: entityId,
            items: [],
        };
        rooms.forEach((item: i_product) => {
            if (item) {
                const result = {
                    item_id: item.id,
                    item_name: item.name,
                    item_brand: companyInfo?.name ? companyInfo.name : null,
                    item_list_id: entityId ? entityId : null,
                    // 'price': item.bookingMeta.totalPriceRoom,
                    item_category: item.defaultPeople,
                    item_variant: item.type,
                };
                eventData.items.push(result);
            }
        });
        return eventData;
    }
};

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
