<template>
  <div :class="`inline-block relative ${widthFit && 'w-full'}`">
    <div class="flex first-child:rounded-l last-child:rounded-r">
      <div
          v-for="(tab) in tabs"
          :key="tab"
          :class="`group tab duration-75 after:animate-[expand_.2s_ease-in-out] cursor-pointer ${activeTab(tab)}`"
          :style="{ width: `${tabWidthType}`, boxSizing: 'border-box' }"
          @click="emits('update:modelValue', tab)"
      >
        <p class="relative text-center mx-auto font-medium text-gray-600 group-[.active]:text-white"  :style="{ zIndex: 2 }">
          {{ $t(tab) }}
        </p>
      </div>
    </div>
    <div
        v-if="activeIndex !== -1"
        :class="`absolute top-[-8px] h-[56px] bg-gray-900 duration-75 ${roundedActive}`"
        :style="{width: `${100/(tabs?.length)}%`, left: `${(100/(tabs?.length) * (activeIndex))}%` }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

interface Props {
  tabs?: string[]
  modelValue?: string | number
  tabWidth?: string | number
  widthFit?: boolean
};

const emits = defineEmits<{
  "update:modelValue": [modelValue: Props["modelValue"]]
}>();

const props = withDefaults(defineProps<Props>(), {
  tabs: () => [],
  tabWidth: 128,
  widthFit: false
});

const activeIndex = computed<number>(() => props.tabs.findIndex(tab => tab === props.modelValue));

const tabWidthType = computed<string>(() => props.widthFit ? "100%" : `${props.tabWidth}px`);

const roundedActive = computed<string>(() => activeIndex.value === 0 ? "rounded-l" : "rounded-r");

const activeTab = (tab: string): string => {
  let classString = "";
  if (tab === props.modelValue) {
    classString += "active ";
  }
  if (tab === props.tabs[0]) {
    classString += "after:rounded-l";
  } else {
    classString += "after:rounded-r";
  }
  return classString;
};
</script>

<style lang="scss">
.tab {
  position: relative;
  padding: 8px 0;
  box-sizing: border-box;

  &::after {
    width: 100%;
    height: 56px;
    background-color: #fff;
    position: absolute;
    top: -8px;
    content: '';
    border: 1px solid #D0D5DD;
  }
}
</style>
