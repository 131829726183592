import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40b53a15"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-price-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString($options.unitPrice), 1),
    ($options.bitPrice && $props.showDecimals)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("span", null, _toDisplayString($options.bitPoint), 1),
          _createElementVNode("span", null, _toDisplayString($options.bitPrice), 1)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}