<template>
  <div class="vb-room-offer_total vb-room-offer_aside vb-small-text">
    <div class="vb-form_label">{{ $t('word_date') }}:</div>
    <div class="vb-form_holder">
      <DatePeriod
        :data-from="reservationsData?.fromDate"
        :data-to="reservationsData?.toDate"
        :hide-end-time="hideEndTime"
      />
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import DatePeriod from "@/components/different/date-period.vue";

export default {
  name: "ItemProductAside",
  components: { DatePeriod },
  props: {
    hideEndTime: {
      type: Boolean,
      default: false,
    },
    reservationsData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/base";
.vb-room-offer_aside {
  padding-left: 0;
  padding-right: 10px;
}
.vb-room-offer_total .vb-form_label {
  padding-top: 0.2em;
}
.vb-room-offer_total .vb-form_holder {
  padding-top: 1.4em;
}
</style>
