import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a5526a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-the-breadcrumbs" }
const _hoisted_2 = { class: "vb-the-breadcrumbs_list" }
const _hoisted_3 = { class: "vb-the-breadcrumbs_item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_to_router_link = _resolveComponent("to-router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_to_router_link, {
          class: "vb-btn vb-btn--txt",
          to: "home"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('word_main')), 1)
          ], undefined, true),
          _: 1
        }),
        _createElementVNode("span", null, "/ " + _toDisplayString($options.currentPage), 1)
      ])
    ])
  ]))
}