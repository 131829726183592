const FilterDate = (date:string)=> {
  return new Date(date).toLocaleDateString(['nb-NO'], {day: '2-digit', month: '2-digit', year: 'numeric'})
};

const FilterTime = (date:string)=> {
  return new Date(date).toLocaleTimeString(['nb-NO'], {hour: '2-digit', minute: '2-digit'})
};

const FilterSum = (sum:string|number)=> {
  if(typeof sum == 'string') sum = parseFloat(sum);
  return parseFloat(sum.toFixed(2));
};

const FilterPrice = (sum:string|number)=> {
  const sumNum = typeof sum === 'number' ? sum : parseFloat(sum);
  if(sumNum === 0) return 0;
  const sumCell = Math.trunc(sumNum);
  return (sumNum - sumCell) === 0
    ? `${sumNum}.00`
    : sumNum.toFixed(2);
};

const NoSpaces = (text:string|number)=> {
  let string = '';
  if(typeof text === 'number') string = text.toString();
  else string = text;
  return string.split(' ').join('');
};

export default {
  date: FilterDate,
  price: FilterPrice,
  sum: FilterSum,
  time: FilterTime,
  noSpaces: NoSpaces
}
