import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d2f6208"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-page" }
const _hoisted_2 = { class: "vb-content-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "vb-page-error_code"
}
const _hoisted_4 = { class: "vb-h1 vb-page-error_title" }
const _hoisted_5 = {
  key: 1,
  class: "vb-p vb-page-error_txt"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.errorCode)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.errorCode), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_4, _toDisplayString($props.pageTitle), 1),
      ($props.pageText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.pageText), 1))
        : _createCommentVNode("", true),
      ($props.retryBtn)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "vb-btn vb-bg-primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('retry')))
          }, _toDisplayString(_ctx.$t('page-error_btn')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}