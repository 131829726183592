import { createStore } from "vuex";
import mod_cart from "./modules/mod_cart";
import mod_company from "./modules/mod_company";
import mod_globalView from "./modules/mod_globalView";
import mod_orders from "./modules/mod_orders";
import mod_pageCheckout from "./modules/mod_page-checkout";
import mod_search from "./modules/mod_search";
import mod_terms from "./modules/mod_terms";
import mod_user from "./modules/mod_user";


export default createStore({
  modules: {
    mod_cart,
    mod_company,
    mod_globalView,
    mod_orders,
    mod_pageCheckout,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mod_search,
    mod_terms,
    mod_user,
  },
});
