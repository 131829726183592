<template>
  <div class="flex flex-col w-full">
    <p class="mb-1 text-sm font-medium text-gray-600">{{ $t('word_email') }}</p>
    <div class="p-4 flex justify-between rounded border border-solid border-gray-200 bg-gray-50">
      <div class="flex items-start w-full">
        <span class="text-base font-normal overflow-hidden overflow-ellipsis max-w-91% update-field">
          {{ modelEmail }}
        </span>
      </div>
      <button @click.prevent="editEmail = true">
        <span class="text-base font-medium border-b-solid border-b-black border-b-[1.5px]">{{ $t('word_edit') }}</span>
      </button>
    </div>
  </div>

  <el-dialog
    v-model="editEmail"
    align-center
    :show-close="false"
    class="dialog-window min-w-[600px] max-w-[600px] sm:min-w-91% sm:max-w-91%"
  >
    <div class="p-2 mobile:p-0 tablet:p-0 text-haiti">
      <div class="flex justify-between items-start mb-8">
        <div>
          <h2 class="text-2xl font-semibold mb-2">{{ $t('page-account_update-email-title') }}</h2>
          <p class="text-sm font-normal">
            {{ $t('page-account_update-email-subtitle') }}
            <span class="whitespace-nowrap">{{ modelEmail }}</span>
          </p>
        </div>
        <img
          src="@/components/forms/assets/Icons/M-size/x.svg"
          @click="editEmail = false"
          class="cursor-pointer mobile:hidden"
        >
      </div>
      <div v-if="showCodeEmail">
        <div>
          <UInput
            :label="$t('page-account_update-email-enter-code-input-label')"
            :inputClass="'h-56px'"
            type="text"
            inputmode="numeric"
            pattern="\\d{6}"
            required
            autofocus
            maxlength="6"
            inputId="inputCodeEl"
            placeholder="——————"
            v-model:model-value="emailCode"
            :show-actions="true"
            class="w-full update-email-code-input"
          ></UInput>
          <div class="mt-2 text-xs text-gray-600 font-normal">
            <div v-if="emailCodeError" class="flex items-start mt-2">
              <img src="@/components/forms/assets/Icons/S-size/notification/error.svg">
              <span class="ml-1 mb-1 text-red-600">{{ emailCodeError }}</span>
            </div>
            <div v-if="resendTimer > 0 && !firstResend">
              <span>{{ $t('please-wait', { num: resendTimer }) }}</span>
            </div>
            <div v-else>
              <span>{{ $t('page-account_update-email-enter-code-send-to') }}&nbsp;</span>
              <span>{{ newValue }}.</span>
              <br>
              <span>{{ $t('page-account_update-email-resend-code-1') }}&nbsp;</span>
              <button @click.prevent="resendCode">
                <span class="text-xs font-semibold border-b-solid border-b-black border-b-[1px]">{{ $t('word_here') }}</span>
              </button>
              <span>&nbsp;{{ $t('word_or') }}&nbsp;</span>
              <button @click.prevent="showCodeEmail = false">
                <span class="text-xs font-semibold border-b-solid border-b-black border-b-[1px]">{{ $t('word_go-back') }}</span>
              </button>
              <span>&nbsp;{{ $t('page-account_update-email-resend-code-2') }}</span>
            </div>
          </div>
          <div class="mt-8">
            <UButton
              solid
              rounded=""
              :label="$t('page-account_update-email-update-number-button')"
              :preset="undefined"
              :border-width="1"
              :color="'gray-900'"
              :text-color="'white'"
              :font-weight="600"
              :padding="'px-6 py-4'"
              class="w-full bg-gray-900 hover:bg-oxfordBlue active:bg-haiti"
              @click.prevent="confirm"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <UInput
            :label="$t('page-account_update-email-input-title')"
            type="textarea"
            ref="email"
            autocomplete="email"
            v-model:model-value="newValue"
            :show-actions="true"
            :inputClass="'h-56px'"
            class="w-full update-email-input"
          ></UInput>
          <div v-if="newValueError" class="flex items-center mt-2">
            <img src="@/components/forms/assets/Icons/S-size/notification/error.svg">
            <span class="ml-1 text-red-600">{{ newValueError }}</span>
          </div>
          <p v-else class="mt-2 text-xs text-gray-600 font-normal">{{ $t('page-account_update-email-send-code-text') }}</p>
        </div>
        <div class="mt-8">
          <UButton
            solid
            rounded=""
            :label="$t('page-account_update-email-send-code-button')"
            :preset="undefined"
            :border-width="1"
            :color="'gray-900'"
            :text-color="'white'"
            :font-weight="600"
            :padding="'px-6 py-4'"
            class="w-full bg-gray-900 hover:bg-oxfordBlue active:bg-haiti"
            @click.prevent="verifyEmail"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { RequestError } from 'visbook-api'

import UButton from '@/components/forms/UButton.vue';
import UInput from '@/components/forms/UInput.vue';

import { requestVB, emailPattern } from "@/core/helpers";
import i18n from "@/i18n";

export default {
  components: {
    UButton,
    UInput,
  },
  emits: ['confirm-email'],
  props: {
    modelEmail: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showCodeEmail: false,
    newValue: '',
    newValueError: '',
    emailCode: '',
    emailCodeError: '',
    editEmail: false,
    resendTimer: 0,
    firstResend: true,
    interval: undefined,
  }),
  mounted() {
    this.newValue = this.modelEmail;
  },
  watch: {
    emailCodeError: {
      handler() {
        if(this.emailCodeError) {
          const codeInput = document.querySelector('.update-email-code-input');
          codeInput?.classList.add('update-email-input-error');
        }
      },
    },
    newValueError: {
      handler() {
        if(this.newValueError) {
          const input = document.querySelector('.update-email-input');
          input?.classList.add('update-email-input-error');
        }
      }
    },
    emailCode: {
      handler() {
        this.emailCodeError = '';
      },
    }
  },
  methods: {
    confirm() {
      if(this.emailCode.length < 6) {
        this.emailCodeError = i18n.global.t('page-login_send-code-phone-error-text');
        return;
      }
      this.$emit('confirm-email', {
        value: this.newValue,
        code: this.emailCode
      });
      this.showCodeEmail = false;
      this.editEmail = false;
    },
    verifyEmail() {
      if (!this.isValidEmail(this.newValue)) {
        this.newValueError = i18n.global.t('page-account_update-email-invalid-address');
        return;
      }
      if(this.newValue === this.modelEmail) {
        this.newValueError = i18n.global.t('page-account_update-email-same-numbers');
        return;
      }
      requestVB(this.$visbook.SEND_email, this.newValue)
          .then(() => {
            this.showCodeEmail = true;
            this.startResendTimer();
            this.newValueError = '';
          })
          .catch((error: RequestError) => {
            this.newValueError = error.data.error ? error.data.error : error.message;
            if(error.status) this.showError(error.status.toString());
            if(error.status === 401) this.showError(this.$t('page-login_form-code-error') as string);
            else if(error.status === 404) this.showError(this.$t('page-login_message_unreg') as string);
            else error.data.error ? this.showError(error.data.error) : this.showError(error.message);
          })
    },
    resendCode() {
      if (this.firstResend) {
        this.firstResend = false;
        return;
      }
      if(this.resendTimer === 0) {
        this.verifyEmail();
      } else {
        this.startResendTimer();
      }
    },
    startResendTimer() {
      this.resendTimer = 30;
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if(this.resendTimer > 0) {
          this.resendTimer--;
        }
      }, 1000);
    },
    showError(message: string) {
      this.$store.dispatch('mod_globalView/SHOW_notification', {
        message,
        type: 'error'
      })
    },
    isValidEmail(email: string) {
      const pattern = new RegExp(emailPattern);
      return pattern.test(email);
    },
  }
}
</script>

<style lang="scss">
//@import "../../scss/base";

.update-email-code-input {
  .el-input__wrapper {
    outline: 1px solid black;
  }
  input::placeholder {
    color: black;
    font-size: 0.6rem;
    font-weight: 600;
  }
  input {
    color: black;
    letter-spacing: 2rem;
    font-size: 1.2rem;
    padding-left: 5px;
  }
}
.update-email-input-error {
  p {
    color: #B3261E;
  }
  .el-input__wrapper {
    background-color: #FCEEEE;
    outline: 1px solid #B3261E;
  }
  input::placeholder {
    color: #B3261E;
  }
}
.update-field {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-word;
  -webkit-box-orient: vertical;
}
</style>
