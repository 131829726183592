<template>
  <div class="vb-the-footer bg-white border-t border-t-gray-300 p-10 mobile:px-4">
    <div class="flex-col">
      <the-logo></the-logo>
      <span class="flex max-w-sm text-left mt-4 text-gray-600 text-base">{{ $t('footer-text') }}</span>
    </div>
    <footer class="vb-footer-main mt-6">
      <div v-if="companyInfo" class="text-left mobile:flex mobile:flex-wrap mobile:gap-4">
        <span class="mr-4 underline">
          <a
            :href="visbookUrl + 'privacy'"
            target="_blank"
            title="visbook.com/privacy ⭧"
          >Privacy</a>
        </span>
        <span class="mr-4">
          <a
            v-if="companyWebsite"
            :href="companyWebsite.url"
            :title="companyWebsite.text"
            class="vb-btn vb-btn--txt"
          >{{ companyInfo.name }}</a>
          <span v-else>{{ companyInfo.name }}</span>
        </span>

        <span v-if="companyInfo.taxNo" class="mr-4">
          {{ companyInfo.taxNo }}
        </span>

        <span v-if="companyPhone" class="mr-4">
          <a :href="companyPhone.link" class="vb-btn vb-btn--txt whitespace-nowrap">{{ companyPhone.number }}</a>
        </span>

        <span v-if="companyInfo.email" class="mr-4">
          <a
            v-if="isValidEmail"
            :href="`mailto:${companyInfo.email}`"
            class="vb-btn vb-btn--link"
          >{{ companyInfo.email }}</a>
          <span v-else>{{ companyInfo.email }}</span>
        </span>

        <span v-if="companyInfo.website">
          <a
            v-if="companyWebsite"
            :href="companyWebsite.url"
            target="_blank"
            class="vb-btn vb-btn--link"
          >{{ companyWebsite.text }}</a>
          <span v-else class="vb-btn vb-btn--txt">{{ companyInfo.website }}</span>
        </span>
      </div>
    </footer>

  </div>
  <div class="flex justify-between bg-gray-100 p-10 mobile:flex mobile:flex-col">
    <a
      :href="visbookUrl"
      target="_blank"
      title="visbook.com ⭧"
      class="mobile:m-auto mobile:order-2"
    >Copyright {{currentYear}} &copy; VisBook</a>
    <div class="flex mobile:order-1 mobile:m-auto mobile:mb-4">
      <a class="mr-10" href=""><img src="./forms/assets/Icons/social/facebook.svg" alt=""></a>
      <a class="mr-10" href=""><img src="./forms/assets/Icons/social/instagram.svg" alt=""></a>
      <a class="mr-10" href=""><img src="./forms/assets/Icons/social/linkedin.svg" alt=""></a>
      <a href=""><img src="./forms/assets/Icons/social/twitter.svg" alt=""></a>
    </div>
  </div>
</template>

<script lang="ts">
import TheLogo from "@/components/the-logo.vue";
import {
  emailPattern,
  checkUrl,
  cloneDate,
  filterNoSpaces,
} from "@/core/helpers";

export default {
  components: {
    TheLogo
  },
  computed: {
    visbookUrl() {
      let url = "https://visbook.com/";
      const lang = this.$store.state.mod_globalView.siteLanguage;
      switch (lang) {
        case "no":
          url += "no/";
          break;
        case "se":
          url += "sv/";
          break;
      }
      return url;
    },
    currentYear() {
      return cloneDate().getFullYear();
    },
    companyInfo() {
      return this.$store.state.mod_company.companyInfo;
    },
    companyWebsite() {
      return checkUrl(this.companyInfo?.website);
    },
    companyPhone() {
      if (this.companyInfo) {
        const phone = this.companyInfo.phone;
        const code = this.companyInfo.phonePrefix;
        let fullNumber = "";
        if (checkCodeInNumber(code, phone)) fullNumber = phone;
        else fullNumber = `+${code} ${phone}`;
        return {
          number: fullNumber,
          link: filterNoSpaces(`tel:${fullNumber}`),
        };
      } else return null;
      function checkCodeInNumber(code: string, number: string): boolean {
        return (
          code === number.slice(0, code.length) ||
          number.slice(0, 2) === "00" ||
          number[0] === "+"
        );
      }
    },
    isValidEmail() {
      if (this.companyInfo?.email) {
        const pattern = new RegExp(emailPattern);
        return pattern.test(this.companyInfo.email);
      } else return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base";
.vb-the-footer {
  text-align: center;
  min-height: var(--vb-footer-height);
}
.vb-footer-copyright > small {
  padding: 0 1rem;
}
.vb-footer-main {
  .vb-row {
    flex-wrap: wrap;
  }
}
</style>
