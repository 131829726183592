import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5485f552"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-people-selector" }
const _hoisted_2 = {
  key: 0,
  class: "vb-form_holder vb-select-price-message"
}
const _hoisted_3 = { class: "vb-h5 vb-room-head_noprice" }
const _hoisted_4 = { class: "vb-form_holder" }
const _hoisted_5 = { class: "vb-custom-input" }
const _hoisted_6 = {
  key: 0,
  class: "vb-form_label"
}
const _hoisted_7 = {
  key: 1,
  class: "vb-custom-input_input-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_select = _resolveComponent("base-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (undefined !== this.selectPeopleValue && (typeof(this.selectPeopleValue) !== 'number'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('please-select-message')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, [
        ($props.showLabel)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('page-search_w-adults')) + _toDisplayString($options.onlyNumber), 1))
          : _createCommentVNode("", true),
        (!$options.onlyOne)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_base_select, {
                "model-value": $props.modelValue,
                "onUpdate:modelValue": $options.updateData,
                options: $props.options,
                "custom-label": $options.customLabel,
                disabled: $props.disabled || $props.options.length === 0,
                searchable: false,
                allowEmpty: false,
                multiple: $props.multiple,
                showLabels: false,
                "hide-one": "",
                "can-deselect": false
              }, null, 8, ["model-value", "onUpdate:modelValue", "options", "custom-label", "disabled", "multiple"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}