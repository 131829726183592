<template>
<div class="vb-room-head_img">
  <swiper
    :navigation="true"
    :modules="modules">
    <swiper-slide
      v-for="(img, index) in slideList"
      :key="img.src"
      class="h-[180px] md:h-auto"
    >
      <img
        @click="showLightbox(index)"
        :src="img.src"
        alt="room photo" />
    </swiper-slide>
  </swiper>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="lightBoxList"
    :index="slideIndex"
    @hide="visibleRef = false"
  />
</div>
</template>

<script lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import VueEasyLightbox from 'vue-easy-lightbox'
import "swiper/scss";
import "swiper/scss/navigation";

interface Data {
  visibleRef: boolean;
  slideIndex: number | undefined;
}

export default {
  components: {
    Swiper,
    SwiperSlide,
    VueEasyLightbox,
  },
  props: ["imageList"],
  data: () =>
    ({
      visibleRef: false,
      slideIndex: undefined,
      baseUrl: "https://res.cloudinary.com/visbook/image/upload",
      DPR: window.devicePixelRatio,
      modules: [Navigation],
    } as Data),
  computed: {
    imgSize() {
      return `w_${200 * this.DPR},h_${180 * this.DPR}`;
    },
    slideList() {
      const resize = `/c_lfill,g_auto,e_vibrance,f_auto,q_auto,${this.imgSize}`;
      return this.imageList.map((img: any) => ({
        src: `${this.baseUrl}${resize}/visdoc/${img.imagePath}`,
      }));
    },
    lightBoxList() {
      return this.imageList.map((img: any) => ({
        src: `${this.baseUrl}/c_lfill,g_auto,e_vibrance,f_auto,q_auto,h_1200,w_2048/visdoc/${img.imagePath}`,
      }));
    },
  },
  methods: {
    showLightbox(index: number) {
      this.slideIndex = index;
      this.visibleRef = true;
    }
  },
};
</script>
