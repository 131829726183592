import {AxiosResponse} from "axios";
import fecha from "fecha";
import {RequestError, out_order} from "visbook-api";
import store from "@/store";
import router from "@/router";
import {i_cartItem, i_timeZoneInfo} from "@/store/store";
import {i_dateInfo, i_checkinApi} from "@/core/declarations";
import sanitizeHtml from "sanitize-html";
import {ExternalReferenceParam} from "@/config";
import {ExternalPreviewNetsPaymentOptionParam} from "@/config";
import {CookieConsentStatistics} from "@/config";
import i18n from "@/i18n";
import {app} from "@/main";

export interface anyObject {
    [key: string]: any;
}

export interface i_itemOrder extends out_order {
    orderGroupId: string;
    cancellationRules: {
        hasInsurance: boolean;
        fee: number;
        canCancelUntil: string;
        hasInvoices: boolean;
    };
    toBePaid?: number,
}

export const debounce = (func: (...a: any[]) => void, ms?: number) => {
    let timeout: number;
    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, ms);
    };
};

const throttleEv = function (func: () => void, ms?: number) {
    let isThrottled = false;
    let savedArgs: any;
    const delay = ms || 33; //30Hz

    function wrapper(...args: any[]) {
        if (isThrottled) {
            savedArgs = args;
            return;
        }

        func.apply(this, [...args] as []);
        isThrottled = true;
        setTimeout(() => {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(this, savedArgs);
                savedArgs = null;
            }
        }, delay);
    }

    return wrapper;
};

const deepCopy = function (object: object) {
    return JSON.parse(JSON.stringify(object));
};

const requestVB = function (
    func: (...funcArg: any) => Promise<AxiosResponse>,
    ...funcArg: any
): Promise<any> {
    store.dispatch("mod_globalView/INC_requestLoader");
    const fb = func.bind(app.config.globalProperties.$visbook);
    return fb(...funcArg)
        .then((data: any) => data)
        .catch((err: RequestError) => {
            const r = router as any;
            const isUserLogin = store.getters["mod_user/isAuthorized"];
            const isUnauthorizedRes = err.status === 401;
            const isNotLoginPage = r.currentRoute.value.name !== "login";

            if (isUserLogin && isUnauthorizedRes && isNotLoginPage)
                store.dispatch("mod_user/EVENT_logOut");
            throw err;
        })
        .finally(() => {
            store.dispatch("mod_globalView/DEC_requestLoader");
        });
};

const toRouterUrl = function (name: string, except: string[] = []) {
    const path = router.currentRoute.value;
    except.forEach((queryParam) => {
        delete path.query[queryParam];
    });
    delete path.query["errorCode"];
    router.push({
        name: name,
        params: path.params,
        query: path.query,
    });
};

const getDateInfo = function (date: Date | string | null): i_dateInfo | null {
    if (!date) return null;
    let y,
        m,
        d = "";
    if (typeof date === "string") {
        y = date.substring(0, 4);
        m = date.substring(5, 7);
        d = date.substring(8, 10);
    } else {
        y = `${date.getFullYear()}`;
        m = twoDigits(date.getMonth() + 1);
        d = twoDigits(date.getDate());
    }
    return {y, m, d};
};

const convertToDateFormat = function (date: Date | string | null): string {
    const dateInfo = getDateInfo(date);
    return dateInfo ? `${dateInfo.y}-${dateInfo.m}-${dateInfo.d}` : "";
};

const hotelNow = function (): Date {
    const timeZone: i_timeZoneInfo = store.getters["mod_company/timeZone"];
    if (timeZone) {
        return new Date(
            new Date().toLocaleString("en-US", {timeZone: timeZone.iana})
        );
    }
    return new Date();
};

const cloneDate = function (date?: Date | string): Date {
    const d = cloneDateOrNull(date);
    return null === d ? new Date() : d;
};

const cloneDateOrNull = function (date?: Date | string | number): Date | null {
    if (date instanceof Date && !isNaN(date.getTime())) {
        return new Date(date);
    }
    if (Number.isInteger(date)) {
        return new Date(date as number);
    }
    if (typeof date === "string") {
        // YYYY-MM-DDTHH:mm:ss+offset
        const datePart = getDatePart(date);
        const timePart = getTimePart(date);
        const [year, month, day] = datePart.split("-");
        if (year && month) {
            const [hours, minutes] = timePart.split(":");
            return new Date(
                parseInt(year),
                parseInt(month) - 1,
                parseInt(day) || 1,
                parseInt(hours) || 0,
                parseInt(minutes) || 0
            );
        }
    }
    return null;

    function getDatePart(date: string): string {
        const index = date.indexOf("T");
        return -1 !== index ? date.substring(0, index) : date;
    }

    function getTimePart(date: string): string {
        const index1 = date.indexOf("T");
        if (-1 !== index1) {
            const time = date.substring(index1 + 1);
            const index2 = time.indexOf("+");
            return -1 !== index2 ? time.substring(0, index2) : time;
        }
        return "";
    }
};

function formatDateRule(date: Date | null, format: string) {
    if (date) {
        return fecha.format(date, format);
    }
    return "";
}

const twoDigits = function (number: number): string {
    const numString = number.toString();
    return numString.length > 1 ? numString : `0${numString}`;
};

const noNulls = function (model: object | null) {
    if (!model) return;
    const newModel: anyObject = {...model};
    for (const prop in newModel) {
        if (newModel[prop] === null) newModel[prop] = "";
    }
    return newModel;
};

const toNulls = function (model: object | null) {
    if (!model) return;
    const newModel: anyObject = {...model};
    for (const prop in newModel) {
        if (newModel[prop] === "") newModel[prop] = null;
    }
    return newModel;
};
const preparePhoneNumber = function (num: string | undefined): string {
    if (num) {
        const numberArr = num.split("+");
        const number = numberArr[numberArr.length - 1];
        return "+" + number.replace(/[^0-9 ]/g, "");
    } else return "";
};

function getEntityId() {
    return app.config.globalProperties.$entity;
}

function getLocalCart() {
    const id = getEntityId();
    const cart = sessionStorage.getItem(`cart${id}`);
    return cart ? JSON.parse(cart) : null;
}

function setLocalCart(cartList: i_cartItem[]) {
    const id = getEntityId();
    sessionStorage.setItem(`cart${id}`, JSON.stringify(cartList));
}

const emailPattern = "^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$";
const phonePattern = "^(\\+?\\d{1,3}[-. )]?)?(\\d{3}[-. ]?){2}\\d{4}$";

function checkUrl(url: string) {
    if (!url) return returnError();

    const parts = url.split(/ +/);
    for (let i = 0; i < parts.length; i++) {
        const ret = getAnswer(parts[i]);
        if (ret) {
            return ret;
        }
    }
    return returnError();

    function getAnswer(url: string) {
        try {
            const urlObject = new URL(url);
            return getUrl(urlObject);
        } catch (e) {
            try {
                const urlObject = new URL("https://" + url);
                return getUrl(urlObject);
            } catch (e) {
                return returnError();
            }
        }
    }

    function getUrl(urlObject: URL) {
        if (!urlObject.host) {
            return returnError();
        }
        const parseUrl = urlObject.host.split(".");
        if (parseUrl[0] === "www") {
            parseUrl.shift();
        }
        if (parseUrl.length < 2) {
            return returnError();
        }
        return {
            text: parseUrl.join(".") + urlObject.pathname,
            url: urlObject.origin.replace(/^http:/, "https:") + urlObject.pathname,
        };
    }

    function returnError() {
        return null;
    }
}

function nextDay(date: Date) {
    const d = cloneDate(date);
    d.setDate(d.getDate() + 1);
    return d;
}

function nextMonth(date: Date) {
    const d = cloneDate(date);
    d.setMonth(d.getMonth() + 1);
    return d;
}

function setDay(date: Date, nextD = 0) {
    const d = cloneDate(date);
    d.setDate(d.getDate() + nextD);
    return d;
}

function getTime(string: string): number {
    if (!string) return 0;
    const [h, m /*, s*/] = string.split(":");
    return +h + +m / 60;
}

function checkedDateString(
    checkInDate: Date | null,
    checkOutDate: Date | null
): i_checkinApi {
    let checkIn = "";
    let checkOut = "";
    if (checkInDate && checkOutDate) {
        checkIn = convertToDateFormat(checkInDate);
        checkOut = convertToDateFormat(checkOutDate);
    }

    return {checkIn, checkOut};
}

function getDuration(date1: Date | string, date2: Date | string): number {
    const one_day = 1000 * 60 * 60 * 24;

    if (typeof date1 === "string") {
        date1 = cloneDate(date1);
    }
    if (typeof date2 === "string") {
        date2 = cloneDate(date2);
    }

    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();
    if (isNaN(date1_ms) || isNaN(date2_ms)) {
        return 1;
    }

    return Math.floor(Math.abs(date2_ms - date1_ms) / one_day) + 1;
}

function safeHtml(html: string) {
    return sanitizeHtml(html, {
        allowedTags: [
            "a",
            "b",
            "br",
            "strong",
            "i",
            "em",
            "mark",
            "small",
            "del",
            "ins",
            "sub",
            "sup",
            "h2",
            "h3",
            "h4",
            "h5",
            "blockquote",
            "span",
            "ul",
            "ol",
            "li",
            "p",
        ],
    });
}

function getExternalVBReferenceValue() {
    return sessionStorage.getItem(ExternalReferenceParam);
}

function setExternalVBReferenceValue(value: string) {
    sessionStorage.setItem(ExternalReferenceParam, value);
}

function getExternalPreviewNetsPaymentOptionValue() {
    return sessionStorage.getItem(ExternalPreviewNetsPaymentOptionParam);
}

function setExternalPreviewNetsPaymentOptionValue(value: string) {
    sessionStorage.setItem(ExternalPreviewNetsPaymentOptionParam, value);
}

let ip2cPromise: Promise<string> | null = null;

function getDefaultLanguageCountry(): Promise<string> {
    if (ip2cPromise === null) {
        ip2cPromise = Promise.resolve("en").then((language) => {
            sessionStorage.setItem("defaultLanguage", language);
            sessionStorage.setItem("defaultCountry", "NO");
            return language;
        });
        // ip2cPromise = fetch('https://ip2c.org/s')
        //   .then((response) => response.text())
        //   .then((response) => {
        //     const result = (response || '').toString();
        //     let language = defaultLanguage;
        //     switch(result.substring(result.lastIndexOf(';') + 1)) {
        //       case 'Norway':
        //         language = 'no';
        //         break;
        //       case 'Germany':
        //         language = 'de';
        //         break;
        //       case 'Sweden':
        //         language = 'se';
        //         break;
        //       case 'Denmark':
        //         language = 'dk';
        //         break;
        //     }
        //     sessionStorage.setItem('defaultLanguage', language);
        //     sessionStorage.setItem('defaultCountry',  result.substr(2, 2));
        //     return sessionStorage.getItem('defaultLanguage') || defaultLanguage;
        //   })
        //   .catch((response) => {
        //     return defaultLanguage;
        //   });
    }
    return ip2cPromise;
}

async function getCookieConsentKey(): Promise<string> {
    const name = "vb-cookie-consent-";
    try {
        await Promise.resolve(store.dispatch("mod_company/GET_setupInfo"));
        return name + store.getters["mod_company/companyGACode"];
    } catch (e) {
        return name;
    }
}

async function isCookieConsentStatisticsAllowed(): Promise<boolean> {
    try {
        const key = await getCookieConsentKey();
        if (app.config.globalProperties.$cookies.isKey(key)) {
            return (
                0 !=
                (app.config.globalProperties.$cookies.get(key) &
                    CookieConsentStatistics)
            );
        }
    } catch (err) {
        console.log("isCookieConsentStatisticsAllowed", err);
    }
    return false;
}

function toDateFormat(date: Date | string | null | undefined) {
    if (null === date || undefined === date) {
        return "";
    }
    const d = cloneDateOrNull(date);
    if (null === d) {
        return "";
    }
    return formatDateRule(d, i18n.global.t("date-picker_format") as string);
}

function toTimeFormat(date: string) {
    const d = cloneDateOrNull(date);
    const h = null === d ? "0" : digits(d.getHours());
    const m = null === d ? "0" : digits(d.getMinutes());

    function digits(str: number): string {
        return str < 10 ? `0${str}` : `${str}`;
    }

    return `${h}:${m}`;
}

function filterDateTime(date: string) {
    const data = toDateFormat(date);
    const time = toTimeFormat(date);
    return `${data} ${time}`;
}

function toMoneyFormat(value: string | number | null | undefined) {
    if (null === value || undefined === value) {
        return "";
    }
    if (typeof value == "string") {
        value = parseFloat(value);
    }
    return value.toFixed(2);
}

function filterNoSpaces(text: string | number) {
    let string = "";
    if (typeof text === "number") string = text.toString();
    else string = text;
    return string.split(" ").join("");
}

export {
    throttleEv,
    deepCopy,
    requestVB,
    toRouterUrl,
    getDateInfo,
    convertToDateFormat,
    twoDigits,
    noNulls,
    preparePhoneNumber,
    getEntityId,
    getLocalCart,
    setLocalCart,
    emailPattern,
    checkUrl,
    nextDay,
    nextMonth,
    setDay,
    getTime,
    formatDateRule,
    checkedDateString,
    getDuration,
    safeHtml,
    getExternalVBReferenceValue,
    setExternalVBReferenceValue,
    getExternalPreviewNetsPaymentOptionValue,
    setExternalPreviewNetsPaymentOptionValue,
    hotelNow,
    cloneDate,
    cloneDateOrNull,
    getDefaultLanguageCountry,
    getCookieConsentKey,
    isCookieConsentStatisticsAllowed,
    toDateFormat,
    toTimeFormat,
    filterDateTime,
    toMoneyFormat,
    filterNoSpaces,
    phonePattern,
    toNulls
};
