<template>
  <div class="fixed z-[9] max-w-xl ml-10 top-30 right-10 mobile:left-0 mobile:right-2.5 mobile:ml-2.5" style="width: auto;">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      :class="notification.boxClass"
      class="duration-300 transition-opacity mb-0.5 p-6 rounded-xl flex mb-2 flex justify-between"
    >
      <div class="flex">
        <div class="mr-4 min-w-24px min-h-24px">
          <img
            v-if="notification.type === 'error'"
            src="@/components/forms/assets/Icons/notification/error.svg"
            alt=""
          >
          <img
            v-else-if="notification.type === 'info'"
            src="@/components/forms/assets/Icons/notification/info.svg"
            alt=""
          >
          <img
            v-else-if="notification.type === 'success'"
            src="@/components/forms/assets/Icons/notification/success.svg"
            alt=""
          >
          <img
            v-else
            src="@/components/forms/assets/Icons/notification/warning.svg"
            alt=""
          >
        </div>
        <div class="flex flex-col">
          <template v-for="(line, i) in notification.message" :key="i">
            <span style="overflow-wrap: anywhere;">{{ line }}</span>
          </template>
        </div>
      </div>
      <button
        v-if="notification.type !== 'success'"
        type="button"
        @click="closeNotification(notification.id)"
        class="ml-4 mt-1 flex min-w-16px min-h-16px"
      >
        <img src="@/components/forms/assets/Icons/S-size/x.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import store from '@/store';
import { computed, ref, watch } from 'vue';

interface Notification {
  id: number;
  boxClass: object;
  type: string;
  message: string | string[];
}

const notifications: any = ref([]);

const types = ['error', 'info', 'success', 'warning'];

const notificationInfo: any = computed(() => store.state.mod_globalView.notifications);

const beforeIcon = (type: string) => {
  return `${process.env.BASE_URL}/icons/notification/${type}.svg`;
};

const closeNotification = (id: number) => {
  store.dispatch("mod_globalView/HIDE_notification", id);
};

const prepareMessage = (rawMessage: any[]) => {
  const message: string[] = [];
    rawMessage.forEach((part: any) => {
      if (Array.isArray(part)) {
        Array.prototype.push.apply(message, prepareMessage(part));
      } else {
        Array.prototype.push.apply(message, part.split(/\r?\n/));
      }
    });
    return message;
};

watch(
  notificationInfo, () => {
    notifications.value = [];
    notificationInfo.value.forEach((notification: any) => {
      notifications.value.push({
        id: notification.id,
        message: prepareMessage(notification.message),
        type: notification.type,
        boxClass: {
          "vb-bg-error": notification.type === "error",
          "vb-bg-success": notification.type === "success",
          "vb-bg-warning": notification.type === "warning",
          "vb-bg-info": notification.type === "info",
        },
      });
    });
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>

<style lang="scss" scoped>
@import "../scss/base";
</style>
