<template>
  <div
    v-click-outside="()=> {this.isOpen = false}"
    :class="{'vb-the-language--open': isOpen}"
    class="vb-the-language mobile:hidden"
  >
    <button
      type="button"
      aria-label="open languages list"
      @click="isOpen = !isOpen"
      class="vb-the-language_current px-4 py-2 flex rounded-sm hover:bg-translucentAthensGray focus:bg-translucentAthensGray focus:outline-none"
    >
      <img src="@/components/forms/assets/Icons/M-size/globe-white.svg" alt="">
      <!-- <img :src="`/icons/${currentLang}.svg`" alt=""> -->
      <span class="ml-2">{{ currentLangName }}</span>
    </button>

    <div class="absolute right-0 top-12">
      <ul class="vb-the-language_list">
        <li
          v-for="lang in existLanguages"
          :key="lang.code"
        >
          <button
            type="button"
            @click="setLang(lang.code)"
            class="vb-the-language_btn p-4 hover:bg-athensGray flex justify-between"
          >
            <span>{{ lang.name }}</span>
            <img
              v-if="lang.flagClass === currentLang || (lang.flagClass === 'gb' && currentLang === 'en')"
              src="@/components/forms/assets/Icons/M-size/check.svg"
              class="pl-4"
              alt="checked"
            >
          </button>
        </li>
      </ul>
    </div>
  </div>
  <ul class="hidden mobile:block">
    <li
      v-for="lang in existLanguages"
      :key="lang.code"
    >
      <button
        type="button"
        @click="setLang(lang.code)"
        class="vb-the-language_btn vb-btn px-4 py-4"
      >
        <div class="w-full flex justify-between">
          <div class="flex">
            <img :src="`/icons/${lang.flagClass === 'gb' ? 'en' : lang.flagClass}.svg`" class="mr-4" alt="">
            <span>{{ lang.name }}</span>
          </div>
          <img
            v-if="lang.flagClass === currentLang || (lang.flagClass === 'gb' && currentLang === 'en')"
            src="@/components/forms/assets/Icons/M-size/check.svg"
            alt=""
          >
        </div>
      </button>
    </li>
  </ul>
</template>

<script lang="ts">
import i18n from "@/i18n";

export default {
  data: () => ({
    isOpen: false,
  }),
  computed: {
    currentLang() {
      return this.$store.state.mod_globalView.siteLanguage;
    },
    allCountries() {
      return this.$store.state.mod_globalView.countries;
    },
    enabledLanguages() {
      return this.$store.state.mod_company.companySettings?.enabledLanguages.reduce(
        (langs: string[], lang: string) => {
          switch (lang) {
            case "nb-NO": // Norwegian
              langs.push("no");
              break;
            case "sv-SE": // Swedish
              langs.push("se");
              break;
            case "da-DK": // Danish
              langs.push("dk");
              break;
            case "de-DE": // German
              langs.push("de");
              break;
            case "en-GB": // English
              langs.push("en");
              break;
            // not supported yet, but backend has them
            // case 'fi-FI':
            // case 'ru-RU':
          }
          return langs;
        },
        []
      );
    },
    existLanguages() {
      const translations = i18n.global.availableLocales;
      const languages = this.allCountries.reduce((acc: any[], el: any) => {
        if (
          translations.includes(el.langIso) ||
          translations.includes(el.iso2)
        ) {
          acc.push({
            name: el.langNative || el.nameNative || el.name,
            code: el.langIso || el.iso2,
            flagClass: el.iso2,
          });
        }
        return acc;
      }, []);
      if (this.enabledLanguages?.length > 0) {
        return languages.reduce((acc: any[], el: any) => {
          if (this.enabledLanguages?.includes(el.code)) {
            acc.push(el);
          }
          return acc;
        }, []);
      }
      return languages;
    },
    languageList() {
      return this.existLanguages.filter(
        (el: any) => el.code !== this.currentLang
      );
    },
    currentLangName () {
      return this.existLanguages.filter((lang: any) => lang.code === this.currentLang)[0].name;
    },
  },
  methods: {
    async setLang(lang: string) {
      await this.$store.dispatch("mod_globalView/SET_siteLanguage", {
        lang,
        to: this.$route,
      });
      this.isOpen = false;
      location.replace(this.$route.fullPath)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base";
.vb-the-language {
  position: relative;
}
.vb-the-language_current {
  text-align: right;
}
.vb-the-language_icon {
  @include multi-transition(transform);
  display: inline-block;
  .vb-the-language--open & {
    transform: rotateX(180deg);
  }
}
.vb-the-language_list {
  @include multi-transition(opacity, transform);
  opacity: 0;
  transform: translateY(-2em);
  visibility: hidden;
  z-index: 1;
  width: 200px;
  border-radius: $bdrs;
  overflow: hidden;
  background-color: $bg-page;
  color: $col-txt;
  font-size: 1rem;
  box-shadow: 0px 8px 8px -4px #0000000A;
  box-shadow: 0px 20px 24px -4px #0000001A;
  .vb-the-language--open & {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}
.vb-the-language_btn {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0;
}
</style>
