const versionApi = "8";

const _externalReferenceParam = "vb_ref";
const _externalPreviewNetsPaymentOptionParam = "preview_nets_payment_option";

const _gtagId =
  process.env.NODE_ENV === "development" ? "G-CRDGSK3D3M" : "G-678QL11DRS";

let _ApiUrl = `https://ws.visbook.com/${versionApi}/api`;
if (process.env.VUE_APP_API_URL?.trim().length ?? 0 > 5) {
  _ApiUrl = eval('`' + process.env.VUE_APP_API_URL + '`'); // We're doing eval here to support ${versionApi} and dynamic environment
}
else {
  _ApiUrl = process.env.NODE_ENV === "development"
    // ? `http://127.0.0.1:8080/`
    ? `https://dev-front.ws-staging.visbook.com/api`
    : `https://ws.visbook.com/${versionApi}/api`;
}

const _resourceUrlPrefix = "https://res.cloudinary.com/visbook/image/upload/";
const _resourceImgParams = "w_250,h_60,c_limit,dpr_auto,f_auto,q_auto:best/";

const _cookieConsentFunctional = 0b01;
const _cookieConsentStatistics = 0b10;

const _MyBring_API_URL = "https://api-new.bring.com";
const _X_MyBring_API_Uid = "steffen@visbook.com";
const _X_MyBring_API_Key = "20853893-16f1-4faf-be62-1fc65940aaab";

export const ExternalReferenceParam = _externalReferenceParam;
export const GtagId = _gtagId;
export const ApiUrl = _ApiUrl;
export const ResourceUrlPrefix = _resourceUrlPrefix;
export const ResourceImgParams = _resourceImgParams;
export const ExternalPreviewNetsPaymentOptionParam =
  _externalPreviewNetsPaymentOptionParam;
export const CookieConsentFunctional = _cookieConsentFunctional;
export const CookieConsentStatistics = _cookieConsentStatistics;
export const MyBring_API_URL = _MyBring_API_URL;
export const X_MyBring_API_Uid = _X_MyBring_API_Uid;
export const X_MyBring_API_Key = _X_MyBring_API_Key;
