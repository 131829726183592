import i18n from "@/i18n";

export default {
  methods: {
    $_datePicker_i18nCalendar() {
      return {
        night: i18n.global.t("word_night"),
        nights: i18n.global.t("word_nights"),
        "day-names": i18n.global.tm("date-picker_days"),
        "check-in": i18n.global.t("word_checkIn"),
        "check-out": i18n.global.t("word_checkOut"),
        "month-names": i18n.global.tm("date-picker_months"),
      };
    },
    $_datePicker_dateFormat() {
      return i18n.global.t("date-picker_format");
    },
    $_datePicker_firstDayOfWeek() {
      return +i18n.global.t("date-picker_first-day");
    },
    $_datePicker_dateStyle() {
      return { "border": "1px solid #101828", "border-radius": "4px", "background-color": "#F2F4F7" };
    },
  },
};
