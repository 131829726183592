import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "vb-the-pagination" }
const _hoisted_2 = { class: "vb-the-pagination_nav vb-row vb-row--ai-c vb-row--jc-sb vb-the-pagination_nav-top" }
const _hoisted_3 = { class: "vb-pagination-nav_info vb-row vb-row--ai-c" }
const _hoisted_4 = { class: "vb-nav-info_result vb-h4 vb-text-uppercase mobile:text-sm" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "vb-pagination-nav_filter" }
const _hoisted_8 = { class: "vb-the-pagination_nav vb-row vb-row--ai-c vb-row--jc-sb" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "vb-the-pagination_list"
}
const _hoisted_11 = {
  key: 2,
  class: "vb-the-pagination_nav"
}
const _hoisted_12 = { class: "vb-pagination-nav_info vb-row vb-row--ai-c" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_filter_dynamic = _resolveComponent("search-filter-dynamic")!
  const _component_pagination_page_list = _resolveComponent("pagination-page-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          ($options.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('word_searching')) + "…", 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('pagination_result-found', $options.listLength)) + ":", 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_search_filter_dynamic, { onUpdateFilter: $options.resetPages }, null, 8, ["onUpdateFilter"])
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      ($props.allItems.length > $props.itemsInPage)
        ? (_openBlock(), _createBlock(_component_pagination_page_list, {
            key: 0,
            "model-value": _ctx.currentPage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
            "total-items": $options.listLength,
            "items-in-page": $props.itemsInPage
          }, null, 8, ["model-value", "total-items", "items-in-page"]))
        : _createCommentVNode("", true)
    ]),
    ($options.afterLoading)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_9, _toDisplayString($props.messageEmptyList), 1))
      : (_openBlock(), _createElementBlock("ul", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.currentPageItems, (room) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "vb-the-pagination_item",
              key: room.id
            }, [
              _renderSlot(_ctx.$slots, "default", { itemData: room })
            ]))
          }), 128))
        ])),
    ($props.allItems.length > 1)
      ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            ($props.allItems.length > $props.itemsInPage)
              ? (_openBlock(), _createBlock(_component_pagination_page_list, {
                  key: 0,
                  "model-value": _ctx.currentPage,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
                  "total-items": $options.listLength,
                  "items-in-page": $props.itemsInPage
                }, null, 8, ["model-value", "total-items", "items-in-page"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}