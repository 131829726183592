<template>
  <div
    class="
      flex gap-8 mx-[156px] my-20 p-10 bg-[#FFFFFF8F] min-w-max rounded z-[9]
      tablet:flex-col tablet:m-6
      mobile:flex-col mobile:mb-0 mobile:mx-0 mobile:p-4
    "
    :class="{'restaurants-style': restaurantsState}"
  >
    <div class="w-1/2 tablet:w-full mobile:w-full min-w-max">
      <div class="w-full">
        <base-input :text-error="errors.dates">

          <hotel-date-picker v-if="webProductIdList.length > 1" />
          <product-date-picker
            v-else-if="webProductIdList.length === 1"
            :product-id="singleProductId"
            :all-persons="filterPeopleCount"
            :show-cta-availability="false"
            :preload="true"
          />
        </base-input>
      </div>
    </div>

    <div class="flex gap-8 w-1/2 tablet:flex-col tablet:w-full mobile:flex-col mobile:w-full">
      <base-input
        v-if="!restaurantsState"
        class="w-1/2 tablet:w-full mobile:w-full min-w-max"
        :text-error="errors.accommodations"
      >
        <template v-slot:label>
          <div v-if="restaurantsState" class="text-sm text-black font-medium mb-1">
              {{ $t('page-search_w-adults') }}
            </div>
            <div v-else class="text-sm text-black font-medium mb-1">{{ $t('quick-booking_select_label') }}</div>
        </template>

        <roomtype-filter :desktop-max-width="360" />
      </base-input>

      <div class="tablet:w-full mobile:w-full min-w-max mt-6 mb-auto" :class="restaurantsState ? 'w-full' : 'w-1/2'">
        <button
          :disabled="disabled"
          class="bg-keppel hover:bg-oxfordBlue active:bg-haiti flex justify-center py-4 rounded w-full"
          @click="search"
        >
          <img src="@/components/forms/assets/Icons/M-size/search-white.svg" class="mr-2" alt="">
          <span>{{ $t('quick-booking_btn') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { toRouterUrl, cloneDate, cloneDateOrNull } from "@/core/helpers";
import { i_select, i_checkinPeriod } from "@/core/declarations";
import HotelDatePicker from "@/components/forms/hotel-date-picker/hotel-date-picker.vue";
import RoomtypeFilter from "@/components/the-pagination/roomtype-filter.vue";
import ProductDatePicker from "@/components/product-cart/product-date-picker.vue";

interface i_query {
  checkIn?: string;
  checkOut?: string;
  webProductId?: string;
}

interface Data {
  errors: { [key: string]: string };
}

export default {
  components: {
    HotelDatePicker,
    RoomtypeFilter,
    ProductDatePicker,
  },
  emits: ["checkOutUpdated"],
  data: () => ({
    errors: {
      dates: "",
      accommodations: "",
    },
  }),
  computed: {
    restaurantsState() {
      return this.$store.getters["mod_search/GET_restaurantsState"];
    },
    minNights() {
      if (this.$store.state.mod_company.companySettings) {
        return this.$store.state.mod_company.companySettings.timeSettings
          .minimumBookingLength;
      }
      return 0;
    },
    webProductIdList(): i_select[] {
      return this.$store.state.mod_search.roomTypeList;
    },
    checkinPeriod(): i_checkinPeriod {
      return this.$store.getters["mod_search/GET_searchDates"];
    },
    filterPeopleCount(): number {
      return this.$store.state.mod_search.filterPeopleCount;
    },
    singleProductId(): number {
      if (this.webProductIdList.length === 1) {
        return this.webProductIdList[0].value;
      }
      return -1;
    },
    disabled(): boolean {
      return !this.checkinPeriod.checkIn;
    },
  },
  methods: {
    search() {
      const checkIn = this.checkinPeriod.checkIn;
      const checkOut = this.checkinPeriod.checkOut;
      // const numberOfPersons = this.filterPeopleCount;
      const next = () => {
        toRouterUrl("search", [
          "webProductId",
          "checkIn",
          "checkOut",
          "numberOfPersons",
        ]);
      };
      if (checkIn && !checkOut)
        this.$emitter.on("checkOutUpdated", () => {
          next();
        });
      else next();
    },
  },
  watch: {
    "checkinPeriod.checkOut"(newValue: Date | null) {
      this.$emit("checkOutUpdated", newValue);
    },
    minNights: {
      handler() {
        const query: i_query = this.$route.query;

        const checkIn = cloneDateOrNull(query.checkIn);
        let checkOut = cloneDateOrNull(query.checkOut);
        if (checkIn && !checkOut && this.minNights) {
          checkOut = cloneDate(checkIn);
          checkOut.setDate(checkOut.getDate() + this.minNights);
        }
        if (checkIn && checkOut) {
          this.$store.dispatch("mod_search/SET_dateSettings", {
            checkIn,
            checkOut,
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base";
.vb-content-wrap.restaurants-style {
  max-width: 550px;
}
.vb-quick-booking {
  padding: 3vh 0;
  background: rgba($bg-model, 0.8);
  color: inherit;
}
.vb-quick-booking_cta {
  align-self: flex-end;
}
.vb-btn {
  border-radius: 0;
  height: $input-height;
}
.vb-form_row {
  flex-wrap: wrap;
}
.vb-mobile {
  .vb-form_row .vb-form_holder {
    flex-basis: 50%;
    max-width: 50%;
  }
}
.vb-rebuild-point {
  .vb-form_row {
    flex-wrap: nowrap;
    .vb-form_holder {
      max-width: 100%;
      flex-basis: auto;
    }
  }
}
.vb-form_holder {
  &::v-deep .vb-form_holder {
    margin: 0;
    padding: 0;
  }
}
</style>
