<template>
  <span class="vb-price-currency" v-if="dataPrice !== undefined">
    <price-base
      :data-price="dataPrice"
      :show-decimals="showDecimals"/>
    <span v-if="dataCurrency" class="vb-price-currency_currency vb-small-text">
      {{dataCurrency}}
    </span>
  </span>
</template>

<script lang="ts">
import PriceBase from "@/components/different/price-base.vue";

export default {
  name: "PriceCurrency",
  components: {PriceBase},
  props: {
    dataPrice: {
      type: [String, Number],
      default: "",
    },
    dataCurrency: {
      type: String,
      default: "",
    },
    showDecimals: {
      type: Boolean,
      default: true,
    }
  },
};
</script>

<style lang="scss" scoped>
//@import "../../scss/base";
.vb-price-currency {
  margin: 0;
}

.vb-text-secondary .vb-price-currency {
  margin: 0 0 0 .5em;
}

.vb-price-currency_currency {
  font-weight: 400;
  padding-left: 0.25rem;
}
</style>
