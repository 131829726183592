import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-683ffeb9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col text-center" }
const _hoisted_2 = { class: "vb-h1 text-56px tablet:text-5xl mobile:text-title" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_quick_booking = _resolveComponent("the-quick-booking")!
  const _component_template_base = _resolveComponent("template-base")!

  return (_openBlock(), _createBlock(_component_template_base, { "show-breadcrumbs": false }, {
    default: _withCtx(() => [
      _createElementVNode("main", {
        ref: "introEl",
        class: "h-[calc(100vh-80px)] flex flex-col justify-end text-white",
        style: _normalizeStyle($options.introStyles)
      }, [
        _createElementVNode("div", _hoisted_1, [
          ($options.pageTitle.title || $options.pageTitle.subTitle)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("h2", _hoisted_2, _toDisplayString($options.pageTitle.title), 1),
                _createElementVNode("h3", {
                  innerHTML: $options.pageTitle.subTitle,
                  class: "vb-intro_sub-title text-title tablet:text-2xl mobile:text-base"
                }, null, 8, _hoisted_3)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_the_quick_booking, { ref: "quickBooking" }, null, 512)
      ], 4)
    ], undefined, true),
    _: 1
  }))
}