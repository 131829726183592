import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocation,
} from "vue-router";
import PageHome from "@/pages/page-home.vue";
import PageSearch from "@/pages/page-search.vue";
import PageCheckout from "@/pages/page-checkout.vue";
import PageOrder from "@/pages/page-order.vue";
import PageLogin from "@/pages/page-login.vue";
import AccessDisabled from "@/pages/access-disabled.vue";
import PageNoFound from "@/pages/page-no-found.vue";
import PageNoEntity from "@/pages/page-no-entity.vue";
import store from "@/store";
import i18n from "@/i18n";
import { splashScreenStopper_contant } from "@/store/store";
import {
  ExternalReferenceParam,
  ExternalPreviewNetsPaymentOptionParam,
} from "@/config";
import {
  setExternalVBReferenceValue,
  setExternalPreviewNetsPaymentOptionValue,
  getDefaultLanguageCountry,
} from "@/core/helpers";
import { app } from "@/main";
import TestPage from "@/pages/test-page.vue";

const routes: any = [
  {
    path: "/:entity(\\d+)/",
    name: "home",
    meta: { titleLang: "pg-title_default" },
    component: PageHome,
    beforeEnter: redirectIfRequired,
  },
  {
    path: "/:entity(\\d+)/search",
    name: "search",
    meta: { titleLang: "pg-title_search", parent: "home" },
    component: PageSearch,
    beforeEnter: redirectIfRequired,
  },
  {
    path: "/:entity(\\d+)/checkout",
    name: "checkout",
    meta: { titleLang: "pg-title_checkout", parent: "home" },
    component: PageCheckout,
    beforeEnter: redirectIfRequired,
  },
  {
    path: "/:entity(\\d+)/order",
    name: "order",
    meta: { titleLang: "pg-title_orders", parent: "home" },
    component: PageOrder,
    beforeEnter: redirectIfRequired,
  },
  {
    path: "/:entity(\\d+)/login",
    name: "login",
    meta: { titleLang: i18n.global.t("pg-title_login") },
    component: PageLogin,
    beforeEnter: redirectIfRequired,
  },
  {
    path: "/test",
    name: 'test',
    meta: {titleLang: i18n.global.t("pg-title_login")},
    component: TestPage
  },
  // my-page
  {
    path: '/my-page/:entity(\\d+)/',
    redirect: { name: 'orders' }
  },
  {
    path: '/my-page/:entity(\\d+)/account',
    name: 'account',
    meta: { titleLang: i18n.global.t('pg-title_account') },
    component: () => import('@/pages/page-profile-account.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/my-page/:entity(\\d+)/orders',
    name: 'orders',
    meta: { titleLang: i18n.global.t('pg-title_orders') },
    component: () => import('@/pages/page-orders.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/my-page/:entity(\\d+)/orders/:order(\\d+)',
    name: 'my-order',
    meta: { titleLang: i18n.global.t('pg-title_orders') },
    component: () => import('@/pages/my-page-order.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/my-page/:entity(\\d+)/orders/payment/:order(\\d+)',
    name: 'my-payment',
    meta: { titleLang: i18n.global.t('pg-title_payment') },
    component: () => import('@/pages/my-page-payment.vue'),
  },
  {
    path: '/my-page/:entity(\\d+)/pay/',
    name: 'payment',
    meta: { titleLang: i18n.global.t('pg-title_payment') },
    component: () => import('@/pages/page-pay-order.vue'),
  },
  {
    path: '/my-page/:entity(\\d+)/delete',
    name: 'delete',
    meta: { titleLang: i18n.global.t('pg-title_delete') },
    component: () => import('@/pages/page-profile-delete.vue'),
    beforeEnter: authCheck
  },
  {
    path: '/my-page/:entity(\\d+)/account-deleted',
    name: 'account-deleted',
    meta: { titleLang: i18n.global.t('pg-title_deleted') },
    component: () => import('@/pages/page-profile-deleted.vue'),
  },
  {
    path: '/my-page/:entity(\\d+)/gdpr',
    name: 'gdpr',
    meta: { titleLang: i18n.global.t('pg-title_gdpr') },
    component: () => import('@/pages/page-profile-gdpr.vue'),
    beforeEnter: authCheck
  },
  /*errors*/
  {
    path: "/:entity(\\d+)/access-disabled",
    name: "AccessDisabled",
    meta: { titleLang: "pg-title_error" },
    component: AccessDisabled,
  },
  {
    path: "/:entity(\\d+)/*",
    redirect: { name: "NotFound" },
  },
  {
    path: "/no-entity",
    name: "NoEntity",
    meta: { titleLang: "pg-title_error" },
    component: PageNoEntity,
  },
  {
    path: "/:entity(\\d+)/not-found",
    name: "NotFound",
    meta: { titleLang: "pg-title_error" },
    component: PageNoFound,
  },
  {
    path: "/:pathMatch(.*)",
    redirect: { path: "/no-entity" },
  },
];

async function redirectIfRequired(
  to: RouteLocation,
  from: RouteLocation,
  next: any
) {
  const reservationAllowed = await isReservationAllowed();
  if (reservationAllowed) {
    const isAuthenticated = await isLoggedIn();
    const mustLogin = await isLoginRequired();
    if ("login" === to.name) {
      next();
      // if(mustLogin && !isAuthenticated) {
      // }
      // else {
      //   next({ name: 'home', params: to.params, query: to.query });
      // }
    } else {
      if (mustLogin && !isAuthenticated) {
        next({ name: "login", params: to.params, query: to.query });
      } else {
        next();
      }
    }
  } else {
    next();
  }
}

async function authCheck(to: any, from: any, next: any) {
  const isAuthenticated = await isLoggedIn();
  if (isAuthenticated) {
    next();
  } else {
    next({
      name: 'login',
      params: to.params,
      query: to.query
    });
  }
}

async function isLoginRequired() {
  const mustLogin = store.getters["mod_company/mustLogin"];
  if (null === mustLogin) {
    return store
      .dispatch("mod_company/GET_setupInfo")
      .then(() => {
        return store.getters["mod_company/mustLogin"];
      })
      .catch((err: any) => {
        throw err;
      });
  }
  return mustLogin;
}

async function isLoggedIn() {
  const isAuthenticated = store.getters["mod_user/isAuthenticated"];
  if (null === isAuthenticated) {
    return store
      .dispatch("mod_user/GET_userProfile")
      .then(() => {
        return null !== store.getters["mod_user/isAuthenticated"];
      })
      .catch(() => {
        return false;
      });
  }
  return null !== isAuthenticated;
}

async function isReservationAllowed() {
  const reservationAllowed = store.getters["mod_company/reservationAllowed"];
  if (null === reservationAllowed) {
    return store
      .dispatch("mod_company/GET_setupInfo")
      .then(() => {
        return store.getters["mod_company/reservationAllowed"];
      })
      .catch((err: any) => {
        throw err;
      });
  }
  return reservationAllowed;
}

function fixQueryParams(route: RouteLocation) {
  for (const param in route.query) {
    switch (param.toLowerCase()) {
      case ExternalReferenceParam.toLowerCase():
        fixQueryParam(route, param, ExternalReferenceParam);
        break;
      case ExternalPreviewNetsPaymentOptionParam.toLowerCase():
        fixQueryParam(route, param, ExternalPreviewNetsPaymentOptionParam);
        break;
      case "errorcode":
        fixQueryParam(route, param, "errorCode");
        break;
      case "lang":
        fixQueryParam(route, param, "lang");
        break;
      case "checkin":
        fixQueryParam(route, param, "checkIn");
        break;
      case "checkout":
        fixQueryParam(route, param, "checkOut");
        break;
      case "webproductid":
        fixQueryParam(route, param, "webProductId");
        break;
    }
  }
}

function getLanguageFromState() {
  return filterUnsupportedLanguages(
    (store.state as any).mod_globalView.siteLanguage
  );
}

function getLanguageFromQuery(route: RouteLocation) {
  return filterUnsupportedLanguages(route.query.lang);
}

function getLanguageFromNavigator() {
  return filterUnsupportedLanguages(window.navigator.language.slice(0, 2));
}

function getLanguageFromCookies() {
  return filterUnsupportedLanguages(
    app.config.globalProperties.$cookies.get("lang")
  );
}

async function getLanguageFromRemoteServer() {
  if (!sessionStorage.getItem("defaultLanguage")) {
    return getDefaultLanguageCountry()
      .then(() => {
        return sessionStorage.getItem("defaultLanguage") as string;
      })
      .catch(() => {
        return sessionStorage.getItem("defaultLanguage") as string;
      });
  }
  return sessionStorage.getItem("defaultLanguage") as string;
}

function filterUnsupportedLanguages(language: any): string {
  switch (language) {
    case "no":
    case "de":
    case "se":
    case "en":
    case "dk":
      return language;
  }
  return "";
}

function fixQueryParam(
  route: RouteLocation,
  wrongKey: string,
  correctKey: string
) {
  if (
    wrongKey !== correctKey &&
    Object.prototype.hasOwnProperty.call(route.query, wrongKey)
  ) {
    route.query[correctKey] = route.query[wrongKey];
    delete route.query[wrongKey];
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: RouteLocation, from: RouteLocation, next) => {
  fixQueryParams(to);

  if (ExternalReferenceParam in to.query) {
    setExternalVBReferenceValue(to.query[ExternalReferenceParam] as string);
  }

  if (ExternalPreviewNetsPaymentOptionParam in to.query) {
    setExternalPreviewNetsPaymentOptionValue(
      to.query[ExternalPreviewNetsPaymentOptionParam] as string
    );
  }

  if (to.name === 'search'){
    store.commit('mod_search/set_allRooms', [])
  }

  if ("errorCode" in to.query) {
    let errorCode = to.query["errorCode"] as string;
    if (
      ![
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
      ].includes(errorCode)
    ) {
      errorCode = "unknown";
    }
    store.dispatch("mod_globalView/SHOW_notification", {
      message: i18n.global.t("error_payment_" + errorCode),
      type: "error",
    });
    delete to.query["errorCode"];
  }

  to.meta.title = i18n.global.t(to.meta.titleLang as string);

  next();
});

router.beforeEach(async (to: RouteLocation, from: RouteLocation, next) => {
  // from internal state
  let lang = getLanguageFromState();
  if (lang.length) {
    next();
    return;
  }

  // from query request
  lang = getLanguageFromQuery(to);
  if (lang.length) {
    store.dispatch("mod_globalView/SET_siteLanguage", {
      lang: lang,
      to: to,
      skipReplace: true,
    });
    next();
    return;
  }

  // from cookie
  lang = getLanguageFromCookies();
  if (lang.length) {
    next({ path: to.path, query: { ...to.query, lang } });
    return;
  }

  // from navigator.language
  lang = getLanguageFromNavigator();
  if (lang.length) {
    next({ path: to.path, query: { ...to.query, lang } });
    return;
  }

  // from a remote service
  lang = await getLanguageFromRemoteServer();
  next({ path: to.path, query: { ...to.query, lang: lang } });
});

router.afterEach(() => {
  store.dispatch(
    "mod_globalView/DEC_splashScreen",
    splashScreenStopper_contant.LANGUAGE
  );
});

export default router;
