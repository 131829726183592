import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "vb-form_row vb-row--ai-b" }
const _hoisted_2 = {
  key: 0,
  class: "vb-form_holder"
}
const _hoisted_3 = { class: "vb-form_label" }
const _hoisted_4 = { class: "vb-form_holder" }
const _hoisted_5 = { class: "vb-form_label" }
const _hoisted_6 = {
  key: 1,
  class: "vb-form_holder vb-w-50 vb-form_price"
}
const _hoisted_7 = { class: "vb-form_label" }
const _hoisted_8 = { class: "multiselect-single-label w-full" }
const _hoisted_9 = { class: "flex justify-between w-full" }
const _hoisted_10 = { class: "vb-form_price-name" }
const _hoisted_11 = { class: "vb-form_price-value" }
const _hoisted_12 = { class: "flex justify-between w-full" }
const _hoisted_13 = { class: "vb-form_price-name" }
const _hoisted_14 = { class: "vb-form_price-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_select = _resolveComponent("base-select")!
  const _component_product_select_people = _resolveComponent("product-select-people")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.showDateSelect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('word_date')), 1),
          _createVNode(_component_base_select, {
            "model-value": _ctx.selectedDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event)),
            disabled: $options.disableAll,
            options: $options.activeDateList,
            searchable: false,
            allowEmpty: false,
            multiple: false,
            showLabels: false,
            "hide-one": true
          }, null, 8, ["model-value", "disabled", "options"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_product_select_people, {
      "model-value": _ctx.selectedPerson,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedPerson) = $event)),
      disabled: $options.disablePeople,
      options: $props.selectPeopleOptions,
      "for-activity": true,
      class: "vb-w-50"
    }, null, 8, ["model-value", "disabled", "options"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('word_time')), 1),
      _createVNode(_component_base_select, {
        class: _normalizeClass([{'restaurant-time-selector': $props.itemData.type === 'restaurant'}, "vb-form-time-selector vb-text-right"]),
        "model-value": _ctx.lazySelectedTime,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lazySelectedTime) = $event)),
        disabled: $options.disableAll,
        options: $options.activeTimeList,
        groups: "",
        "group-label": "duration",
        "group-options": "times",
        "group-select": false,
        label: "name",
        valueProp: "name",
        "track-by": "name",
        searchable: false,
        allowEmpty: false,
        multiple: false,
        showLabels: false,
        "hide-one": true
      }, null, 8, ["class", "model-value", "disabled", "options"])
    ]),
    ($props.itemData.type !== 'restaurant')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('word_price')), 1),
          _createVNode(_component_Multiselect, {
            "model-value": _ctx.lazySelectedPrice,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lazySelectedPrice) = $event)),
            disabled: $options.disableAll,
            options: $options.activePriceList,
            searchable: false,
            allowEmpty: false,
            multiple: false,
            showLabels: false,
            "hide-one": true,
            valueProp: "id",
            canClear: false,
            "track-by": "calculatedPrice"
          }, {
            singlelabel: _withCtx(({ value }) => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(value.name), 1),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.selectedPrice?.priceName), 1)
                ])
              ])
            ]),
            option: _withCtx(({ option }) => [
              _createElementVNode("span", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(option.name), 1),
                _createElementVNode("span", _hoisted_14, _toDisplayString(option.priceName), 1)
              ])
            ]),
            _: 1
          }, 8, ["model-value", "disabled", "options"])
        ]))
      : _createCommentVNode("", true)
  ]))
}