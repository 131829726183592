import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vb-form_holder" }
const _hoisted_2 = { class: "vb-form_label" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('word_notes')) + ":", 1),
    _createElementVNode("textarea", {
      class: "vb-textarea",
      value: _ctx.note,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => ($options.blur($event.target.value)))
    }, null, 40, _hoisted_3)
  ]))
}