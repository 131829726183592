import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38b22463"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-page_main vb-content-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "vb-page-main_sidebar min-w-[400px] mobile:min-w-full"
}
const _hoisted_3 = { class: "vb-page-main_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showAside)
      ? (_openBlock(), _createElementBlock("aside", _hoisted_2, [
          _renderSlot(_ctx.$slots, "aside", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_3, [
      _renderSlot(_ctx.$slots, "main", {}, undefined, true)
    ])
  ]))
}