import {out_company, RequestError} from "visbook-api";
import router from "@/router";
import { GtagId } from "@/config";
import VueGtag from "vue-gtag";
import {
  i_company,
  i_out_setup,
  i_out_company,
  i_timeZoneInfo,
} from "@/store/store";
import {
  requestVB,
  isCookieConsentStatisticsAllowed,
  getEntityId,
} from "@/core/helpers";
import { ActionContext, Dispatch } from "vuex";
import { app } from "@/main";

const namespaced = true;

const state: i_company = {
  companyInfo: null,
  companyInfoPromise: null,
  companySettings: null,
  setupPromise: null,
  companies: {}
};

const getters = {
  getCompanySettings(state: i_company) {
    return state.companySettings;
  },
  getCompanyInfo(state: i_company) {
    return state.companyInfo;
  },
  companyCurrency(state: i_company) {
    return state.companyInfo ? state.companyInfo.currency.name : "";
  },
  mustLogin(state: i_company) {
    return state.companySettings
      ? !state.companySettings.allowCustomerRegistration
      : null;
  },
  reservationAllowed(state: i_company) {
    return state.companySettings
      ? state.companySettings.enabledServices.reservations
      : null;
  },
  timeZone(state: i_company): i_timeZoneInfo | null {
    return state.companyInfo ? state.companyInfo.timeZoneInfo : null;
  },
  companyGACode(state: i_company): string {
    if (state.companySettings) {
      if (state.companySettings.analytics.length > 0) {
        const gaCode = state.companySettings.analytics.find(
          (i: any) => i.name === "GA"
        );
        if (gaCode) {
          return gaCode.accountId;
        }
      }
    }
    return "";
  },
};

const mutations = {
  set_companyInfo(state: i_company, info: i_out_company) {
    state.companyInfo = info;
    if (state.companyInfo.email && state.companyInfo.email.length) {
      state.companyInfo.email = state.companyInfo.email.trim();
    }
  },
  set_setupInfo(state: i_company, value: i_out_setup) {
    state.companySettings = value;
  },
  set_primaryColor(state: i_company, color: string) {
    const cssRoot: any = document.querySelector(":root");
    const rootStyles = getComputedStyle(cssRoot);
    const textDark = rootStyles.getPropertyValue("--vb-text-dark");
    const textLight = rootStyles.getPropertyValue("--vb-text-light");

    cssRoot.style.setProperty("--vb-color-primary", color);
    cssRoot.style.setProperty(
      "--vb-color-primary-txt",
      isLightColor(color) ? textDark : textLight
    );

    function isLightColor(color: string): boolean {
      const colNum =
        color[0] === "#" ? color.substr(1, color.length - 1) : color;
      const r = parseInt(colNum.substr(0, 2), 16);
      const g = parseInt(colNum.substr(2, 2), 16);
      const b = parseInt(colNum.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128;
    }
  },
};

const actions = {
  GET_companyInfo({ commit, dispatch }: any) {
    const getCompany: any = app.config.globalProperties.$visbook.GET_company;
    return requestVB(getCompany)
      .then((info: i_out_company) => {
        commit("set_companyInfo", info);
        if (info?.properties?.webbooking_service_message?.text.length) {
          showWebbookingServiceMessage(
            dispatch,
            info?.properties?.webbooking_service_message?.text + "dddd"
          );
        }

        return info;
      })
      .catch((err: RequestError) => {
        throw err;
      });
  },

  // eslint-disable-next-line no-empty-pattern
  GET_companyInfoById({}: any, encryptedCompanyId: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (!state.companies.hasOwnProperty(encryptedCompanyId)) {
      state.companies[encryptedCompanyId] = requestVB(
          app.config.globalProperties.$visbook.GET_companyByID as any,
          encryptedCompanyId
      )
          .then((companyInfo: out_company) => {
            return companyInfo.name
          })
          .catch((err: RequestError) => {
            throw err;
          })
    }
    return state.companies[encryptedCompanyId];
  },

  GET_setupInfo({
    state,
    dispatch,
    commit,
    getters,
  }: ActionContext<i_company, any>) {
    if (null === state.setupPromise) {
      const getSetup: any = app.config.globalProperties.$visbook.GET_setup;
      state.setupPromise = requestVB(getSetup)
        .then((info: any) => {
          const minPersons = +info?.properties?.persons_min?.value;
          const maxPersons = +info?.properties?.persons_max?.value;
          if (minPersons && maxPersons) {
            commit(
              "mod_search/set_filterPeopleOptions",
              {
                min: minPersons,
                max: maxPersons,
              },
              { root: true }
            );
          }

          commit("set_setupInfo", info);
          isCookieConsentStatisticsAllowed()
            .then((isAllowed: boolean) => {
              installGtag(app, isAllowed, getters.companyGACode);
            })
            .catch((err: any) => {
              throw err;
            });
          if (info.colors.mainColor) {
            commit("set_primaryColor", info.colors.mainColor);
          }

          if (info.enabledServices.reservations === false) {
            if ("NotFound" !== router.currentRoute.value.name) {
              router.push({
                name: "NotFound",
                params: { entity: app.config.globalProperties.$entity },
              });
            }
          }

          if (info?.properties?.webbooking_service_message?.text.length) {
            showWebbookingServiceMessage(
              dispatch,
              info?.properties?.webbooking_service_message?.text + "cccc"
            );
          }

          return info;
        })
        .catch((err: RequestError) => {
          if (
            err.status === 403 &&
            "AccessDisabled" !== router.currentRoute.value.name
          ) {
            router.push({
              name: "AccessDisabled",
              params: { entity: app.config.globalProperties.$entity },
            });
          } else if ("NoEntity" !== router.currentRoute.value.name) {
            router.push({ name: "NoEntity" });
          }
          throw err;
        });
    }
    return state.setupPromise;
  },
};

const installGtag = (app: any, isEnabled: boolean, param: string) => {
  const includes: any = [];
  if (param) {
    includes.push({ id: param });
  }
  app.use(
    VueGtag,
    {
      config: { id: GtagId },
      enabled: isEnabled,
      includes,
    },
    router
  );
};

const showWebbookingServiceMessage = (dispatch: Dispatch, message: string) => {
  const messageHash = cyrb53(message, getEntityId());
  if (!sessionStorage.getItem(messageHash)) {
    dispatch(
      "mod_globalView/SHOW_notification",
      {
        message: message,
        type: "warning",
      },
      { root: true }
    );
    sessionStorage.setItem(messageHash, "1");
  }
};

const cyrb53 = function (str: string, seed = 0): string {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
