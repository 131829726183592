<template>
    <div v-bind="{ class: attrs.class }">
        <p class="mb-1 text-sm font-medium text-gray-600" v-if="props.label">
            {{ props.label }}
        </p>
        <ElInput
            v-bind="{ ...attrs, class: inputClass }"
            class="[&>div]:!shadow-[0_0_0_1px_#d1d5db]"
        >
        </ElInput>
    </div>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue';

defineOptions({
    inheritAttrs: false,
})

export interface Props {
    label?: string
    inputClass?: string
    type?: string
}

const props = withDefaults(defineProps<Props>(), {
    inputClass: 'h-[56px]',
    type: 'text',
})

const attrs = useAttrs()
</script>
