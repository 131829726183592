import {out_order, out_orderGroup} from "visbook-api";
import {requestVB} from "@/core/helpers";
import {ActionContext} from "vuex";
import {i_orders} from "@/store/store";
import {app} from "@/main";

const namespaced = true;

const state = {
    selectedOrder: {
        id: null,
        currency: "",
    },
    orders: [],
    orderGroups: [],
    activeOrder: null,
    activeOrderGroups: null
};

const getters = {};

const mutations = {
    set_selectedOrderCurrency(state: i_orders, currency: string) {
        state.selectedOrder.currency = currency;
    },
    set_orders(state: i_orders, ordersList: out_order[]) {
        state.orders = ordersList;
    },
    set_orderGroups(state: i_orders, ordersList: out_orderGroup[]) {
        state.orderGroups = ordersList;
    },
    set_activeOrder(state: i_orders, data: out_order) {
        state.activeOrder = data;
    },
    set_activeOrderGroups(state: i_orders, data: out_orderGroup) {
        state.activeOrderGroups = data;
    },
};

const actions = {
    CLEAR_selectedOrderCurrency({commit}: ActionContext<i_orders, any>) {
        commit("set_selectedOrderCurrency", "");
    },
    SET_selectedOrderCurrency(
        {commit}: ActionContext<i_orders, any>,
        currency: string
    ) {
        commit("set_selectedOrderCurrency", currency);
    },
    GET_orders({commit}: ActionContext<i_orders, any>): Promise<any> {
        return Promise.all([
            requestVB(app.config.globalProperties.$visbook.GET_orders),
            requestVB(app.config.globalProperties.$visbook.GET_orderGroups),
        ])
            .then((order: any[]) => {
                commit("set_orders", order[0]);
                commit("set_orderGroups", order[1]);
                return true;
            })
            .catch((err: Error) => {
                throw err;
            });
    },
    GET_ordersById({commit}: ActionContext<any, any>, id: string) {
        let activeOrder: any = undefined;
        requestVB(app.config.globalProperties.$visbook.GET_ordersById, id).then(order => {
            const orderGroupId = order.orderGroupId;
            requestVB(app.config.globalProperties.$visbook.GET_orderGroupsById, orderGroupId)
            .then((orderGroups: out_orderGroup) => {
                activeOrder = {
                    currency: orderGroups.currency,
                    cancellationRules: orderGroups.cancellationRules,
                    ...order,
                }
                commit('set_activeOrder', activeOrder);
                commit('set_activeOrderGroups', orderGroups);
            })
            .catch((err: Error) => {
                throw err;
            });
            return order;
        })
            .catch((err: Error) => {
                throw err;
            });
    },
    GET_orderGroupsById({commit}: ActionContext<any, any>, id: string) {
        requestVB(app.config.globalProperties.$visbook.GET_orderGroupsById, id)
            .then((orderGroups: out_orderGroup) => {
                commit('set_activeOrderGroups', orderGroups);
                return orderGroups;
            })
            .catch((err: Error) => {
                throw err;
            });
    }
};

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
