import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-476c59d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vb-room-head_cost vb-room-offer_aside"
}
const _hoisted_2 = {
  key: 0,
  class: "vb-h5 vb-room-head_noprice"
}
const _hoisted_3 = {
  key: 1,
  class: "vb-h5 vb-room-head_noprice vb-select-price-message"
}
const _hoisted_4 = { class: "vb-form_label" }
const _hoisted_5 = { class: "multiselect__label" }
const _hoisted_6 = { class: "vb-room-head_price-name multiselect__title" }
const _hoisted_7 = { class: "vb-room-head_price-price multiselect__price" }
const _hoisted_8 = { class: "vb-room-head_price-type multiselect__unit" }
const _hoisted_9 = { class: "vb-room-head_price-option-name" }
const _hoisted_10 = { class: "vb-room-head_price-option-price" }
const _hoisted_11 = {
  key: 3,
  class: "vb-h5 vb-room-head_noprice"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return ($options.isSearchPerformed)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!$props.configurationOk)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('invalid-person-setup')), 1))
          : (typeof(this.selectPeopleValue) !== 'number')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('please-select-message')), 1))
            : ($props.modelValue)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('word_price')), 1),
                  _createVNode(_component_multiselect, {
                    class: "vb-room-head_price multiselect",
                    "model-value": $options.selectedId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($options.updatePrice($event))),
                    options: $options.priceList,
                    label: "label",
                    "value-prop": "value",
                    "track-by": "value",
                    allowEmpty: false,
                    showLabels: false,
                    searchable: false,
                    disabled: $options.priceList && $options.priceList.length < 2,
                    caret: !($options.priceList && $options.priceList.length < 2),
                    "can-deselect": false
                  }, {
                    singlelabel: _withCtx((props) => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(props.value.label), 1),
                        _createElementVNode("span", _hoisted_7, _toDisplayString($options.pricePerStep) + " " + _toDisplayString($options.currency), 1),
                        _createElementVNode("span", _hoisted_8, _toDisplayString($options.unit), 1)
                      ])
                    ]),
                    option: _withCtx((props) => [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(props.option.label), 1),
                      _createElementVNode("span", _hoisted_10, _toDisplayString(props.option.price), 1)
                    ]),
                    _: 1
                  }, 8, ["model-value", "options", "disabled", "caret"])
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('no-valid-price')), 1))
      ]))
    : _createCommentVNode("", true)
}