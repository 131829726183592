<template>
<BasePicker
  v-if="settings"
  ref="datePicker"
  :disabled="isDisable"
  :starting-date-value="value.checkIn"
  :ending-date-value="value.checkOut"
  :start-date="startBooking"
  :end-date="stopBooking"
  :min-nights="minNights"
  :max-nights="maxNights"
  :showYear="true"
  :format="$_datePicker_dateFormat()"
  :i18n="$_datePicker_i18nCalendar()"
  :first-day-of-week="$_datePicker_firstDayOfWeek()"
  :current-date-style="$_datePicker_dateStyle()"
  :singleDaySelection="singleDaySelection"
  @check-in-changed="updateInfo($event, true)"
  @check-out-changed="updateInfo($event, false)"
  class="hotel-date-picker"
>
</BasePicker>
</template>

<script lang="ts">
import { getTime, nextDay, setDay, cloneDate, hotelNow } from "@/core/helpers";
import { i_checkinPeriod } from "@/core/declarations";

import BasePicker from "@/components/forms/hotel-date-picker/date-picker/DatePicker.vue";
import ProductMixin from "@/components/forms/hotel-date-picker/date-picker/DatePickerMixin";

export default {
  name: "HotelDatePicker",
  components: { BasePicker },
  mixins: [ProductMixin],
  computed: {
    restaurantsState() {
      return this.$store.getters["mod_search/GET_restaurantsState"];
    },
    value(): i_checkinPeriod {
      return this.$store.getters["mod_search/GET_searchDates"];
    },
    settings() {
      if (this.$store.state.mod_company.companySettings) {
        return this.$store.state.mod_company.companySettings.timeSettings;
      }
      return null;
    },
    singleDaySelection() {
      return this.restaurantsState ? true : false;
    },
    maxNights() {
      return !this.settings || this.settings.maximumBookingLength <= 0
        ? 0
        : this.settings.maximumBookingLength - 1;
    },
    minNights() {
      if (
        this.settings &&
        this.settings.minimumBookingLength ===
          this.settings.maximumBookingLength
      ) {
        return this.maxNights;
      }
      return this.settings.minimumBookingLength || 1;
    },
    startBooking() {
      const currentDate = hotelNow();
      let startDate = currentDate;
      const closeBookingTime = getTime(this.settings.closingTime);
      const userTime = currentDate.getHours() + currentDate.getMinutes() / 60;
      if (closeBookingTime && closeBookingTime < userTime) {
        startDate = nextDay(currentDate);
      }
      if (this.settings.openingDate) {
        const openDate = cloneDate(this.settings.openingDate);
        return startDate > openDate ? startDate : openDate;
      } else return startDate;
    },
    stopBooking() {
      return this.settings.closingDate
        ? cloneDate(this.settings.closingDate)
        : Infinity;
    },
    isDisable() {
      return (
        setDay(this.startBooking, this.settings?.minimumBookingLength) >
        this.stopBooking
      );
    },
  },
  methods: {
    updateInfo(data: Date, isInput: boolean) {
      const modelData = { ...this.value };
      isInput ? (modelData.checkIn = data) : (modelData.checkOut = data);
      this.$store.dispatch("mod_search/SET_dateSettings", modelData);
    },
  },
};
</script>

<style lang="scss">
</style>
