import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba5cfe8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["required", "disabled", "checked"]
const _hoisted_2 = { class: "vb-checkbox-fake" }
const _hoisted_3 = {
  key: 0,
  class: "vb-checkbox-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["vb-custom-checkbox vb-small-text", {'vb-text-disable': $props.disabled}])
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      required: $props.required,
      disabled: $props.disabled,
      checked: $props.modelValue,
      onChange: _cache[0] || (_cache[0] = ($event: any) => { _ctx.$emit('change-event', $event); _ctx.$emit('update:modelValue', $event.target.checked); _ctx.$emit('change', $event.target.checked);})
    }, null, 40, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", {
        class: _normalizeClass(["vb-icon", $props.iconChecked])
      }, null, 2)
    ]),
    (!$props.noText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}