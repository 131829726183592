<template>
  <div class="test">
    Test Page
    <div>
      <!--   Work   -->
      <base-input
          placeholder="test"
          v-model="text"
          enable-read-only
      />
      <p>
        {{ text }}
      </p>
      <!--   Work   -->
      <base-switch
          v-model="switchBoolean"
          placeholder="switch"
          :first-value="false"
          :second-value="true"
          :second-text="$t('page-account_form_subscribe')"
      />
      <p>
        {{ switchBoolean }}
      </p>
      <!--   Work   -->
      <base-input-phone
          v-model="phone"
          :input-placeholder="$t('word_phone')"
          :input-options="{showDialCode: false}"
      />
      <p>{{ phone }}</p>
      <!--   Work   -->
      <update-email
          :model-email="email"
          @confirm-email="confirmEmail"
      />
      <p>{{ email }}</p>
      <!--   Work (need base-input-phone changes)   -->
      <update-mobile
          :show-dial-code="false"
          :model-mobile="mobile"
          @confirm-mobile="confirmMobile"
      />
      <!--   Work   -->
      <custom-text type="mypage_profile_info" />
      <!--   Work   -->
      <date-period data-to="2023-06-21T20:00:00" data-from="2023-06-19T10:00:00"/>
      <price-currency data-currency="NOK" data-price="1050"/>
    </div>
  </div>
</template>

<script lang="ts">
import BaseInput from "@/components/forms/base-input.vue";
import BaseSwitch from "@/components/forms/base-switch.vue";
import BaseInputPhone from "@/components/forms/base-input-phone/base-input-phone.vue";
import UpdateEmail from "@/components/page-account/update-email.vue";
import UpdateMobile from "@/components/page-account/update-mobile.vue";
import CustomText from "@/components/different/custom-text.vue";
import DatePeriod from "@/components/different/date-period.vue";
import PriceCurrency from "@/components/different/price-currency.vue";

export default {
  components: {
    PriceCurrency,
    DatePeriod,
    CustomText,
    UpdateMobile,
    UpdateEmail,
    BaseInputPhone,
    BaseSwitch,
    BaseInput
  },
  data() {
    return {
      text: '',
      placeholder: 'test',
      switchBoolean: false,
      phone: '',
      email: '',
      mobile: ''
    }
  },
  methods: {
    confirmEmail(data: any) {
      console.log(data)
      this.email = data
    },
    confirmMobile(data: any) {
      console.log(data)
      this.mobile = data
    }
  }
}

</script>

<style scoped lang="scss">
.test {
  margin: 40px;
}
</style>