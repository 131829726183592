import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59518e92"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked", "value"]
const _hoisted_2 = {
  key: 0,
  class: "vb-radio-fake"
}
const _hoisted_3 = { class: "vb-radio-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["vb-custom-radio", $options.isChecked ? 'custom-radio_checked' : ''])
  }, [
    _createElementVNode("input", _mergeProps(_ctx.$attrs, { type: "radio" }, {
      checked: $options.isChecked,
      value: $props.modelValue,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleChange && $options.handleChange(...args)))
    }), null, 16, _hoisted_1),
    (!$props.btnView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}