<template>
<template-base :show-breadcrumbs="false">
  <main
    ref="introEl"
    class="h-[calc(100vh-80px)] flex flex-col justify-end text-white"
    :style="introStyles"
  >
    <div class="flex flex-col text-center">
      <template v-if="pageTitle.title || pageTitle.subTitle">
        <h2 class="vb-h1 text-56px tablet:text-5xl mobile:text-title">{{ pageTitle.title }}</h2>
        <h3 v-html="pageTitle.subTitle" class="vb-intro_sub-title text-title tablet:text-2xl mobile:text-base" />
      </template>
    </div>

    <the-quick-booking ref="quickBooking" />
  </main>
</template-base>
</template>

<script lang="ts">
import TemplateBase from "@/pages/templates/template-base.vue";
import TheQuickBooking from "@/components/the-quick-booking.vue";
import { i_outSetupImage } from "visbook-api";

export default {
  components: {
    TheQuickBooking,
    TemplateBase,
  },
  data: () => ({
    introSize: {
      width: 0,
      height: 0,
    },
  }),
  mounted() {
    const introEl = this.$refs.introEl as HTMLDivElement;
    this.introSize.width = introEl.offsetWidth;
    this.introSize.height = introEl.offsetHeight;
  },
  computed: {
    setupInfo() {
      return (this.$store.state as any).mod_company.companySettings;
    },
    setupProperties() {
      return this.setupInfo?.properties;
    },
    pageTitle() {
      return {
        title: this.setupProperties?.description_short?.text,
        subTitle: this.setupProperties?.description_long?.text,
      };
    },
    introStyles() {
      let srcBg = "";
      if (this.setupInfo && this.setupInfo.images.length) {
        const companyBg = this.setupInfo.images.filter(
          (el: i_outSetupImage) => el.imageType === "background"
        );
        if (companyBg.length) {
          const transformer = companyBg[0].transformer;
          const positionUpload = transformer.indexOf("/visdoc");
          const cropperData = `/w_${this.introSize.width},h_${this.introSize.height},c_lfill,f_auto,g_auto:subject`;
          const url =
            positionUpload >= 0
              ? `${transformer.slice(
                  0,
                  positionUpload
                )}${cropperData}${transformer.slice(positionUpload)}`
              : transformer;
          srcBg = `${url}/${companyBg[0].imagePath}`;
        }
      }
      const bg = srcBg || require("@/assets/img/intro-bg.png");
      return {
        background: `linear-gradient(rgba(22,22,22,0.3), rgba(22,22,22,0.3)), url("${bg}") center/cover no-repeat`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base";
.vb-intro {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: $col-txt2;
  min-height: calc(100vh - 80px);
}
.vb-intro_head {
  flex: 1;
  flex-direction: column;
}
.vb-intro_title {
  white-space: pre-wrap;
  margin-bottom: 0;
}
h3.vb-intro_sub-title {
  margin-bottom: 3.5rem;
  margin-top: 0;
  font-weight: normal;
  white-space: pre-wrap;
  /deep/ a {
    color: inherit;
  }
}
.vb-intro_cta {
  border-radius: 0;
  backdrop-filter: blur(3px);
  min-width: 17rem;
}
</style>
