import { RequestError, out_terms } from "visbook-api";
import { requestVB } from "@/core/helpers";
import i18n from "@/i18n";
import { ActionContext } from "vuex";
import { app } from "@/main";

export interface i_storeCheckout {
  terms: string;
}

const namespaced = true;

const state: i_storeCheckout = {
  terms: "",
};

const getters = {};

const mutations = {
  set_terms(state: i_storeCheckout, val: string) {
    state.terms = val;
  },
};

const actions = {
  GET_Terms({ commit, state }: ActionContext<i_storeCheckout, any>) {
    if (state.terms) return;
    requestVB(app.config.globalProperties.$visbook.GET_terms, [
      {
        reservationId: 0,
        encryptedCompanyId: "string",
      },
    ])
      .then((txt: out_terms) => {
        const termsText = txt.terms.length
          ? txt.terms
          : (i18n.global.t("page-account_message_no-terms") as string);
        commit("set_terms", termsText);
      })
      .catch((err: RequestError) => {
        console.log("terms error", err);
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
