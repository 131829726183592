<template>
  <nav class="vb-the-menu">
    <ul class="vb-the-menu_list" v-if="showMyPage">
      <li v-if="isAuthorized || isLoginPage">
        <router-link
          v-if="isMyPage"
          :to="{name: 'home'}"
          class="vb-the-menu_link vb-btn vb-btn--txt vb-btn--my-page flex px-4 py-2 rounded-sm hover:bg-translucentAthensGray focus:outline-none"
        >
          <img src="@/components/forms/assets/Icons/M-size/user-white.svg" alt="">
          <span class="ml-1">{{$t('word_to_booking')}}</span>
        </router-link>
        <router-link v-else
          :to="{name: 'account'}"
          class="vb-the-menu_link vb-btn vb-btn--txt vb-btn--my-page flex px-4 py-2 rounded-sm hover:bg-translucentAthensGray focus:outline-none"
        >
          <img src="@/components/forms/assets/Icons/M-size/user-white.svg" alt="">
          <span class="ml-1">{{$t('word_my-page')}}</span>
        </router-link>
      </li>
      <li v-if="!isLoginPage && !isAuthorized">
        <button
          class="vb-the-menu_link vb-btn vb-btn--txt flex px-4 py-2 rounded-sm hover:bg-translucentAthensGray focus:outline-none"
          type="button"
          @click="openLogin"
        >
          {{$t('pg-title_login')}}
          <img src="@/components/forms/assets/Icons/M-size/logIn-white.svg" class="ml-2" alt="">
        </button>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { getEntityId } from "@/core/helpers";

export default {
  computed: {
    isAuthorized() {
      return this.$store.getters["mod_user/isAuthorized"];
    },
    isOpenMenu() {
      return this.$store.state.mod_globalView.isDisableScroll;
    },
    showMyPage() {
      return this.$store.state.mod_company?.companySettings?.enabledServices?.myPage;
    },
    myPageLink() {
      return `https://my-page.visbook.com/${getEntityId()}`;
    },
    isMyPage() {
      return ['orders', 'account', 'gdpr', 'delete', 'my-order', 'account-deleted', 'login'].includes(this.$route.name)
    },
    isLoginPage() {
      return this.$route.name === 'login';
    },
  },
  methods: {
    openLogin() {
      this.$router.push({
        name: "login",
        query: { ...this.$route.query },
        params: { ...this.$route.params },
      });
      // this.$store.dispatch('mod_globalView/OPEN_modal', 'login')
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/base";
.vb-the-menu_list {
  display: flex;
  margin: 0;
}
.vb-btn--my-page span {
  display: none;
}
.vb-tablet .vb-btn--my-page {
  span {
    display: inherit;
  }
  i.vb-icon {
    display: none;
  }
}
.vb-hide-for-rebuild-point {
  .vb-rebuild-point & {
    display: none;
  }
}
</style>
