import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "vb-room-head_img" }
const _hoisted_2 = ["onClick", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      navigation: true,
      modules: _ctx.modules
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.slideList, (img, index) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            key: img.src,
            class: "h-[180px] md:h-auto"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                onClick: ($event: any) => ($options.showLightbox(index)),
                src: img.src,
                alt: "room photo"
              }, null, 8, _hoisted_2)
            ], undefined, true),
            _: 2
          }, 1024))
        }), 128))
      ], undefined, true),
      _: 1
    }, 8, ["modules"]),
    _createVNode(_component_vue_easy_lightbox, {
      visible: _ctx.visibleRef,
      imgs: $options.lightBoxList,
      index: _ctx.slideIndex,
      onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visibleRef = false))
    }, null, 8, ["visible", "imgs", "index"])
  ]))
}