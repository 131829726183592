<template>
<div class="vb-header-cart" v-click-outside="closeList">
  <button
    class="flex items-center px-4 py-2 rounded-sm hover:bg-translucentAthensGray focus:outline-none"
    :title="cardTitle"
    type="button"
    @click.stop="display = !display"
  >
    <img src="@/components/forms/assets/Icons/M-size/shopping-bag.svg" class="mr-2" alt="shopping bag">
    <span class="lowercase">{{ cartList.length }} {{ cartList.length === 1 ? $t('word_item') : $t('word_items') }}</span>
  </button>

  <div class="vb-header-cart_drop-box text-sm" v-show="display" v-if="cartList.length">
    <ul class="vb-header-cart_list">
      <li class="vb-header-cart_item" v-for="item in cartList" :key="item.cartId">
        <div class="vb-cart-item_img" v-if="item.product.image">
          <img :src="item.product.image" />
        </div>
        <div class="vb-cart-item_main">
          <div class="vb-item-main_name">{{ item.product.unitName || item.product.name }}</div>
          <button
            class="vb-btn vb-btn--icon vb-icon vb-icon-reset"
            type="button"
            :title="$t('cart_mess_remove-reserve')"
            @click="removeCartItem(item)"
          ></button>
          <div class="vb-item-main_info">
            <i class="vb-icon vb-icon-date"></i>
            {{ reservationDateTime(item) }}
            <i class="vb-icon vb-icon-user"></i>
            {{ item.reservationsData.numberOfPeople }}
            <template v-if="item.product.type === 'accommodation'">
              <i class="vb-icon vb-icon-night"></i>
              {{ item.bookingMeta.nights }}
            </template>
          </div>
          <price-currency
            v-if="item.product.type !== 'restaurant'"
            :data-price="getItemPrice(item.bookingMeta)"
            :data-currency="itemCurrency"
          ></price-currency>
          <template v-if="getItemAdditionPrice(item.bookingMeta)">
            <div class="vb-item-main_info">
              <i class="vb-icon vb-icon-blank"></i>
              {{ $t('page-search_w-additionals') }}
            </div>
            <price-currency :data-price="getItemAdditionPrice(item.bookingMeta)" :data-currency="itemCurrency"></price-currency>
          </template>
        </div>
      </li>
    </ul>

    <div class="vb-header-cart_item" v-if="discount">
      <span>{{$t('cart-discount')}}:</span>
      <price-currency :data-price="discount" :data-currency="itemCurrency"></price-currency>
    </div>
    <div class="vb-header-cart_item">
      <span>{{$t('cart_total-mess', {count: cartList.length})}}:</span>
      <price-currency :data-price="cartTotalPrice" :data-currency="itemCurrency"></price-currency>
    </div>
    <div class="vb-header-cart_cta-box vb-header-cart_item vb-form_row">
      <div class="vb-form_holder">
        <button
          class="vb-btn vb-btn--outline vb-w-100"
          type="button"
          v-if="$route.name === 'checkout'"
          @click="toSearch"
        >{{$t('cart_btn_search')}}</button>
        <button
          class="vb-btn vb-btn--outline vb-w-100"
          type="button"
          v-else @click="display = false"
        >{{$t('cart_btn_continue')}}</button>
      </div>
      <div class="vb-form_holder">
        <button
          class="vb-btn vb-bg-primary vb-w-100"
          type="button" v-if="$route.name !== 'checkout'"
          @click="toCheckoutPage"
        >{{$t('cart_btn_checkout')}}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { i_reservation, RequestError } from "visbook-api";
import { i_cartItem } from "@/store/store";
import { getLocalCart } from "@/core/helpers";
import PriceCurrency from "@/components/different/price-currency.vue";

import { getSingleItemDetailsGTAG } from "@/store/gtags-helpers";
import { gtag_cart } from "@/store/gtags-helpers/gtags-interfaces";
import filters from "@/core/filters";

export default {
  components: {
    PriceCurrency,
  },
  created() {
    this.checkLocalCart();
  },
  beforeUnmount() {
    this.stopPing();
  },
  data: () => ({
    pingInterval: 0,
  }),
  computed: {
    display: {
      get(): boolean {
        return this.$store.getters["mod_cart/displayCard"];
      },
      set(value: boolean) {
        this.$store.dispatch("mod_cart/SET_displayCard", value);
      },
    },
    cartList(): i_cartItem[] {
      return this.$store.getters["mod_cart/selectedProducts"];
    },
    cartTotalPrice(): number {
      return this.$store.getters["mod_cart/cartTotalPrice"];
    },
    discount() {
      return 0;
    },
    itemCurrency() {
      return this.$store.getters["mod_company/companyCurrency"];
    },
    pingRequestList() {
      return this.$store.getters["mod_cart/cartRequestList"];
    },
    cardTitle() {
      return this.cartList.length + " " + this.$t("word_items");
    },
    companyInfo() {
      return this.$store.getters.getCompanyInfo;
    },
  },
  watch: {
    "cartList.length"(newLength: number) {
      newLength ? this.startPing() : this.stopPing();
    },
    display(val: boolean) {
      if (val) {
        const eventData: gtag_cart = {
          currency: this.itemCurrency,
          value: this.cartTotalPrice,
          items: [],
        };
        this.cartList.forEach((i: i_cartItem) => {
          const result = getSingleItemDetailsGTAG(i, this.companyInfo);
          eventData.items.push(result);
        });

        this.$gtag.event("view_cart", {
          ...eventData,
        });
      }
    },
  },
  methods: {
    startPing() {
      if (this.pingInterval) return;

      this.pingInterval = setInterval(() => {
        if (this.pingRequestList.length) {
          this.$visbook
            .PING_reservations(this.pingRequestList)
            .then((expiredList: any) => {
              if (expiredList) {
                const expiredIdList = expiredList.map(
                  (el: i_reservation) =>
                    el.reservationId.toString() + el.encryptedCompanyId
                );
                const expiredIdString = expiredList.reduce(
                  (idString: string, el: any) => {
                    return `${idString} ${el.reservationId.toString()}`;
                  },
                  ""
                );
                const filteredList = this.cartList.filter(
                  (el: any) => !expiredIdList.includes(el.cartId)
                );
                this.setCartList(filteredList);
                this.$store.dispatch("mod_globalView/SHOW_notification", {
                  message: this.$t("cart_mess_expired-list", {
                    list: expiredIdString,
                  }),
                  type: "warning",
                });
              }
            })
            .catch((err: RequestError) => {
              console.log("err", err);
            });
        }
      }, 20000);
    },
    stopPing() {
      clearInterval(this.pingInterval);
      this.pingInterval = 0;
    },
    closeList() {
      this.display = false;
    },
    removeCartItem(cartItem: i_cartItem) {
      this.$store.dispatch("mod_cart/REM_product", cartItem);
    },
    toSearch() {
      this.$store.dispatch("mod_search/BACK_toSearchPage");
      this.display = false;
    },
    toCheckoutPage() {
      this.$router
        .push({
          name: "checkout",
          params: { entity: this.$entity },
        })
        .catch((/*error: any*/) => {
          //??
        });
      this.display = false;
    },
    setCartList(list: i_cartItem[]) {
      this.$store.dispatch("mod_cart/SET_productList", list);
    },
    checkLocalCart() {
      const cart = getLocalCart();
      if (cart) this.setCartList(cart);
      this.startPing();
    },
    reservationDateTime(item: any) {
      if (item.product.type == "accommodation") { 
        return filters?.date(item.reservationsData.fromDate);
      }
      return `${filters?.date(item.reservationsData.fromDate)} ${filters?.time(item.reservationsData.fromDate)}`;
    },
    getItemPrice(bookingMeta: any) {
      return bookingMeta.priceSelected?.calculatedPrice ?? 0;
    },
    getItemAdditionPrice(bookingMeta: any) {
      return bookingMeta.additionalSelected.reduce(
        (sum: number, el: any) => sum + el.totalPrice,
        0
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/base";
$cart-item_height: 5rem;
$cart-item_gap: 10px;

.vb-header-cart {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.vb-cart-view_icon {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 18%;
    right: -0.1em;
    width: 0.35em;
    height: 0.35em;
    background-color: #ff3e3e;
    border-radius: 50%;
  }
}
.vb-header-cart_drop-box {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  min-width: 220px;
  background-color: $bg-page;
  color: $col-txt;
  border: 1px solid;
  box-shadow: $box-shad;
  .vb-mobile & {
    min-width: 300px;
  }
}
.vb-header-cart_list {
  overflow: auto;
  max-height: 50vh;
  border-bottom: 1px solid;
}
.vb-header-cart_item {
  display: flex;
  justify-content: space-between;
  padding: $cart-item_gap;
  border-bottom: 1px solid;
  &:last-child {
    border-bottom: none;
  }
}
.vb-cart-item_img {
  width: $cart-item_height;
  margin-right: $cart-item_gap;
  img {
    max-width: 100%;
    max-height: $cart-item_height;
  }
}
.vb-cart-item_main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
}
.vb-item-main_name,
.vb-item-main_info {
  flex: 1;
  min-width: 50%;
  .vb-icon {
    margin-right: 0.3em;
    margin-left: 1px;
    &.vb-icon-blank:before {
      content: "\E900";
      opacity: 0;
    }
  }
  .vb-icon + .vb-icon {
    margin-left: 0.5em;
  }
}
.vb-item-main_name {
  padding-right: $cart-item_gap;
  text-transform: uppercase;
  margin-bottom: $cart-item_gap;
}
.vb-cart-item_main > .vb-item-main_info {
  line-height: 1.5em;
}
> .vb-header-cart_cta-box {
  border-bottom: none;
}
.vb-header-cart_view.vb-btn {
  span {
    display: none;
  }
}
.vb-tablet {
  .vb-header-cart_view.vb-btn {
    span {
      display: inline;
    }
  }
}

.vb-header-cart_list {
  .vb-btn.vb-icon-reset {
    background: $black;
    color: $white;
    padding: 0.15em 0.1em;
    position: relative;
    border-radius: 0 4px 4px 0;
    margin-bottom: $cart-item_gap;
  }

  .vb-btn.vb-icon-reset:after {
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    top: 55%;
    transform: translateY(-52.5%);
    border-top: 0.6em solid transparent;
    border-right: 0.6em solid $black;
    border-bottom: 0.6em solid transparent;
    border-left: 0.6em solid transparent;
    height: 0;
    width: 0;
  }
}
</style>
