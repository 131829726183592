<template>
<div
  :class="dayCssClass"
  class="
    square h-12 tablet:h-16 mobile:h-16 font-medium
    hover:bg-athensGray hover:outline hover:outline-keppel hover:outline-offset-[-1px] hover:rounded
  "
  @mouseover='setHoveringDay'
>
  <div
    v-if='showTooltip && this.options.hoveringTooltip'
    v-html='tooltipMessageDisplay'
    class="datepicker__tooltip"
  ></div>
  <div
    @click.prevent.stop='dayClicked(date)'
    @keyup.enter.prevent.stop='dayClicked(date)'
    :style='isToday ? currentDateStyle : ""'
    :tabindex="tabIndex"
    ref="day"
    class="datepicker__month-day flex justify-center items-center h-12 tablet:h-16 mobile:h-16"
  >
    <span>{{ dayNumber }}</span>
  </div>
</div>
</template>

<script>
import fecha from "fecha";

import Helpers from "./helpers.js";
import { cloneDate } from "@/core/helpers";

export default {
  name: "DatePickerDay",

  emits: ["hovering-day", "day-clicked"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isUndefined: {
      type: Boolean,
      required: false,
    },
    sortedDisabledDates: {
      type: Array,
    },
    isAvailable: {
      type: Boolean,
    },
    availabilityReason: {
      type: String,
    },
    options: {
      type: Object,
    },
    checkIn: {
      type: Date,
    },
    checkOut: {
      type: Date,
    },
    hoveringDate: {
      type: Date,
    },
    mounseOverFunction: {
      type: Function,
    },
    belongsToThisMonth: {
      type: Boolean,
    },
    activeMonthIndex: {
      type: Number,
    },
    date: {
      type: Date,
    },
    nextDisabledDate: {
      type: [Date, Number, String],
    },
    hoveringTooltip: {
      default: true,
      type: Boolean,
    },
    tooltipMessage: {
      default: null,
      type: String,
    },
    currentDateStyle: {
      required: true,
    },
    showHalfDay: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isHighlighted: false,
      isDisabled: false,
      allowedCheckoutDays: [],
      currentDate: cloneDate(),
    };
  },

  computed: {
    dayNumber() {
      return this.getDay(this.date);
    },
    tabIndex() {
      if (
        !this.isOpen ||
        !this.belongsToThisMonth ||
        this.isDisabled ||
        !this.isClickable()
      ) {
        return -1;
      }
      return 0;
    },
    nightsCount() {
      return this.countDays(this.checkIn, this.hoveringDate);
    },
    tooltipMessageDisplay() {
      return this.tooltipMessage
        ? this.tooltipMessage
        : `${this.nightsCount} ${
            this.nightsCount !== 1
              ? this.options.i18n["nights"]
              : this.options.i18n["night"]
          }`;
    },
    showTooltip() {
      return (
        !this.isDisabled &&
        this.date == this.hoveringDate &&
        this.checkIn !== null &&
        this.checkOut == null
      );
    },

    isToday() {
      return this.compareDay(this.currentDate, this.date) == 0;
    },

    dayCssClass() {
      const classes = [];

      const c = this.dayClass;
      if (c) {
        classes.push(c);
      }

      if (this.isUndefined) {
        classes.push("datepicker__month-day--undefined");
      } else {
        if (this.belongsToThisMonth) {
          if (this.checkIn != null && this.checkOut == null) {
            if (this.isDisabled) {
              classes.push("datepicker__month-day--disabled");
              if (this.isInsideOfAllowedSelection) {
                classes.push("datepicker__month-day--stayover");
              }
            } else if (this.canCheckOut && !this.isAvailable && !this.isToday) {
              classes.push("datepicker__month-day--cancheckout");
            }
          } else {
            if (this.isDisabled) {
              classes.push("datepicker__month-day--disabled");
              if (this.isStayOver) {
                classes.push("datepicker__month-day--stayover");
              }
            }
            if (!this.isAvailable) {
              if (this.availabilityReason !== "stayOver") {
                classes.push("datepicker__month-day--nostayover");
              } else {
                classes.push("datepicker__month-day--notavailable");
              }
              if (this.canCheckOut && !this.isToday) {
                classes.push("datepicker__month-day--cancheckout");
              }
            }
          }
        }
      }

      return classes;
    },

    dayClass() {
      if (this.belongsToThisMonth) {
        // If the calendar has a minimum number of nights
        if (
          !this.isDisabled &&
          this.checkIn &&
          this.compareDay(this.date, this.checkIn) == 1 &&
          this.options.minNights > 0 &&
          this.compareDay(
            this.date,
            this.addDays(this.checkIn, this.options.minNights)
          ) == -1
        ) {
          return "datepicker__month-day--selected datepicker__month-day--out-of-range";
        }

        // If the calendar has allowed ranges
        if (this.options.allowedRanges.length !== 0) {
          if (
            !this.isDisabled &&
            this.checkIn !== null &&
            this.checkOut == null
          ) {
            // If the day is one of the allowed check out days and is not highlighted
            if (
              this.allowedCheckoutDays.some(
                (i) => this.compareDay(i, this.date) == 0 && !this.isHighlighted
              )
            ) {
              return "datepicker__month-day--allowed-checkout";
            }
            // If the day is one of the allowed check out days and is highlighted
            if (
              this.allowedCheckoutDays.some(
                (i) => this.compareDay(i, this.date) == 0 && this.isHighlighted
              )
            ) {
              return "datepicker__month-day--selected datepicker__month-day--allowed-checkout";
            }
            // If the day is not one of the allowed Checkout Days and is highlighted
            if (
              !this.allowedCheckoutDays.some(
                (i) => this.compareDay(i, this.date) == 0
              ) &&
              this.isHighlighted
            ) {
              return "datepicker__month-day--out-of-range datepicker__month-day--selected";
            } else {
              return "datepicker__month-day--out-of-range";
            }
          }
        }
        // Highlight the selected dates and prevent the user from selecting
        // the same date for checkout and checkin
        if (
          this.checkIn !== null &&
          fecha.format(this.checkIn, "YYYYMMDD") ==
            fecha.format(this.date, "YYYYMMDD")
        ) {
          return "datepicker__month-day--first-day-selected";
        }
        if (this.checkOut !== null) {
          if (
            fecha.format(this.checkOut, "YYYYMMDD") ==
            fecha.format(this.date, "YYYYMMDD")
          ) {
            return "datepicker__month-day--last-day-selected";
          }
        }
        // Only highlight dates that are not disabled
        if (this.isHighlighted) {
          return " datepicker__month-day--selected";
        }
      } else if (!this.belongsToThisMonth) {
        return "datepicker__month-day--hidden";
      } else {
        return "datepicker__month-day--valid";
      }
      return "";
    },

    canCheckOut() {
      if (this.isAvailable) {
        return true;
      }
      if (!this.isAvailable && this.availabilityReason === "stayOver") {
        return false;
      }
      if (!this.isAvailable && !this.showHalfDay) {
        return false;
      }
      const prevDate = fecha.format(this.addDays(this.date, -1), "YYYY-MM-DD");
      if (
        this.options.startDate &&
        fecha.format(this.options.startDate, "YYYY-MM-DD") > prevDate
      ) {
        return false;
      }
      return !this.options.disabledDates.some(
        (date) => date.date == prevDate && date.reason !== "stayOver"
      );
    },

    isInsideOfAllowedSelection() {
      if (this.checkIn && !this.checkOut) {
        if (this.compareDay(this.date, this.checkIn) != 1) {
          // current day is before or equal checkIn
          return false;
        }
        if (
          this.nextDisabledDate instanceof Date &&
          this.compareDay(this.date, this.nextDisabledDate) == 1
        ) {
          // current day is after last possible checkOut
          return false;
        }
        return true;
      }
      return false;
    },

    isStayOver() {
      return this.isDisabled && this.availabilityReason == "stayOver";
    },
  },

  watch: {
    isOpen(newVal) {
      if (!newVal) this.isDisabled = false;
    },
    hoveringDate() {
      if (this.checkIn !== null && this.checkOut == null) {
        this.isDateLessOrEquals(this.checkIn, this.date) &&
        this.isDateLessOrEquals(this.date, this.hoveringDate)
          ? (this.isHighlighted = true)
          : (this.isHighlighted = false);
      }
      // if (
      //   this.checkIn !== null &&
      //   this.checkOut == null &&
      //   this.allowedCheckoutDays.length !== 0
      // ) {
      // }
    },
    activeMonthIndex() {
      this.checkIfDisabled();
      this.checkIfHighlighted();
      if (this.checkIn !== null && this.checkOut !== null) {
        this.isDateLessOrEquals(this.checkIn, this.date) &&
        this.isDateLessOrEquals(this.date, this.checkOut)
          ? (this.isHighlighted = true)
          : (this.isHighlighted = false);
      } else if (this.checkIn !== null && this.checkOut == null) {
        this.disableNextDays();
      } else {
        return;
      }
    },
    nextDisabledDate() {
      this.disableNextDays();
    },
    checkIn(date) {
      this.createAllowedCheckoutDays(date);
    },
    // dayClass(className) {
    //   const classList = className.split(' ');
    //   console.log(classList);
    //   if(classList.includes('datepicker__month-day--disabled')) this.$emit()
    // }
    sortedDisabledDates() {
      this.checkIfDisabled();
    },
  },

  methods: {
    ...Helpers,

    setHoveringDay() {
      this.$emit("hovering-day");
    },
    isClickable() {
      if (this.$refs && this.$refs.day) {
        return getComputedStyle(this.$refs.day).pointerEvents !== "none";
      } else {
        return true;
      }
    },

    compareDay(day1, day2) {
      const date1 = fecha.format(cloneDate(day1), "YYYYMMDD");
      const date2 = fecha.format(cloneDate(day2), "YYYYMMDD");

      if (date1 > date2) {
        return 1;
      } else if (date1 == date2) {
        return 0;
      } else if (date1 < date2) {
        return -1;
      }
    },

    dayClicked(date) {
      if (this.isDisabled || !this.isClickable()) {
        return;
      } else {
        if (this.options.allowedRanges.length !== 0) {
          this.createAllowedCheckoutDays(date);
        }

        let nextDisabledDate =
          this.allowedCheckoutDays[this.allowedCheckoutDays.length - 1] ||
          this.getLastAllowedDate() ||
          this.nextDateByDayOfWeekArray(
            this.options.disabledDaysOfWeek,
            this.date
          ) ||
          Infinity;

        if (this.options.maxNights) {
          nextDisabledDate = cloneDate(
            Math.min(
              nextDisabledDate,
              this.addDays(this.date, this.options.maxNights)
            )
          );
        }
        if (this.options.endDate && this.options.endDate instanceof Date) {
          nextDisabledDate = cloneDate(
            Math.min(nextDisabledDate, this.options.endDate)
          );
        }

        if (this.options.enableCheckout) {
          nextDisabledDate = Infinity;
        }

        if (nextDisabledDate instanceof Date) {
          const dateObj = cloneDate(date);
          while (nextDisabledDate > dateObj) {
            if ("stayOver" == this.getDisabledDateReason(nextDisabledDate)) {
              nextDisabledDate = cloneDate(
                new Date(
                  nextDisabledDate.getFullYear(),
                  nextDisabledDate.getMonth(),
                  nextDisabledDate.getDate() - 1
                )
              );
              continue;
            }
            break;
          }
          if (false == nextDisabledDate >= dateObj) {
            return;
          }
        }

        this.$emit("day-clicked", { date, nextDisabledDate });
      }
    },

    getLastAllowedDate() {
      if (!this.options.disabledDates.length) {
        return null;
      }

      let nextDate = null;
      let date = this.date;
      do {
        nextDate = this.getNextDate(this.sortedDisabledDates, date);
        if (!nextDate) break;
        if('stayOver' !== this.getDisabledDateReason(nextDate)) {
          if (!(this.getDisabledDateReason(nextDate) === 'blockingLengthException' && nextDate.getTime() <= this.addDays(date, this.options.minNights).getTime())) {
            return nextDate;
          }
        }
      } while ((date = this.addDays(nextDate, 1)));

      return null;
    },

    getDisabledDateReason(date) {
      let reason = "";
      this.options.disabledDates.some(function (d) {
        if (this.compareDay(d.date, date) === 0) {
          reason = d.reason;
          return true;
        }
        return false;
      }, this);
      return reason;
    },

    compareEndDay() {
      if (this.options.endDate !== Infinity) {
        return this.compareDay(this.date, this.options.endDate) == 1;
      }
    },

    checkIfDisabled() {
      this.isDisabled =
        // If this day is equal any of the disabled dates
        (this.sortedDisabledDates
          ? this.sortedDisabledDates.some(
              (i) => this.compareDay(i, this.date) == 0
            )
          : null) ||
        // Or is before the start date
        this.compareDay(this.date, this.options.startDate) == -1 ||
        // Or is after the end date
        this.compareEndDay() ||
        // Or is in one of the disabled days of the week
        this.options.disabledDaysOfWeek.some(
          (i) => i == fecha.format(this.date, "dddd")
        );
      // Scroll monthes when checkIn is already set but checkOut is not
      if (this.checkIn && !this.checkOut) {
        if (this.compareDay(this.date, this.checkIn) == -1) {
          // disable all days before checkIn
          this.isDisabled = true;
        } else if (this.nextDisabledDate instanceof Date) {
          if (this.compareDay(this.date, this.nextDisabledDate) == 0) {
            // last allowed day - unlock it
            this.isDisabled = false;
          } else if (this.compareDay(this.date, this.nextDisabledDate) == 1) {
            // disable all days after nextDisabledDate
            this.isDisabled = true;
          }
        }
        if (this.hoveringDate instanceof Date) {
          this.isHighlighted =
            this.isDateLessOrEquals(this.checkIn, this.date) &&
            this.isDateLessOrEquals(this.date, this.hoveringDate)
              ? true
              : false;
        }
      }
      // Handle checkout enabled
      if (this.options.enableCheckout) {
        if (
          this.compareDay(this.date, this.checkIn) == 1 &&
          this.compareDay(this.date, this.checkOut) == -1
        ) {
          this.isDisabled = false;
        }
      }
    },

    checkIfHighlighted() {
      if (this.checkIn !== null && this.checkOut !== null) {
        this.isDateLessOrEquals(this.checkIn, this.date) &&
        this.isDateLessOrEquals(this.date, this.checkOut)
          ? (this.isHighlighted = true)
          : (this.isHighlighted = false);
      }
    },

    createAllowedCheckoutDays(date) {
      this.allowedCheckoutDays = [];
      this.options.allowedRanges.forEach((i) =>
        this.allowedCheckoutDays.push(this.addDays(date, i))
      );
      this.allowedCheckoutDays.sort((a, b) => a - b);
    },

    disableNextDays() {
      if (
        !this.isDateLessOrEquals(this.date, this.nextDisabledDate) &&
        this.nextDisabledDate !== Infinity
      ) {
        this.isDisabled = true;
      } else if (this.isDateLessOrEquals(this.date, this.checkIn)) {
        this.isDisabled = true;
      }
      if (
        this.compareDay(this.date, this.checkIn) == 0 &&
        this.options.minNights == 0
      ) {
        this.isDisabled = false;
      }
      if (
        this.isDateLessOrEquals(this.checkIn, this.date) &&
        this.options.enableCheckout
      ) {
        this.isDisabled = false;
      } else {
        return;
      }
    },

    setEnabled() {
      this.isDisabled = false;
    },
  },

  beforeMount() {
    this.checkIfDisabled();
    this.checkIfHighlighted();
  },

  mounted() {
    this.$emitter.on("enable-date-" + fecha.format(this.date, "YYYYMMDD"), () =>
      this.setEnabled()
    );
  },
};
</script>
