import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "vb-btn",
    href: $props.to,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($options.toLink()), ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}