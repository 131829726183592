<template>
  <router-link :to="{name: 'home'}" class="vb-header-main_logo vb-ellipsis flex items-center">
    <img
      v-if="logoImg"
      :alt="logoNameAlt"
      :title="logoName"
      :src="logoImg"
      class="vb-header-main_logo-img"
    >
    <h1 class="vb-header-main_logo-name">{{` ${logoName}`}}</h1>
  </router-link>
</template>

<script lang="ts">
import { ResourceUrlPrefix, ResourceImgParams } from "@/config";

export default {
  computed: {
    companyInfo() {
      return this.$store.state.mod_company.companyInfo;
    },
    setupInfo() {
      return this.$store.state.mod_company.companySettings;
    },
    logoImg() {
      if (!this.setupInfo?.images?.length) return "";
      let url, path;
      const miniLogo = this.setupInfo.images.find(
        (el: any) => el.imageType === "miniLogo"
      );
      const logo = this.setupInfo.images.find(
        (el: any) => el.imageType === "logo"
      );
      if (miniLogo || logo) {
        if (miniLogo && miniLogo.transformer && miniLogo.imagePath) {
          url = miniLogo.transformer;
          path = miniLogo.imagePath;
        } else {
          url = logo.transformer;
          path = logo.imagePath;
        }
        if (url.startsWith(ResourceUrlPrefix)) {
          url =
            ResourceUrlPrefix +
            ResourceImgParams +
            url.slice(ResourceUrlPrefix.length);
        }
        return `${url}/${path}`;
      }
      return "";
    },
    logoName(): string {
      return this.setupInfo?.name ?? "";
    },
    logoNameAlt() {
      return this.logoName.substr(0, 20);
    },
  },
};
</script>

<style lang="scss">
@import "../scss/base";
.vb-header-main_logo {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  margin-right: 1em;
}
.vb-header-main_logo-img {
  object-fit: contain;
  height: $header-main-height - 1rem;
  margin-right: 1rem;
}
.vb-header-main_logo-name {
  display: none;
  font-size: inherit;
  font-weight: normal;
}
.vb-tablet {
  .vb-header-main_logo-name {
    display: inline;
  }
}
</style>
