<template>
<div class="vb-form_row vb-row--ai-b">
  <div v-if="showDateSelect" class="vb-form_holder">
    <div class="vb-form_label">{{ $t('word_date') }}</div>

    <base-select
      v-model:model-value="selectedDate"
      :disabled="disableAll"
      :options="activeDateList"
      :searchable="false"
      :allowEmpty="false"
      :multiple="false"
      :showLabels="false"
      :hide-one="true"
    />
  </div>

  <product-select-people
    v-model:model-value="selectedPerson"
    :disabled="disablePeople"
    :options="selectPeopleOptions"
    :for-activity="true"
    class="vb-w-50"
  />

  <div class="vb-form_holder">
    <div class="vb-form_label">{{ $t('word_time') }}</div>

    <base-select
      :class="{'restaurant-time-selector': itemData.type === 'restaurant'}"
      v-model:model-value="lazySelectedTime"
      :disabled="disableAll"
      :options="activeTimeList"
      groups
      group-label="duration"
      group-options="times"
      :group-select="false"
      label="name"
      valueProp="name"
      track-by="name"
      :searchable="false"
      :allowEmpty="false"
      :multiple="false"
      :showLabels="false"
      :hide-one="true"
      class="vb-form-time-selector vb-text-right"
    />
  </div>

  <div v-if="itemData.type !== 'restaurant'" class="vb-form_holder vb-w-50 vb-form_price">
    <div class="vb-form_label">{{ $t('word_price') }}</div>

    <Multiselect
      v-model:model-value="lazySelectedPrice"
      :disabled="disableAll"
      :options="activePriceList"
      :searchable="false"
      :allowEmpty="false"
      :multiple="false"
      :showLabels="false"
      :hide-one="true"
      valueProp="id"
      :canClear="false"
      track-by="calculatedPrice"
    >
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label w-full">
          <span class="flex justify-between w-full">
            <span class="vb-form_price-name">{{ value.name }}</span>
            <span class="vb-form_price-value">{{ selectedPrice?.priceName }}</span>
          </span>
        </div>
      </template>
      <template v-slot:option="{ option }">
        <span class="flex justify-between w-full">
          <span class="vb-form_price-name">{{ option.name }}</span>
          <span class="vb-form_price-value">{{ option.priceName }}</span>
        </span>
      </template>
    </Multiselect>
  </div>
</div>
</template>

<script lang="ts">
import ProductSelectPeople from "@/components/product-cart/product-select-people.vue";
import { i_product } from "@/store/store";
import { i_productPriceInfo, i_checkinPeriod } from "@/core/declarations";
import {
  getDuration,
  convertToDateFormat,
  toDateFormat,
  toTimeFormat,
} from "@/core/helpers";
import Multiselect from "@vueform/multiselect";

import { PropType } from "vue";

interface i_timeList {
  time: string;
  from: string;
  to: string;
  disabled: boolean;
  activity: i_productPriceInfo[];
}
interface i_dateList {
  date: string;
  from: string;
  to: string;
  duration: number;
  timeList: i_timeList[];
}
interface i_timeSelect {
  name: string;
  $isDisabled: boolean;
  dateFrom: string;
  dateTo: string;
  duration: number;
  timeFrom: string;
  timeTo: string;
  activityInfoIndex: number;
  dateIndex: number;
  availableUnits?: number;
}

interface i_timeSelectOption {
  duration: number | string;
  times: i_timeSelect[];
}

interface Data {
  selectedPrice: i_productPriceInfo | null;
}

export default {
  components: {
    ProductSelectPeople,
    Multiselect
  },
  emits: ["update-pricelist-count", "update-activity"],
  props: {
    itemData: {
      type: Object as PropType<i_product>,
      default: () => null,
    },
    configurationOk: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    selectPeopleOptions: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    selectPeopleValue: {},
  },
  data: () =>
    ({
      selectedPerson: 1,
      selectedDate: "",
      selectedTime: null,
      lazySelectedTime: null,
      selectedPrice: null,
      lazySelectedPrice: 2083
    } as Data),
  created() {
    this.selectedPerson = this.selectPeopleValue;
  },
  computed: {
    searchDates(): i_checkinPeriod {
      return this.$store.getters["mod_search/GET_searchDates"];
    },
    disableAll() {
      return !this.itemData.availability.available;
    },
    disablePeople() {
      return (
        !this.configurationOk ||
        (this.disableAll &&
          this.itemData?.availability.reason !== "noPricesFound")
      );
    },
    dateStartsWithYear() {
      const dateFrom = this.activeTimeList[0].times[0].dateFrom.match(/\d+/g);
      return dateFrom ? dateFrom[0].length === 4 ? true : false : false;
    },
    itemCurrency() {
      return this.$store.getters["mod_company/companyCurrency"];
    },
    activityInfo() {
      const priceList = this.itemData.prices;
      const availableList = this.itemData.availability.steps;
      /* allPrices */
      const allPrices: i_productPriceInfo[] = [];
      priceList.forEach(({ id, name, steps, calculatedPrice }: any) => {
        steps.forEach((days: any) => {
          allPrices.push({
            ...days,
            id,
            name,
            priceName: `${calculatedPrice.toFixed(2)} ${this.itemCurrency}`,
            calculatedPrice,
          });
        });
      });
      /* allPrices + availableUnits */
      const allPricesAvail = allPrices.map((price) => {
        const available = availableList.find(
          (av: any) => `${av.from}${av.to}` === `${price.from}${price.to}`
        );
        return {
          ...price,
          availableUnits: available?.availableUnits ?? 0,
        };
      }); //TODO refactoring to object modal
      /* array modal */ const dateInfo: i_dateList[] = [];
      allPricesAvail.forEach((el) => {
        const dateKey = createDateInfo(el.from, el.to);
        const dateTime = createTimeInfo(el.from, el.to);
        const currDateIndex = dateInfo.findIndex(
          (date) => date.date === dateKey
        );
        if (currDateIndex >= 0) {
          const currTimeList = dateInfo[currDateIndex].timeList;
          const currTimeIndex = currTimeList.findIndex(
            (date) => date.time === dateTime
          );
          if (currTimeIndex >= 0) {
            currTimeList[currTimeIndex].activity.push(el);
          } else {
            currTimeList.push({
              time: dateTime,
              from: getTime(el.from),
              to: getTime(el.to),
              disabled: !el.availableUnits,
              activity: [el],
            });
          }
        } else {
          dateInfo.push({
            date: dateKey,
            from: getDate(el.from),
            to: getDate(el.to),
            duration: getDuration(
              convertToDateFormat(el.from),
              convertToDateFormat(el.to)
            ),
            timeList: [
              {
                time: dateTime,
                from: getTime(el.from),
                to: getTime(el.to),
                disabled: !el.availableUnits,
                activity: [el],
              },
            ],
          });
        }
      });
      return dateInfo;

      function createDateInfo(from: string, to: string) {
        const dateFrom = toDateFormat(from);
        const dateTo = toDateFormat(to);
        return dateFrom === dateTo ? dateFrom : `${dateFrom}-${dateTo}`;
      }
      function createTimeInfo(from: string, to: string) {
        return `${toTimeFormat(from)}-${toTimeFormat(to)}`;
      }
      function getDate(date: string) {
        return toDateFormat(date);
      }
      function getTime(time: string) {
        return toTimeFormat(time);
      }
    },
    activeDateList() {
      if (!this.activityInfo.length) return [];
      return Array.from(new Set(this.activityInfo.map((el: any) => el.from)));
    },
    dateIndex() {
      if (!this.selectedTime) return 0;
      return this.selectedTime.dateIndex;
    },
    activeTimeList() {
      if (!this.selectedDate) return [];
      if (!this.activityInfo[this.dateIndex]) return [];
      const timeList: i_timeSelectOption[] = [];
      this.activityInfo.forEach((d: any, index: any) => {
        if (d.date.startsWith(this.selectedDate)) {
          d.timeList.forEach((t: any) => {
            if (!timeList.some((e) => e.duration === d.duration)) {
              timeList.push({
                duration: d.duration,
                times: [],
              });
            }
            const option = timeList.find((e) => e.duration === d.duration);
            option?.times.push({
              dateIndex: index,
              name: this.getTimeLabel(t, d),
              $isDisabled: t.disabled,
              dateFrom: d.from,
              dateTo: d.to,
              duration: d.duration,
              timeFrom: t.from,
              timeTo: t.to,
              activityInfoIndex: index,
              availableUnits: t.activity[0].availableUnits,
            });
          });
        }
      });

      timeList.sort((a, b) => {
        if (a.duration < b.duration) {
          return -1;
        }
        if (a.duration > b.duration) {
          return 1;
        }
        return 0;
      });

      timeList.forEach((t) => {
        t.duration += " " + this.$t("timelist-days", t.duration as number);
        if (this.itemData.type === "restaurant") {
          t.times = t.times.filter((i) => {
            if (i.availableUnits) {
              if (+i.availableUnits >= +this.selectedPerson) {
                return i;
              }
            } else {
              return i;
            }
          });
        }
        t.times.sort((a, b) => {
          if (a.timeFrom < b.timeFrom) {
            return -1;
          }
          if (a.timeFrom > b.timeFrom) {
            return 1;
          }
          if (a.timeTo < b.timeTo) {
            return -1;
          }
          if (a.timeTo > b.timeTo) {
            return 1;
          }
          return 0;
        });
      });

      if (
        !this.selectedTime ||
        !timeList.some((g) =>
          g.times.some(
            (t) =>
              t.name === this.selectedTime?.name &&
              t.dateFrom === this.selectedTime.dateFrom &&
              t.dateTo === this.selectedTime.dateTo
          )
        )
      ) {
        if (
          timeList.every((g) => {
            const t = g.times.find((t) => !t.$isDisabled);
            if (t) {
              this.selectedTime = t;
              return false; // selectedTime is set - stop it
            }
            return true; // continue to search for selectedTime
          })
        ) {
          // selectedTime is not set, let's take a first one
          timeList.every((g) => {
            const t = g.times.find(() => true);
            if (t) {
              this.selectedTime = t;
              return false; // selectedTime is set - stop it
            }
            return true; // continue to search for selectedTime
          });
        }
      }
      return timeList;
    },
    timeIndex() {
      const index = this.activityInfo[this.dateIndex].timeList.findIndex(
        (el: any) =>
          el.from === this.selectedTime?.timeFrom &&
          el.to === this.selectedTime?.timeTo
      );
      return index >= 0 ? index : 0;
    },
    activePriceList() {
      if (!this.selectedTime || !this.activityInfo[this.dateIndex]) {
        return [];
      }
      return this.activityInfo[this.dateIndex].timeList[this.timeIndex].activity.map((price: any) => ({
        ...price,
        namePrice: `${price.calculatedPrice} ${this.itemCurrency}`
      }));
    },
    priceIndex() {
      if (!this.activityInfo.length) return 0;
      const index = this.activityInfo[this.dateIndex].timeList[
        this.timeIndex
      ].activity.findIndex((el: any) => el.id === this.selectedPrice?.id);
      return index >= 0 ? index : 0;
    },
    selectedActivity() {
      if (!this.selectedPrice || !this.activityInfo.length) return null;
      return this.activityInfo[this.dateIndex].timeList[this.timeIndex]
        .activity[this.priceIndex];
    },
    showDateSelect() {
      const start = convertToDateFormat(this.searchDates.checkIn);
      const end = convertToDateFormat(this.searchDates.checkOut);
      const hide = start === end;
      if (start && hide) {
        this.activeDateList;
        return false;
      }
      return true;
    },
  },
  watch: {
    selectedPrice(price: any) {
      if (price && price.id !== this.lazySelectedPrice) this.lazySelectedPrice = price.id;
    },
    selectedTime(time: any){
      if (time?.name !== this.lazySelectedTime) this.lazySelectedTime = time?.name;
    },
    lazySelectedTime(lazyTime: any) {
      const matchedTime = this.activeTimeList
        .flatMap((day: any) => day.times)
        .find((time: any) => time.name === lazyTime);
      if (matchedTime && this.selectedTime !== matchedTime) {
        this.selectedTime = matchedTime;
      }
    },
    activeDateList(newDateList: any) {
      if (
        !this.selectedDate ||
        !newDateList.includes(this.selectedDate)
      ) {
        this.updateSelectedDate(newDateList[0]);
      }
    },
    selectedPerson() {
      this.$emit("update-activity", {
        selectedPrice: this.selectedActivity,
        selectedPerson: this.selectedPerson,
      });
    },
    selectedActivity() {
      this.$emit("update-activity", {
        selectedPrice: this.selectedActivity,
        selectedPerson: this.selectedPerson,
      });
    },
    "itemData.type": {
      handler() {
        if (
          this.itemData.type === "restaurant" &&
          this.activePriceList.length > 0
        ) {
          this.selectedPrice = this.activePriceList[0];
        }
      },
      immediate: true,
    },
    activePriceList(newPriceList: any) {
      if (
        !this.selectedPrice ||
        !newPriceList.some(
          (el: any) =>
            el.id === this.selectedPrice?.id &&
            el.calculatedPrice === this.selectedPrice?.calculatedPrice
        )
      ) {
        this.updateSelectedPrice(newPriceList[0]);
      }
      this.$emit("update-pricelist-count", newPriceList.length);
    },
  },
  methods: {
    getTimeLabel(times: i_timeList, dates: i_dateList): string {
      if (dates.from !== dates.to) {
        return (
          dates.from + " " + times.from + " ➔ " + dates.to + " " + times.to
        );
      }
      return times.from + " ➔ " + times.to;
    },
    updateSelectedDate(value: any) {
      this.selectedDate = value;
    },
    updateSelectedPrice(value: any) {
      this.selectedPrice = value;
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/base";
.vb-form-time-selector {
  /deep/ {
    .multiselect__option--disabled.multiselect__option:before {
      content: none;
    }
    .multiselect__option--disabled {
      color: $black !important;
      background: none !important;
      font-weight: bold;
    }
  }
}
.vb-w-50.vb-form_price {
  width: 100%;
  .vb-tablet & {
    width: calc(100% / 2);
  }
  /deep/ {
    .multiselect__option {
      &::before {
        position: absolute;
      }
      .vb-form_price-container {
        padding-left: 19px;
      }
    }
  }
}
.disabledoption {
  opacity: 0.5;
}
.vb-form_price-container {
  display: flex;
}
.vb-form_price-name {
  flex: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.vb-form_price-value {
  flex: none;
}
</style>
