<template>
  <div class="vb-dates small-text" v-if="hasData">
    <i class="vb-icon vb-icon-date"></i>
    <time class="vb-dates_day" :datetime="dataTo">
      {{ fromDate }}
      <span v-if="!sameDay"> - {{ toDate }}</span>
    </time>
    <span class="vb-dates_time" v-if="sameDay">
      <i class="vb-icon vb-icon-time"></i>
      <span>{{ fromTime }}</span>
      <span v-if="!sameTime && !hideEndTime"> - {{ toTime }}</span>
    </span>
  </div>
</template>
  
<script lang="ts">
import { toDateFormat, toTimeFormat } from "@/core/helpers";

export default {
  name: "DatePeriod",
  props: {
    hideEndTime: {
      type: Boolean,
      default: false,
    },
    dataFrom: {
      type: String,
      required: true,
    },
    dataTo: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasData() {
      return this.dataFrom && this.dataTo;
    },
    sameDay() {
      if (!this.hasData) return false;
      return toDateFormat(this.dataFrom) === toDateFormat(this.dataTo);
    },
    sameTime() {
      if (!this.hasData) return false;
      return toTimeFormat(this.dataFrom) === toTimeFormat(this.dataTo);
    },
    fromDate() {
      return toDateFormat(this.dataFrom);
    },
    toDate() {
      return toDateFormat(this.dataTo);
    },
    fromTime() {
      return toTimeFormat(this.dataFrom);
    },
    toTime() {
      return toTimeFormat(this.dataTo);
    },
  },
};
</script>

<style lang="scss" scoped>
.vb-dates {
  .vb-icon {
    margin-right: 0.3em;
  }
}
.vb-dates_day {
  margin-right: 10px;
}
.vb-dates_time {
  display: inline-block;
  white-space: nowrap;
}
</style>
