<template>
<div
  v-if="show"
  v-click-outside='clickOutside'
  @blur="clickOutside"
  class="w-full h-20"
>
  <slot name="opener" :is-open="isOpen" :set-is-open="setDatepickerVisible">
    <date-picker-opener
      :check-in="checkInText"
      :check-out="checkOutText"
      :is-open="isOpen"
      :disabled="disabled"
      :single-day-selection="singleDaySelection"
      :display-clear-button="displayClearButton"
      :i18n="i18n"
      @clear-selection="clearSelection"
      @set-is-open="setDatepickerVisible($event)"
    />
  </slot>

  <div
    v-if="isOpen"
    class="
      datepicker-wrapper z-[99] rounded overflow-hidden pb-10 w-[min(80vw,886px)] fixed left-[14%] bottom-[25%] bg-white
      tablet:w-full tablet:left-0 tablet:right-0 tablet:top-0 tablet:bottom-0 tablet:rounded-none tablet:overflow-auto
      mobile:w-full mobile:left-0 mobile:right-0 mobile:top-0 mobile:bottom-0 mobile:rounded-none mobile:overflow-auto
    "
  >
    <div class="bg-white">
      <div class="flex items-center justify-between bg-gray-50 text-black mb-10">
        <p class="font-semibold ml-10 py-4">
          {{ $t('quick-booking_select_date') }}
        </p>

        <button class="hidden tablet:flex mobile:flex mr-4" @click.prevent="hideDatepicker">
          <img src="@/components/forms/assets/Icons/M-size/x.svg" alt="">
        </button>
      </div>
    </div>

    <div
      id="#swiperWrapper"
      class="w-full px-10 flex gap-10 tablet:flex-col tablet:px-4 mobile:flex-col mobile:px-4"
      :class="{'datepicker--show-overlay': showOverlay }"
    >
      <the-request-loader v-if="showOverlay" />
      <div v-for='n in [0,1]' :key='n'>
        <div v-if="n === 0" class="flex items-center text-black">
          <button
            type="button"
            :disabled="!activeMonthIndex"
            @click="renderPreviousMonth"
            @keyup.enter.stop.prevent="renderPreviousMonth"
            :tabindex='isOpen ? 0 : -1'
            class="flex justify-center items-center border border-solid border-gray-300 rounded min-w-10 max-w-10 h-10"
          >
            <img src="@/components/forms/assets/Icons/M-size/chevron-left.svg" :class="activeMonthIndex ? '' : 'opacity-50'">
          </button>
          <div class="w-full text-center ml-2 font-medium tablet:mr-12 mobile:mr-12">{{ currentMonthText }}</div>
        </div>
        <div v-else-if="n === 1" class="flex items-center justify-end text-black">
          <div class="w-full text-center mr-2 font-medium tablet:ml-12 mobile:ml-12">{{ nextMonthText }}</div>
          <button
            type="button"
            :disabled="isLastMonth"
            @click="renderNextMonth"
            @keyup.enter.stop.prevent="renderNextMonth"
            :tabindex='isOpen ? 0 : -1'
            class="flex justify-center items-center border border-solid border-gray-300 rounded min-w-10 max-w-10 h-10 ml-2"
          >
            <img src="@/components/forms/assets/Icons/M-size/chevron-right.svg" :class="isLastMonth ? 'opacity-50' : ''">
          </button>
        </div>

        <div class="flex text-gray-500 h-10">
          <div class="datepicker__week-name">{{ $t('date-picker_week') }}</div>
          <div
            v-for='dayName in i18n["day-names"]'
            :key="dayName"
            class="datepicker__week-name"
          >{{ dayName }}</div>
        </div>

        <div class="flex">
          <DatePickerWeeks :dayList="months[activeMonthIndex+n].days" />

          <div class="max-w-[87.5%] min-w-[87.5%]">
            <Day
              v-for='day in months[activeMonthIndex+n].days'
              :key="day.date.toString()"
              :is-open="isOpen"
              :is-undefined="showOverlay"
              :options="$props"
              @day-clicked='handleDayClick($event)'

              :date='day.date'
              :belongsToThisMonth='day.belongsToThisMonth'
              :sortedDisabledDates='sortedDisabledDates'
              :nextDisabledDate='nextDisabledDate'
              :activeMonthIndex='activeMonthIndex'
              :tooltipMessage='tooltipMessage'

              :isAvailable='isDayAvailable(day)'
              :availabilityReason='getAvailabilityReason(day)'

              :hoveringDate='hoveringDate'
              @hovering-day="hoveringDate = day.date"

              :checkIn='checkIn'
              :checkOut='checkOut'
              :currentDateStyle='currentDateStyle'
              :show-half-day="showHalfDay"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
// used from https://github.com/krystalcampioni/vue-hotel-datepicker#readme
import i18n from "@/i18n";
import {
  throttleEv,
  convertToDateFormat,
  formatDateRule,
  setDay,
  cloneDate,
} from "@/core/helpers";
import fecha from "fecha";

import Day from "./DatePickerDay.vue";
import DatePickerOpener from "./DateOpener.vue";
import Helpers from "./helpers.js";
import DatePickerWeeks from "./DatePickerWeeks.vue";
import TheRequestLoader from "@/components/the-request-loader.vue";

import { PropType } from "vue";

const defaulti18n = {
  night: "Night",
  nights: "Nights",
  "day-names": ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
  "check-in": i18n.global.t("word_checkIn"),
  "check-out": i18n.global.t("word_checkOut"),
  "month-names": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};

export default {
  name: "BaseDatePicker",

  components: {
    DatePickerOpener,
    DatePickerWeeks,
    Day,
    TheRequestLoader,
  },

  props: {
    value: { type: String },
    startingShowDate: { default: null, type: Date },
    startingDateValue: { default: null, type: Date },
    endingDateValue: { default: null, type: Date },

    startDate: { default: () => cloneDate(), type: [Date, String] },
    endDate: { default: Infinity, type: [Date, String, Number] },
    firstDayOfWeek: { default: 0, type: Number },

    minNights: { default: 1, type: Number },
    maxNights: { default: null, type: Number },

    currentDateStyle: { default: () => ({ border: "1px solid #00c690" }) },
    format: { default: "YYYY-MM-DD", type: String },
    i18n: { default: () => defaulti18n, type: Object },

    disabled: { default: false, type: Boolean },
    disabledDates: { default: () => [], type: Array },
    disabledDaysOfWeek: { default: () => [], type: Array },
    allowedRanges: { default: () => [], type: Array },

    enableCheckout: { default: false, type: Boolean },
    hoveringTooltip: { default: true, type: [Boolean, Function] },
    tooltipMessage: { default: null, type: String },

    singleDaySelection: { default: false, type: Boolean },
    showHalfDay: { default: true, type: Boolean },
    showYear: { default: false, type: Boolean },
    displayClearButton: { default: true, type: Boolean },
    showOverlay: { default: false, type: Boolean },
    closeDatepickerOnClickOutside: { default: true, type: Boolean },
  },

  emits: [
    "calendar-id-opened",
    "check-in-changed",
    "check-out-changed",
    "update-months",
    "height-changed",
  ],

  data() {
    return {
      hoveringDate: null,
      checkIn: this.startingDateValue,
      checkOut: this.endingDateValue,
      months: [],
      activeMonthIndex: 0,
      nextDisabledDate: null,
      show: true,
      isOpen: false,
      xDown: null,
      yDown: null,
      xUp: null,
      yUp: null,
      sortedDisabledDates: null,
      screenSize: this.handleWindowResize(),
      renderNextMonthEv: throttleEv(this.renderNextMonth),
    };
  },

  computed: {
    accommodationsState() {
      return this.$store.getters["mod_search/GET_accommodationsState"];
    },
    showOpenedDate() {
      return this.startingShowDate || this.checkIn;
    },
    checkInText() {
      return this.formatDate(this.checkIn);
    },
    checkOutText() {
      return this.formatDate(this.checkOut);
    },
    currentMonth() {
      return this.months[this.activeMonthIndex].days[15].date;
    },
    nextMonth() {
      return this.months[this.activeMonthIndex + 1].days[15].date;
    },
    currentMonthText() {
      return this.getMonth(this.currentMonth);
    },
    nextMonthText() {
      return this.getMonth(this.nextMonth);
    },
    isLastMonth() {
      if (this.endDate !== Infinity) {
        return (
          fecha.format(this.nextMonth, "YYYYMM") >=
          fecha.format(cloneDate(this.endDate), "YYYYMM")
        );
      }
      return false;
    },
  },

  watch: {
    disabledDates() {
      this.parseDisabledDates();
    },
    isOpen(value: any) {
      if (this.screenSize !== "desktop") {
        const bodyClassList = document.querySelector("body")?.classList;

        if (value) {
          bodyClassList?.add("-overflow-hidden");
          setTimeout(() => {
            const swiperWrapper = document.getElementById("swiperWrapper");
            const datePickerElement: any =
              document.querySelector(".datepicker__month");
              const monthHeihgt = datePickerElement?.offsetHeight;
            if (swiperWrapper) {
              swiperWrapper.scrollTop = this.activeMonthIndex * monthHeihgt;
            }
          }, 1000);
        } else {
          bodyClassList?.remove("-overflow-hidden");
        }
      }
      this.emitUpdateMonths();
      this.setDefaultCheckOut(value);
      this.$emit("calendar-id-opened", value);
    },
    // TODO delete checkIn/checkOut and use startingDateValue/endingDateValue
    startingDateValue(newDate: any) {
      if (convertToDateFormat(newDate) !== convertToDateFormat(this.checkIn))
        this.checkIn = newDate;
    },
    endingDateValue(newDate: any) {
      if (convertToDateFormat(newDate) !== convertToDateFormat(this.checkOut))
        this.checkOut = newDate;
    },
    checkIn(newDate: any) {
      this.$emit("check-in-changed", newDate);
      this.hoveringDate = null;
    },
    checkOut(newDate: any) {
      if (this.checkOut !== null) {
        this.hoveringDate = null;
        this.nextDisabledDate = null;
        this.show = true;
        this.parseDisabledDates();
        this.reRender();
        this.isOpen = false;
      }

      this.$emit("check-out-changed", newDate);
    },
  },

  methods: {
    ...Helpers,

    emitUpdateMonths(list = this.months) {
      if (this.isOpen) {
        const monthsList = list.map((m: any) => m.days[15].date);
        this.$emit("update-months", monthsList, this.activeMonthIndex);
      }
    },
    formatDate(date: any) {
      return formatDateRule(date, this.format);
    },

    handleWindowResize() {
      if (window.innerWidth < 480) {
        this.screenSize = "smartphone";
      } else if (window.innerWidth >= 480 && window.innerWidth < 768) {
        this.screenSize = "tablet";
      } else if (window.innerWidth >= 768) {
        this.screenSize = "desktop";
      }

      return this.screenSize;
    },

    onElementHeightChange(el: any, callback: any) {
      let lastHeight = el.clientHeight;
      let newHeight = lastHeight;

      (function run() {
        newHeight = el.clientHeight;

        if (lastHeight !== newHeight) {
          callback();
        }

        lastHeight = newHeight;

        if (el.onElementHeightChangeTimer) {
          clearTimeout(el.onElementHeightChangeTimer);
        }

        el.onElementHeightChangeTimer = setTimeout(run, 1000);
      })();
    },

    emitHeighChangeEvent() {
      this.$emit("height-changed");
    },

    reRender() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    clearSelection() {
      this.hoveringDate = null;
      this.checkIn = null;
      this.checkOut = null;
      this.nextDisabledDate = null;
      this.show = true;
      this.parseDisabledDates();
      this.reRender();
    },

    setDatepickerVisible(e: any) {
      this.isOpen = e;
    },
    hideDatepicker() {
      this.isOpen = false;
    },
    showDatepicker() {
      this.isOpen = true;
    },
    toggleDatepicker() {
      this.isOpen = !this.isOpen;
    },

    clickOutside() {
      if (this.closeDatepickerOnClickOutside) {
        this.hideDatepicker();
      }
    },

    handleDayClick(event: any) {
      if (
        this.accommodationsState &&
        this.checkIn &&
        this.checkIn === event.date
      ) {
        // prevent choose onde date for checking and check out if only accommodations in entity
        return;
      }
      // event.nextDisabledDate contains last allowed date
      if (this.checkIn && this.checkOut) {
        // new click session - should reset previous values
        this.checkIn = null;
        this.checkOut = null;
      }

      if (
        !this.checkIn &&
        !this.canBeCheckInDay(event.date, event.nextDisabledDate)
      ) {
        return;
      }
      if (!this.checkIn && !this.singleDaySelection) {
        this.checkIn = event.date;
      } else if (this.singleDaySelection) {
        this.checkIn = event.date;
        this.checkOut = event.date;
      } else if (this.checkIn && !this.checkOut) {
        this.checkOut = event.date;
      } else {
        this.checkOut = null;
        this.checkIn = event.date;
        this.hoveringDate = null;
      }
      //available calendar
      if (event.nextDisabledDate instanceof Date) {
        this.$root.$emit(
          "enable-date-" + fecha.format(event.nextDisabledDate, "YYYYMMDD")
        );
      }
      this.nextDisabledDate = event.nextDisabledDate;
    },

    canBeCheckInDay(date: any, lastAllowedDate: any) {
      if (this.singleDaySelection || lastAllowedDate == Infinity) {
        return true;
      }
      const minDate = fecha.format(setDay(date, this.minNights), "YYYYMMDD");
      const allowedDate = fecha.format(lastAllowedDate, "YYYYMMDD");
      return minDate <= allowedDate;
    },

    renderPreviousMonth() {
      if (this.activeMonthIndex > 0) {
        this.activeMonthIndex--;
        this.emitUpdateMonths();
      }
      // if (this.activeMonthIndex >= 1) {
      //
      // }
      // else return
    },

    renderNextMonth() {
      const updateMonth = () => {
        this.activeMonthIndex++;
        this.emitUpdateMonths();
      };
      if (this.activeMonthIndex < this.months.length - 2) {
        updateMonth();
        return;
      }

      let firstDayOfLastMonth;

      if (this.screenSize !== "desktop") {
        firstDayOfLastMonth = this.months[this.months.length - 1].days.filter(
          (day: any) => day.belongsToThisMonth === true
        );
      } else {
        firstDayOfLastMonth = this.months[
          this.activeMonthIndex + 1
        ].days.filter((day: any) => day.belongsToThisMonth === true);
      }
      if (this.endDate !== Infinity) {
        if (
          fecha.format(firstDayOfLastMonth[0].date, "YYYYMM") >=
          fecha.format(cloneDate(this.endDate), "YYYYMM")
        ) {
          return;
        }
      }
      this.createMonth(this.getNextMonth(firstDayOfLastMonth[0].date));

      updateMonth();
    },

    setCheckIn(date: any) {
      this.checkIn = date;
    },
    setCheckOut(date: any) {
      this.checkOut = date;
    },

    getMonth(date: any) {
      return (
        this.i18n["month-names"][(fecha.format(date, "M") as any) - 1] +
        (this.showYear ? fecha.format(date, " YYYY") : "")
      );
    },

    createMonth(date: any) {
      const firstDay = this.getFirstDay(date, this.firstDayOfWeek);
      const month: any = {
        days: [],
      };
      for (let i = 0; i < 42; i++) {
        month.days.push({
          date: this.addDays(firstDay, i),
          belongsToThisMonth:
            this.addDays(firstDay, i).getMonth() === date.getMonth(),
          isInRange: false,
        });
      }
      this.months.push(month);
    },

    parseDisabledDates() {
      const sortedDates = [];

      for (let i = 0; i < this.disabledDates.length; i++) {
        sortedDates[i] = cloneDate(this.disabledDates[i].date);
      }

      sortedDates.sort((a: any, b: any) => a - b);

      this.sortedDisabledDates = sortedDates;
    },
    setDefaultCheckOut(value: any) {
      if (!value && this.checkIn && !this.checkOut) {
        if (!this.accommodationsState) {
          this.checkOut = this.addDays(this.checkIn, this.minNights);
        } else {
          const day = new Date(this.checkIn);
          const nextDay = new Date(day);
          nextDay.setDate(day.getDate() + 1);
          this.checkOut = this.addDays(nextDay, this.minNights);
        }
      }
    },
    calcStartMonths() {
      const startDateAsDate = cloneDate(this.startDate);
      const nextMonthStartDate = this.getNextMonth(startDateAsDate);

      this.createMonth(startDateAsDate);

      if (
        this.showOpenedDate &&
        (this.getMonthDiff(nextMonthStartDate, this.showOpenedDate) > 0 ||
          this.getMonthDiff(this.startDate, this.showOpenedDate) > 0)
      ) {
        const count = this.getMonthDiff(this.startDate, this.showOpenedDate);
        let nextMonth = cloneDate(this.startDate);
        for (let i = 0; i <= count; i++) {
          const tempNextMonth = this.getNextMonth(nextMonth);
          this.createMonth(tempNextMonth);
          nextMonth = tempNextMonth;
        }
        if (
          this.checkOut &&
          this.getMonthDiff(this.checkIn, this.checkOut) > 0
        ) {
          this.createMonth(this.getNextMonth(nextMonth));
          this.activeMonthIndex = 1;
        }
        this.activeMonthIndex += count;
      } else {
        this.createMonth(nextMonthStartDate);
      }
    },
    isDayAvailable(day: any) {
      const date = fecha.format(day.date, "YYYY-MM-DD");
      return this.disabledDates
        ? !this.disabledDates.some((i: any) => i.date === date)
        : true;
    },
    getAvailabilityReason(day: any) {
      const date = fecha.format(day.date, "YYYY-MM-DD");
      if (this.disabledDates) {
        const disabledDate = this.disabledDates.find(
          (i: any) => i.date === date
        );
        if (disabledDate) {
          return disabledDate.reason;
        }
      }
      return "";
    },
  },

  beforeMount() {
    (fecha as any).i18n = {
      dayNames: this.i18n["day-names"],
      dayNamesShort: this.shortenString(this.i18n["day-names"], 3),
      monthNames: this.i18n["month-names"],
      monthNamesShort: this.shortenString(this.i18n["month-names"], 3),
      amPm: ["am", "pm"],
      // D is the day of the month, function returns something like...  3rd or 11th
      DoFn: function (D: any) {
        return (
          D +
          ["th", "st", "nd", "rd"][
            D % 10 > 3 ? 0 : (((D - (D % 10) !== 10) as any) * D) % 10
          ]
        );
      },
    };
    this.calcStartMonths();
    this.parseDisabledDates();
  },

  mounted() {
    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchmove", this.handleTouchMove, false);
    window.addEventListener("resize", this.handleWindowResize);

    this.onElementHeightChange(document.body, () => {
      this.emitHeighChangeEvent();
    });
  },
  unmounted() {
    window.removeEventListener("touchstart", this.handleTouchStart);
    window.removeEventListener("touchmove", this.handleTouchMove);
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<style lang="scss">
@import "../../../../scss/base";
/* =============================================================
     * VARIABLES
     * ============================================================*/
$white: #fff;
$black: #000;
$gray: #424b53;
$primary-text-color: #35343d;
$lightest-gray: #f3f5f8;
$primary-color: #00ca9d;
$primary-color: $primary-color;
$medium-gray: #999999;
$light-gray: #d7d9e2;
$dark-gray: #2d3047;

$day-selected: #F2F4F7;
$nostayover-color: #dbdbdb;

$font-small: 14px;

$available-date: #478676;

$col-secondary-woopacity: #f6f6f6;

/* =============================================================
     * RESPONSIVE LAYOUT HELPERS
     * ============================================================*/
$tablet: "(min-width: 480px) and (max-width: 767px)";
$phone: "(max-width: 479px)";
$desktop: "(min-width: 768px)";
$up-to-tablet: "(max-width: 767px)";
$extra-small-screen: "(max-width: 23em)";

@mixin focusStyle() {
  &:focus {
    outline: 1px dashed var(--vb-color-primary);
    outline-offset: -2px;
  }
}

@mixin device($device-widths) {
  @media screen and #{$device-widths} {
    @content;
  }
}

.square {
  width: calc(100% / 7);
  float: left;
  color: black;
  .vb-tablet & {
    cursor: pointer;
  }
}
.datepicker-wrapper {
  box-shadow: 0px 8px 8px -4px #0000000A;
  box-shadow: 0px 20px 24px -4px #0000001A;
}

/* =============================================================
     * BASE STYLES
     * ============================================================*/

.datepicker {
  transition: all 0.2s ease-in-out;
  background-color: $white;
  color: $gray;
  font-size: 16px;
  line-height: 1;
  overflow: auto;
  z-index: 999;
  .vb-tablet & {
    left: 0;
    top: 48px;
    position: absolute;
    overflow: visible;
    transition: overflow 0s 1s;
    &.datepicker--closed {
      overflow: hidden;
    }
    &.datepicker--open {
      overflow: visible; //fix show .datepicker__tooltip
    }
  }
  &--closed {
    box-shadow: 0 15px 30px 10px rgba($black, 0);
    max-height: 0;
  }
  &__wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 80px;
  }
  &__input {
    //padding-top: 0;
    @include device($phone) {
      //text-indent: 0;
      //text-align: center;
    }

    &--is-active {
      color: $primary-color;
    }
    &--is-active::placeholder {
      color: $primary-color;
    }
    &--is-active::-moz-placeholder {
      color: $primary-color;
    }
    &--is-active:-ms-input-placeholder {
      color: $primary-color;
    }
    &--is-active:-moz-placeholder {
      color: $primary-color;
    }
    &--single-date:first-child {
      width: 100%;
      background: none;
      text-align: left;
    }
  }

  &__month-day {
    visibility: visible;
    text-align: center;
    margin: 0;
    border: 0;
    height: 40px;

    @include focusStyle();

    &--invalid-range {
      background-color: rgba($primary-color, 0.3);
      color: $lightest-gray;
      cursor: not-allowed;
      position: relative;
    }

    &--invalid {
      color: $lightest-gray;
      cursor: not-allowed;
    }

    &--valid:hover,
    &--allowed-checkout:hover {
      background-color: $white;
      color: $primary-color;
      z-index: 1;
      position: relative;
      box-shadow: 0 0 10px 3px rgba($gray, 0.4);
    }

    &--disabled {
      color: #667085;
      text-decoration: line-through;
      cursor: not-allowed;
      pointer-events: none;
      position: relative;
    }

    &--selected {
      background-color: rgba($day-selected, 0.5);
      color: $black;

      &:hover {
        background-color: $day-selected;
        color: $black;
        z-index: 1;
        position: relative;
      }
    }

    &--first-day-selected {
      background: $black;
      color: $white;
      border-radius: 4px 0 0 4px;
    }
    &--last-day-selected {
      background: $black;
      color: $white;
      border-radius: 0 4px 4px 0;
    }

    &--allowed-checkout {
      color: $medium-gray;
    }

    &--out-of-range {
      color: $lightest-gray;
      cursor: not-allowed;
      position: relative;
      pointer-events: none;
    }

    &--valid {
      cursor: pointer;
      color: $medium-gray;
    }

    &--hidden {
      opacity: 0.25;
      pointer-events: none;
      color: $white;
      visibility: hidden;
    }
  }

  &__month-button {
    display: inline-block;
    height: 60px;
    width: 60px;

    @include focusStyle();

    &--locked {
      opacity: 0.2;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  &__month-caption {
    height: 2.5em;
    vertical-align: middle;
  }

  &__week-days {
    height: 2em;
    vertical-align: middle;
  }
}

// Modifiers

.-overflow-hidden {
  overflow: hidden;
}

.-is-hidden {
  display: none !important;
}

.-hide-up-to-tablet {
  @include device($up-to-tablet) {
    display: none;
  }
}

.-hide-on-desktop {
  .vb-tablet & {
    display: none !important;
  }
}
.datepicker--open {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch !important;
  .vb-tablet & {
    position: absolute;
    box-shadow: 0 15px 30px 10px rgba($black, 0.08);
    max-height: 900px;
    display: block;
    bottom: auto;
  }
}
.datepicker--show-overlay {
  transform: rotate(0deg);
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#fff, 0.75);
  }
  .vb-request-loader {
    display: none;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    right: auto;
    .vb-tablet & {
      display: block;
    }
  }
}
.vb-tablet {
  .ontop .datepicker--open {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
  .ontop.onright .datepicker--open {
    top: auto;
    left: auto;
    right: 0;
  }
}

.datepicker__dummy-wrapper {
  display: flex;
  align-items: center;
  padding: 0 0 0 0.1em;
  cursor: pointer;
  width: 100%;
  &.vb--disable {
    color: $col-disabled;
    cursor: default;
  }
}
.datepicker__input {
  height: $input-height;
  padding: 0 $form-gap;
  text-align: center;
  width: 50%;
  @include focusStyle();

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: $primary-text-color;
  }
}
.square.datepicker__month-day--first-day-selected,
.square.datepicker__month-day--last-day-selected {
  opacity: 1;
  background: $black;
  &:hover {
    background: $black;
    outline: none;
  }
}

.square.datepicker__month-day--first-day-selected:hover {
  border-radius: 4px 0 0 4px;
}

.square.datepicker__month-day--last-day-selected:hover {
  border-radius: 0 4px 4px 0;
}

.square.datepicker__month-day--selected {
  background: $day-selected;
}

.square.datepicker__month-day--selected:hover {
  background: $day-selected;
  color: $black;
  outline: 1px solid $black;
}
.datepicker__week-row {
  border: none;
  box-shadow: 0 13px 18px -8px rgba($black, 0.07);
  background: $col-border;
  display: flex;
  &-day {
    width: 100%;
  }
}

.datepicker__week-name {
  width: calc(100% / 7);
  font-weight: 600;
  text-align: center;
  padding: 0.5em 0;
}

.datepicker__months {
  position: relative;
  overflow: auto;
  .vb-tablet & {
    overflow: visible;
    width: 520px;
    &:before {
      content: "";
      background: $light-gray;
      bottom: 0;
      display: block;
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }
}
.datepicker__month {
  display: inline-block;
  width: 100%;
  .square .datepicker__month-day {
    border-bottom: $default-border;
    border-right: $default-border;
  }
  .square {
    position: relative;
    &:nth-child(7n) .datepicker__month-day {
      border-right: none;
    }
  }
  .vb-tablet & {
    padding: 0;
    width: 50%;
    font-size: 12px;
    font-weight: 300;
    &:last-of-type {
      padding: 0;
    }
  }
}
.datepicker__month-mobile-name {
  padding: 0.75rem 1rem;
  font-weight: bold;
  text-align: center;
}
.datepicker__tooltip {
  background-color: $black;
  border-radius: 4px;
  color: $white;
  font-size: 12px;
  padding: 4px 8px;
  position: absolute;
  z-index: 50;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $black;
    bottom: -6px;
    content: "";
    left: 50%;
    margin-left: -6px;
    position: absolute;
  }
}

/* availability calendar */
.vb-product-date-picker {
  div[class="square"] {
    .datepicker__month-day {
      background-color: $available-date;
      color: $col-txt2;
    }
  }
}

.datepicker__month-day--disabled.datepicker__month-day--nostayover {
  background: repeating-linear-gradient(
    -41deg,
    transparent,
    transparent 5px,
    $nostayover-color 5px,
    $nostayover-color 10px
  );
}

.square.datepicker__month-day--cancheckout {
  opacity: 1;
  background: repeating-linear-gradient(
    -41deg,
    transparent,
    transparent 5px,
    $col-secondary-woopacity 5px,
    $col-secondary-woopacity 10px
  );
  .datepicker__month-day {
    z-index: 3;
    position: relative;
    span {
      opacity: 0.8;
      display: inline-block;
      position: relative;
      background-color: $available-date;
      padding: 2.5px 0;
      margin-top: -3px;
      margin-right: 0px;
      min-width: 1.7em;
      border: 0 solid transparent;
      border-radius: 17%;
      color: $white;
    }
  }
  &::before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right top,
      $available-date 50%,
      transparent 50%
    );
  }
}

.square.datepicker__month-day--disabled.datepicker__month-day--cancheckout {
  &::before {
    background: linear-gradient(
      to right top,
      $available-date 50%,
      transparent 50%
    );
  }
}

.square.datepicker__month-day--selected.datepicker__month-day--cancheckout {
  color: $black;
  .datepicker__month-day {
    span {
      background-color: $day-selected;
    }
  }
  &::before {
    background: linear-gradient(
      to right top,
      $day-selected 50%,
      transparent 50%
    );
  }
}

.square.datepicker__month-day--selected.datepicker__month-day--cancheckout:hover {
  background: repeating-linear-gradient(
    -41deg,
    transparent,
    transparent 5px,
    $col-secondary-woopacity 5px,
    $col-secondary-woopacity 10px
  );
  .datepicker__month-day {
    span {
      background-color: $col-secondary;
    }
  }
  &::before {
    background: linear-gradient(
      to right top,
      $col-secondary 50%,
      transparent 50%
    );
  }
}
.square.datepicker__month-day--selected.datepicker__month-day--disabled {
  opacity: 1;
  .datepicker__month-day {
    background: $day-selected;
  }
}
.square.datepicker__month-day--stayover {
  .datepicker__month-day {
    background-color: $available-date;
    color: $col-txt2;
  }
}
</style>
