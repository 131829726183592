import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5336f39c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vb-room-offer" }
const _hoisted_2 = { class: "vb-room-offer_head" }
const _hoisted_3 = {
  key: 0,
  class: "vb-room-head_img_box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_slider = _resolveComponent("product-slider")!
  const _component_product_header = _resolveComponent("product-header")!
  const _component_product_activity_reservation = _resolveComponent("product-activity-reservation")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.itemData.images.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_product_slider, {
              class: "vb-room-head_img",
              "image-list": $props.itemData.images
            }, null, 8, ["image-list"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_product_header, {
        class: "vb-room-head_info",
        "item-data": $props.itemData
      }, null, 8, ["item-data"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activityList, (x) => {
      return (_openBlock(), _createBlock(_component_product_activity_reservation, {
        class: "vb-room-offer_secondary",
        "item-data": $props.itemData
      }, null, 8, ["item-data"]))
    }), 256))
  ]))
}