import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bf1966d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vb-form_label"
}
const _hoisted_2 = { class: "vb-custom-input_input-box" }
const _hoisted_3 = ["id", "value", "pattern", "readonly"]
const _hoisted_4 = {
  key: 0,
  class: "vb-action-btn"
}
const _hoisted_5 = {
  key: 0,
  class: "vb-btn vb-btn--icon vb-icon vb-icon-checked vb-text-primary"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 1,
  class: "vb-action-btn vb-num-btn"
}
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "vb-custom-input_text vb-small-text vb-text-error vb-ellipsis"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["vb-custom-input", $options.componentStyles])
  }, [
    _renderSlot(_ctx.$slots, "label", {}, () => [
      ($props.labelText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.labelText), 1))
        : _createCommentVNode("", true)
    ], true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", _mergeProps(_ctx.$attrs, {
          ref: "inputDom",
          id: $props.inputId
        }, {
          value: $props.modelValue === null ? '' : $props.modelValue ,
          pattern: $options.inputPattern,
          readonly: $options.isReadOnly,
          class: "vb-input vb-custom-input_input",
          onInput: _cache[0] || (_cache[0] = ($event: any) => ($options.updateData($event.target.value))),
          onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.handleKeyDown && $options.handleKeyDown(...args))),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($options.submitInput && $options.submitInput(...args)), ["enter"]))
        }), null, 16, _hoisted_3),
        ($props.showActions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _withDirectives(_createElementVNode("button", {
                type: "button",
                "aria-label": "reset input",
                class: "vb-btn vb-btn--icon vb-icon vb-icon-reset vb-text-disable",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.resetInput && $options.resetInput(...args)))
              }, null, 512), [
                [_vShow, String($props.modelValue).length]
              ]),
              ($props.doneSuccess)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                : (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    "aria-label": "submit",
                    class: _normalizeClass(["vb-btn vb-btn--icon vb-icon vb-icon-active-input", $options.inputIsValid ? 'vb-text-primary' : 'vb-text-disable']),
                    disabled: !$options.inputIsValid,
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.submitInput && $options.submitInput(...args)))
                  }, null, 10, _hoisted_6))
            ]))
          : _createCommentVNode("", true),
        ($options.showBtnNum)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("button", {
                type: "button",
                class: "vb-num-btn--inc vb-btn vb-btn--icon vb-icon vb-icon-chevron-top",
                disabled: $options.btnNumIncDisabled,
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.valueInc && $options.valueInc(...args)))
              }, null, 8, _hoisted_8),
              _createElementVNode("button", {
                type: "button",
                class: "vb-num-btn--dec vb-btn vb-btn--icon vb-icon vb-icon-chevron-down",
                disabled: $options.btnNumDecDisabled,
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.valueDec && $options.valueDec(...args)))
              }, null, 8, _hoisted_9)
            ]))
          : _createCommentVNode("", true)
      ])
    ], true),
    ($props.textError.length && !_ctx.$attrs.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.textError), 1))
      : _createCommentVNode("", true)
  ], 2))
}